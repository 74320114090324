<template>
<div class="capitum-checkout">
    <span v-html="capitumdata.Data.CodeSnippet"></span>
</div>
</template>

<script>
/* eslint-disable */

export default {
      name: 'CapitumCheckout',
      components: {
            
      },
      props: ["capitumdata"],
      data() {
            return {
                  loading: false,
                  order: {}
            };
      },
      computed: {

      },
      methods: {},
      mounted() { 
           window.addEventListener('message', function (e) { 
            try
            {
                  var data = JSON.parse(e.data);
                  switch (data.status) 
                  {
                        case "capitum_complete":
                        // do work;
                        //debugger;
                        //alert("done");
                        break;
                  }
            }
            catch(err){

            }
            
           });
      }
};
</script>

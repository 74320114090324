import axios from "axios";
import authHeader from './../../services/auth-header';

//const API_URL_GET = process.env.VUE_APP_API_URL_GET;
const API_URL_POST = process.env.VUE_APP_API_URL_POST;

export default {
      state: {                    
            error: false,
            errorMessage: "",
            loading: false                        
      },

      getters: {
            loading: state =>
            {
                  return state.loading;
            },           
            error: state =>
            {
                  return state.error;
            },
            errormessage: state =>
            {
                  return state.errorMessage;
            }
      },

      mutations: {        
            setLoading(state, status)
            {
                  state.loading = status;
            },
            setError(state, status)
            {
                  state.error = status;
            },
            setErrorMessage(state, message)
            {
                  state.errorMessage = message;
            }
      },

      actions: {
            async addFavorite({ commit }, item)
            {
                  commit("setLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_POST + 'favorites-add';
                        response = await axios.post(url, item, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setLoading", false);
                        commit("setError", true);
                        commit("setErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {                        
                        commit("setLoading", false);
                        commit("setError", false);
                        commit("setErrorMessage", "");
                        return Promise.resolve(true);
                  }
                  return response;
            },
            async deleteFavorite({ commit }, item)
            {
                  commit("setLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_POST + 'favorites-delete';
                        response = await axios.post(url, item, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setLoading", false);
                        commit("setError", true);
                        commit("setErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {                        
                        commit("setLoading", false);
                        commit("setError", false);
                        commit("setErrorMessage", "");
                        return Promise.resolve(true);
                  }
            }        
      },

      namespaced: true
};

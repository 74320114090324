import Vue from 'vue';
//import VueStore from "./store";
import Router from 'vue-router';
import Home from './views/Home.vue';
import Linn from './views/static/Linn.vue';
import Login from './views/front/Login.vue';
import Register from './views/front/Register.vue';
import ForgotPassword from './views/front/ForgotPassword.vue';
import Packages from './views/packages/Packages.vue';
import Package from './views/packages/Package.vue';
import Recipes from './views/recipes/Recipes.vue';
import Recipe from './views/recipes/Recipe.vue';
import Contact from './views/static/Contact.vue';
import Products from './views/shop/Products.vue';
import Product from './views/shop/Product.vue';
import Checkout from './views/shop/Checkout.vue';
import CheckoutComplete from './views/shop/CheckoutComplete.vue';
import FinalizeOrder from './views/shop/FinalizeOrder.vue';
import Kroppskoll from './views/kroppskoll/Kroppskoll.vue';
import KroppskollMatningar from './views/kroppskoll/Matningar.vue';
import KroppskollJamfor from './views/kroppskoll/Jamfor.vue';
import Instagram from './views/instagram/Instagram.vue';
import Ingredients from './views/ingredients/Ingredients.vue';
import Test from './views/Test.vue';
import store from "./store";

Vue.use(Router);
// vue meta
import Meta from 'vue-meta'
Vue.use(Meta);

export const router = new Router({
  mode: 'history',
  //base: "/demoshop",
  routes: [
    {
      path: '/',
      name: 'hem',
      component: Home
    },
    {
      path: '/hem',
      component: Home
    },
    {
      path: '/linn',
      component: Linn
    },
    {
      path: '/test',
      component: Test
    },
    {
      path: '/kontakt',
      component: Contact
    },
    {
      path: '/instagram',
      component: Instagram
    },
    {
      path: '/ingredienser',
      component: Ingredients
    },
    {
      name: "ingrediens",
      path: '/ingredienser/:id/:name',
      component: Ingredients
    },
    {
      path: '/recept',
      component: Recipes
    },
    {
      name: "recept",
      path: "/recept/:id/:name",
      component: Recipe
    },
    {
      path: "/paket",
      component: Packages
    },
    {
      path: "/paket/:id/:name",
      component: Package
    },
    {
      path: "/produkter",
      component: Products
    },
    {
      path: "/produkter/:id/:name",
      component: Products
    },
    {
      path: "/produkt/:id/:name",
      component: Product
    },
    {
      path: "/kassa",
      component: Checkout
    },
    {
      path: "/betalning",
      component: Checkout
    },
    {
      path: "/slutfor/:id",
      component: FinalizeOrder,
      props: true
    },
    {
      path: "/orderbekraftelse",
      component: CheckoutComplete
    },
    {
      path: "/orderbekraftelse/:id",
      component: CheckoutComplete,
      props: true
    },
    {
      path: '/loggain',
      component: Login
    },
    {
      path: '/registrera',
      component: Register
    },
    {
      path: '/glomtlosenord',
      component: ForgotPassword
    },    
    {
      path: '/kroppskollen',
      name: 'kropskoll',
      component: Kroppskoll
    },
    {
      path: '/kroppskollen/matningar',
      name: 'kropskollMatning',
      component: KroppskollMatningar
    },
    {
      path: '/kroppskollen/jamfor',
      name: 'KroppskollJamfor',
      component: KroppskollJamfor
    },
    {
      path: '/minsida',
      name: 'profile',
      // lazy-loaded
      component: () => import('./views/user/Profile.vue')
    },
    {
      path: "/minsida/:tab",
      name: 'profile-tab',
      // lazy-loaded
      component: () => import('./views/user/Profile.vue')
    },
    {
      path: '/minsida/:tab/:page*',
      name: 'loaded-tab-page',
      // lazy-loaded
      component: () => import('./views/user/Profile.vue')
    },
    {
      path: '/admin',
      name: 'admin',
      // lazy-loaded
      component: () => import('./views/admin/Admin.vue')
    },
    {
      path: '/admin/:tab',
      name: 'admin-tab',
      // lazy-loaded
      component: () => import('./views/admin/Admin.vue')
    },
    {
      path: '/admin/:tab/:page*',
      name: 'admin-tab-page',
      // lazy-loaded
      component: () => import('./views/admin/Admin.vue')
    }
  ]
});

router.beforeEach((to, from, next) =>
{
  const publicPages = ['', '/','/loggain', '/glomtlosenord', '/registrera', '/hem', '/ingredienser', '/instagram', '/kontakt'];
  const loggedIn = localStorage.getItem('user');
  var isPublicPage = IsPublicPage(publicPages, to.path);
   
  if (isPublicPage || loggedIn)
  {
    next();
    return;
  }

  // If not logged in and trying to access a page that is nog public
  if (!loggedIn)
  {
    store.dispatch("auth/logout");
    next({ path: "/loggain", query:{ redirect: to.path} }); // Redirect to login page
    return;
  }
  // If accessing root path "/"
  else if (to.path === "/")
  {
    if (loggedIn)
    {
      next(); // Redirect after logged in
    }
    else
    {
      next({ path: "/loggain" }); // Redirect to login page
    }
    return;
  }

});

function IsPublicPage(publicPages, path)
{
  for (var i = 0; i < publicPages.length; i++)
  {
    var page = publicPages[i];
    if (path.includes(page))
      return true;
  }
  return false;
}

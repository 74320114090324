<template>
      <footer>
            <div id="footerborder"></div>
            <div id="footer" class="mt-5">
                  <div class="d-flex justify-content-center">
                        <div class="footer-item pointer" onclick="window.open('https://www.facebook.com/linnohlander2.0/', '_blank')">                        
                              <inline-svg :src="this.$images.getSvgUrl('facebook.svg')" ></inline-svg>
                        </div>
                        <div class="footer-item pointer" onclick="window.open('https://www.instagram.com/linnohlander2.0/?hl=sv','_blank')">                        
                              <inline-svg :src="this.$images.getSvgUrl('instagram.svg')" ></inline-svg>
                        </div>
                  </div>            
            </div>
            <div class="relative overlay-footer">
                  <div class="overlay-container-bottom">            
                        <div class="overlay-left-bottom">                  
                              <inline-svg :src="this.$images.getSvgUrl('overlay-left-bottom.svg')" ></inline-svg>
                        </div>
                        <div class="overlay-right-bottom">                  
                              <inline-svg :src="this.$images.getSvgUrl('overlay-right-bottom.svg')" ></inline-svg>
                        </div>
                  </div>
            </div>
      </footer>      
</template>


<script>

export default {  
  name: 'FooterComponent'
};
</script>

<template>
    <div class="kroppkoll">
          <div class="mt-0 mb-5 text-center">
                <h1 class="text-main">Jämför Mätningar</h1>                
                <h2 v-if="currentUser && latestmeasurement">Senaste mätning: {{latestmeasurement.date}}</h2>
          </div>
          <div v-if="currentUser">
                <b-container fluid>                    
                    <b-row>
                        <b-col>
                              <b-row class="mb-2 align-items-center">
                                    <b-col cols="auto" class="font-weight-bold">
                                          Visa mätning:
                                    </b-col>
                                    <b-col>
                                          <b-form-select v-model="dateone_pre" @change="SetDates()">
                                                <b-form-select-option :value="0">Senaste mätning</b-form-select-option>
                                                <b-form-select-option :value="1">1 vecka</b-form-select-option>
                                          </b-form-select>
                                    </b-col>
                              </b-row>
                            <b-form-select v-model="dateone" :options="dateOptions"></b-form-select>
                        </b-col>
                        <b-col>                              
                              <b-row class="mb-2 align-items-center">
                                    <b-col cols="auto" class="font-weight-bold">
                                          Jämför med:
                                    </b-col>
                                    <b-col>
                                          <b-form-select v-model="datetwo_pre" @change="SetDates()">
                                                <b-form-select-option :value="1">1 vecka</b-form-select-option>
                                                <b-form-select-option :value="10">1 månad</b-form-select-option>
                                                <b-form-select-option :value="20">Start av året</b-form-select-option>
                                                <b-form-select-option :value="30">1 år</b-form-select-option>
                                                <b-form-select-option :value="40">2 år</b-form-select-option>
                                                <b-form-select-option :value="50">Start</b-form-select-option>
                                                <b-form-select-option :value="100">Högsta vikt</b-form-select-option>
                                                <b-form-select-option :value="101">Högsta fettprocent</b-form-select-option>
                                          </b-form-select>
                                    </b-col>
                              </b-row>                                    
                            <b-form-select v-model="datetwo" :options="dateOptions"></b-form-select>
                        </b-col>
                    </b-row>

                    <hr />
                    <div class="mt-5" v-if="m1 && m2">
                              <JamforTable :m1="m1" :m2="m2"></JamforTable>
                    </div>
                    <div v-else>
                        <b-alert variant="danger" show>Välj två mätningar</b-alert>
                    </div>
                </b-container>
          </div>
          <div v-else>
                <NeedLogin redirect="/kroppskollen" text=" Här får du full kontroll på din kropp! Följ din utveckling och resultat av dina kroppsskannnigar.<br />För att ta del av kroppkollen måste du logga in eller registrera dig!"></NeedLogin>
          </div>          
    </div>
    </template>
    
    <script>            
    import JamforTable from '@/components/kroppskoll/JamforTable';
    import NeedLogin from '@/components/common/NeedLogin';
    
    export default {
          name: 'JamforComponent',
          components: {            
                NeedLogin,
                JamforTable
          },
          data() {
                return {
                      ready: false,
                      dateone: false,
                      datetwo: false,
                      dateone_pre: 0,
                      datetwo_pre: 20
                };
          },
          computed: {
                dateOptions(){
                    var dates = [];
                    for(var i = 0; i < this.measurements.length; i++)
                    {
                        var m = this.measurements[i];
                        dates.push({value: i, text: m.date + '(' + m.data.weight.value + 'kg)'})
                    }
                    return dates;
                },  
                m1(){
                    return this.measurements[this.dateone] ?? false;
                },
                m2(){
                    return this.measurements[this.datetwo] ?? false;
                },
                measurements() {
                      return this.$measurements.measurements();
                },
                latestmeasurement() {
                      if (this.measurements.length > 0)
                            return this.measurements[0];
                      return false;
                },
                currentUser() {
                      return this.$store.state.auth.user;
                },
                age() {
                      return this.$measurements.age();
                },
                height() {
                      return this.$measurements.height();
                }
          },
          methods: {
                date(date) {
                      return this.$date.stringToIsoDateOnly(date);
                },
                SetDates(){
                  // senaste mätning alltid så nära nu i tid som möjligt
                  var m1_force_after = false;
                  var m2_force_after = false;
                  var m1_date = this.$date.dateNow();
                  var m2_date = this.$date.dateNow();
                  var m1_key = "";
                  var m2_key = "";
                  var m1_max = false;
                  var m2_max = false;
                  if(this.dateone_pre == 1)// förra veckan                        
                  {
                        m1_date = this.$date.lastWeek();                                     
                  }

                  if(this.datetwo_pre == 1)// förra veckan                        
                  {
                        m2_date = this.$date.lastWeek();                  
                  }
                  else if(this.datetwo_pre == 10) // 1 månad
                  {
                        m2_date = this.$date.add(m2_date, "months", -1);
                  }
                  else if(this.datetwo_pre == 20) // start av år
                  {
                        var d = new Date(this.$date.currentYear()+"-01-01");
                        m2_date = this.$date.custom(d);
                        m2_force_after = true;
                  }
                  else if(this.datetwo_pre == 30) // 1 år
                  {
                        m2_date = this.$date.add(m2_date, "years", -1);
                  }
                  else if(this.datetwo_pre == 40) // 2 år
                  {
                        m2_date = this.$date.add(m2_date, "years", -2);
                  }
                  else if(this.datetwo_pre == 50) // start
                  {
                        m2_date = this.$date.add(m2_date, "years", -200);
                  }
                  else if (this.datetwo_pre == 100) // max vikt
                  {
                        m2_key = "weight";
                        m2_max = true;
                  }
                  else if (this.datetwo_pre == 101) // max fett
                  {
                        m2_key = "fat";
                        m2_max = true;
                  }

                  if(this.dateone_pre < 100)
                        this.dateone = this.findMeasurementByDate(m1_date, m1_force_after);
                  else 
                        this.dateone = this.findMeasurementByValue(m1_key, m1_max);
                  if(this.datetwo_pre < 100)
                        this.datetwo = this.findMeasurementByDate(m2_date, m2_force_after);
                  else 
                        this.datetwo = this.findMeasurementByValue(m2_key, m2_max);
                },
                findMeasurementByDate(date, force_after){
                  // hitta en så nära, som möjligt
                  var current = false;
                  var prev_days = 100000000;
                  for(var i = 0; i < this.measurements.length; i++){
                        var m = this.measurements[i];
                        var nr_days = Math.abs(this.$date.dayDifferenceBetweenDates(m.date, date));                        
                        if(nr_days < prev_days)
                        {
                              if(force_after)
                              {
                                    if(!this.$date.isAfter(m.date, date))
                                          continue;
                              }
                              current = i;
                              prev_days = nr_days;
                        }
                  }                  
                  return current;
                },
                findMeasurementByValue(key, is_max){
                  var current = false;
                  var prev_value = is_max ? -100000000: 100000000000;
                  for(var i = 0; i < this.measurements.length; i++){
                        var m = this.measurements[i];
                        var mv = m.data[key].value;
                        if(is_max){
                              if(mv > prev_value)
                              {                            
                                    current = i;
                                    prev_value = mv;
                              }
                        }
                        else {
                              if(mv < prev_value)
                              {                            
                                    current = i;
                                    prev_value = mv;
                              }
                        }
                        
                        
                  }                  
                  return current;
                }
          },
          mounted() {
                // hämta in mätningar och mål
                if (this.currentUser) {
                      this.$store.dispatch('user/getUserMeasurments').then(() => {
                            this.$store.dispatch('user/getUserGoals').then(() => {
                                  this.ready = true;
                                  this.SetDates();
                            });
                      });
                }
          },
    };
    </script>
    
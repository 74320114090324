<template>
<div>
      <div v-if="loading">
            <Loader loadingtext="Läser paket..."></Loader>
      </div>
      <div v-else>
            {{apackage}}
            <div class="mt-0 mb-5 text-center">
                  <h1 class="text-main">{{apackage.title}}</h1>
            </div>
            <div class="row">
                  <div class="col-lg-6 col-md-6">
                        <inline-svg :src="this.$images.getSvgUrl(apackage.image)"></inline-svg>
                  </div>
                  <div class="col-lg-6 col-md-6">
                        <div>
                              <div>I paketet ingår</div>
                              <ul class="check-list">
                                    <li v-if="apackage.onlinecoach">
                                          <span class="check-list-check">
                                                <inline-svg :src="this.$images.getSvgUrl('check.svg')"></inline-svg>
                                          </span>
                                          Onlinecoaching
                                    </li>
                                    <li v-if="apackage.breakfast">
                                          <span class="check-list-check">
                                                <inline-svg :src="this.$images.getSvgUrl('check.svg')"></inline-svg>
                                          </span>
                                          {{apackage.breakfast}} frukostpaket
                                    </li>
                                    <li v-if="apackage.meals">
                                          <span class="check-list-check">
                                                <inline-svg :src="this.$images.getSvgUrl('check.svg')"></inline-svg>
                                          </span>
                                          {{apackage.meals}} måltider
                                    </li>
                              </ul>
                        </div>
                  </div>
            </div>
            <div>
                  <span v-html="apackage.text"></span>
            </div>
            <div>{{apackage.description}}</div>
      </div>
</div>
</template>

<script>
const Loader = () => import('@/components/common/Loader');

export default {
      name: 'PackageComponent',
      components: {
            Loader
      },
      computed: {
            haspackage() {
                  return this.apackage;
            },
            apackage() {
                  var p = this.$store.getters["packages/package"];
                  if (p)
                        return p;
                  return false;
            },
            loading() {
                  return this.$store.getters["packages/packageloading"];
            }
      },
      created() {
            this.$store.dispatch("packages/getPackage", this.$route.params.id);
      },
      methods: {}
};
</script>

import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_GET = process.env.VUE_APP_API_URL_GET;
//const API_URL_POST = process.env.VUE_APP_API_URL_POST;

export default {
      state: {                    
            error: false,
            errorMessage: "",
            loading: false,
            data: null,            
            ingredient:
            {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null,
            }
      },

      getters: {
            loading: state =>
            {
                  return state.loading;
            },
            data: state =>
            {
                  return state.data;
            },
            error: state =>
            {
                  return state.error;
            },
            message: state =>
            {
                  return state.message;
            },   
            loadingI: state =>
            {
                  return state.ingredient.loading;
            },
            dataI: state =>
            {
                  return state.ingredient.data;
            },
            errorI: state =>
            {
                  return state.ingredient.error;
            },
            messageI: state =>
            {
                  return state.ingredient.message;
            },            
      },

      mutations: {
            setData(state, data)
            {
                  state.data = data;
            },
            setLoading(state, status)
            {
                  state.loading = status;
            },
            setError(state, status)
            {
                  state.error = status;
            },
            setErrorMessage(state, message)
            {
                  state.errorMessage = message;
            },
            setDataI(state, data)
            {
                  state.ingredient.data = data;
            },
            setLoadingI(state, status)
            {
                  state.ingredient.loading = status;
            },
            setErrorI(state, status)
            {
                  state.ingredient.error = status;
            },
            setErrorMessageI(state, message)
            {
                  state.ingredient.errorMessage = message;
            }
      },

      actions: {
            async getIngredients({ commit })
            {
                  commit("setLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'ingredients';
                        response = await axios.get(url);
                  }
                  catch (error)
                  {
                        commit("setLoading", false);
                        commit("setError", true);
                        commit("setErrorMessage", error.message);
                  }
                  if (response)
                  {
                        commit("setData", response.data.data);
                        commit("setLoading", false);
                        commit("setError", false);
                        commit("setErrorMessage", "");
                  }
            },
            async getIngredient({ commit }, id)
            {
                  commit("setLoadingI", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'ingredient?id='+id;
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setLoadingI", false);
                        commit("setErrorI", true);
                        commit("setErrorMessageI", error.message);
                  }
                  if (response)
                  {
                        commit("setDataI", response.data.data);
                        commit("setLoadingI", false);
                        commit("setErrorI", false);
                        commit("setErrorMessageI", "");
                  }
            }        
      },

      namespaced: true
};

import axios from 'axios';
import authHeader from './auth-header';

const API_URL_GET = process.env.VUE_APP_API_URL_GET;
const API_URL_POST = process.env.VUE_APP_API_URL_POST;

class AdminService
{
  GetOrders() 
  {
    return axios.get(API_URL_GET + 'admin-orders', { headers: authHeader() });
  }
  GetOrder(id) 
  {
    return axios.get(API_URL_GET + 'admin-orders?id=' + id, { headers: authHeader() });
  }
  UpdateOrderData(item)
  {
    return axios.post(API_URL_POST + 'admin-order-update', item, { headers: authHeader() });
  }
  GetUsers() 
  {
    return axios.get(API_URL_GET + 'admin-users', { headers: authHeader() });
  }
  GetAccounts() 
  {
    return axios.get(API_URL_GET + 'admin-accounts', { headers: authHeader() });
  }
  GetUserAccounts(uid)
  {
    return axios.get(API_URL_GET + 'admin-user-accounts?uid=' + uid, { headers: authHeader() });
  }
  GetUserMeasurements(uid)
  {
    return axios.get(API_URL_GET + 'admin-user-measurements?uid=' + uid, { headers: authHeader() });
  }
  SaveMeasurementData(item)
  {
    return axios.post(API_URL_POST + 'admin-measurement-data-add', item, { headers: authHeader() });
  }
  SaveMeasurement(item)
  {
    return axios.post(API_URL_POST + 'admin-measurement-add', item, { headers: authHeader() });
  }
  GetUserGoals(uid)
  {
    return axios.get(API_URL_GET + 'admin-user-goals?uid=' + uid, { headers: authHeader() });
  }
  SaveGoalData(item)
  {
    return axios.post(API_URL_POST + 'admin-goal-data-add', item, { headers: authHeader() });
  }
  SaveGoal(item)
  {
    return axios.post(API_URL_POST + 'admin-goal-add', item, { headers: authHeader() });
  }
  UpdateUserPassword(item)
  {
    return axios.post(API_URL_POST + 'admin-user-password-set', item, { headers: authHeader() });
  }
  UpdateUserProperties(item)
  {
    return axios.post(API_URL_POST + 'admin-user-properties-set', item, { headers: authHeader() });
  }
}

export default new AdminService();

//import store from "./../store/";
import formatter from "./formatter";

export default {
    sums(recipe, is_total)
    {
        var items = [];
        var tcal = 0;
        var tcarbs = 0;
        var tfat = 0;
        var tprotein = 0;
        var portions = formatter.round(recipe.portions, 0);
        if (is_total)
            portions = 1;
        for (var gid in recipe.ingredients)
        {
            for (var i = 0; i < recipe.ingredients[gid].length; i++)
            {
                var ring = recipe.ingredients[gid][i];
                var ing = recipe.ingredientsdata[ring.ingredient];
                var calories = 0;
                var protein = 0;
                var carbs = 0;
                var fat = 0;
                var gram = "Efter behag";

                if (ring.amount > 0)
                {
                    calories = ing.calories / 100 * ring.gram / portions;
                    protein = ing.protein / 100 * ring.gram / portions;
                    carbs = ing.carbs / 100 * ring.gram / portions;
                    fat = ing.fat / 100 * ring.gram / portions;
                    gram = ring.gram / portions;
                }
                items.push({
                    id: ing.id,
                    name: ing.name,
                    gram: gram,
                    amount: ring.amount,
                    unit: ring.unit,
                    calories: formatter.round(calories, 0),
                    protein: formatter.round(protein, 0),
                    carbs: formatter.round(carbs, 0),
                    fat: formatter.round(fat, 0),
                });

                tcal += calories;
                tprotein += protein;
                tcarbs += carbs;
                tfat += fat;
            }
        }
        return {
            sums: {
                calories: formatter.round(tcal, 0),
                fat: formatter.round(tfat, 0),
                carbs: formatter.round(tcarbs, 0),
                protein: formatter.round(tprotein, 0),
            },
            items: items
        };
    },
};

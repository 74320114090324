<template>
<div class="p-3 rounded-lg shadow">
      <h3>{{item.title}}
            <Trend :trend="item.trend"></Trend>
      </h3>
      <div>
            <LineChart :chartData="item.line.data" :chartOptions="item.line.options" :height="250"></LineChart>
      </div>
</div>
</template>

<script>
import LineChart from '@/components/charts/LineChart';
import Trend from '@/components/kroppskoll/Trend';

export default {
      name: 'GraphComponent',
      props: ["item"],
      components: {
            LineChart,
            Trend
      },
      methods: {}
};
</script>

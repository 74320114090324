import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import store from './store';
import VeeValidate from 'vee-validate';
import Vuex from 'vuex';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false;

const config = {  
  fieldsBagName: 'fieldsvee', //Default is fields
  };
  
Vue.use(VeeValidate, config);



//vue-select
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

// SVG inline
import InlineSvg from 'vue-inline-svg';
Vue.component('inline-svg', InlineSvg);


// plugins/helpers
import plugins from "./plugins/helpers";
Vue.use(plugins);

//import { Datetime } from 'vue-datetime';
// You need a specific loader for CSS files
//import 'vue-datetime/dist/vue-datetime.css'
//Vue.component('datetime', Datetime);

//import { Settings } from 'luxon'
 //Settings.defaultLocale = 'se';

Vue.use(Vuex);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

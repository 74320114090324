<template>
      <div>
            <div class="mt-0 mb-5 text-center "><h1 class="text-main">Om mig</h1></div>
            <div >
                  Jag heter Linn och jag är {{new Date().getFullYear() - 1992}} år gammal.
            </div>
      </div>
</template>

<script>
export default {
      name: 'LinnComponent'      
};
</script>

/* eslint-disable */
import store from "./../store/";
import personnummer from "personnummer";

var scan_texts = {
    bmi: {
        1: { title: "Undervikt", description: "En liten viktökning kan göra gott. Hälsoriskerna vid måttlig undervikt är låga. Men ligger man mycket under BMI 18 kan det bli det risk för näringsbrist, och tex hormonbalanserna kan komma i olag, med olika hälsoproblem som följd. Många som ökar lite i vikt märker att de känner sig piggare och får en bättre prestationsförmåga." },
        2: { title: "Normalvikt", description: "Det viktigt att behålla normalvikten. Ät hälsosamt och håll igång. Det är avgörande för hälsan, inte bara vikten i sig. Då hålls kropps- och blodfetterna låga. Man kan ha höga fettvärden trots normalvikt!" },
        3: { title: "Övervikt", description: "Du väger för mycket i förhållande till din längd. En måttlig övervikt behöver inte vara farlig för hälsan om man äter hälsosamt och lagom mycket samt rör på sig i vardagen. Vill man gå ner i vikt är bästa sättet att ytterligare se över dieten och öka motionerandet. Kan eller vill man inte gå ner i vikt är en bromsning av viktökningen av stor betydelse för hälsan." },
        4: { title: "Fetma", description: "Det är hög tid att gå ner i vikt för att inte riskera att drabbas av olika sjukdomar, t ex högt blodtryck, förhöjda blodfetter och diabetes typ 2. Hur stora hälsoriskerna är beror på graden av fetma och hur man mår i övrigt. För individuell rådgivning och ev behandling kan man kontakta en vårdcentral eller företagshälsovård." }
    },
    fat: {
        1: { title: "Farligt låg nivå", description: "Din kropp har en skadligt låg nivå av kroppsfett!" },
        2: { title: "Låg nivå", description: "Din kropp har en låg nivå av kroppsfett" },
        3: { title: "Normal nivå", description: "Din kropp har en nomal nivå av kroppsfett" },
        4: { title: "Hög nivå", description: "Din kropp har en hög nivå av kroppsfett" },
        5: { title: "Extrem nivå", description: "Din kropp har en extremt hög nivå av kroppsfett" }
    },
    water: {
        1: { title: "För låg", description: "Andelen vätska i din kropp är för låg! Du kan uppleva symton som huvudvärk och trötthet. Du löper även större risk att drabbas av sjukdomar" },
        2: { title: "Normal", description: "Andelen vätska i din kropp är normal." },
        3: { title: "För hög", description: "Andelen vätska i drin kropp är för hög! Du riskerar att drabbas av vattenförgiftning!" }
    },
    muscles: {
        1: { title: "Låg nivå", description: "Du har en mycket låg andel muskler." },
        2: { title: "Normal nivå", description: "Din kropp har en nomal muskelmassa" },
        3: { title: "Hög nivå", description: "Din kropp har en högre nomal muskelmassa" },
        4: { title: "Mycket hög nivå", description: "Du har en hög nivå av muskler." },
        5: { title: "Extrem nivå", description: "Din kropp har en extremt hög nivå av muskelmassa" }
    },
    met_age: {
        1: { title: "Yngre", description: "Din metaboliska ålder är yngre än din fysiska ålder! Mycket bra!" },
        2: { title: "Äldre", description: "Din metaboliska ålder är äldre än din fysiska ålder! Du behöver förändra dina vanor!" },
        3: { title: "Samma", description: "Din metaboliska ålder är samma som din fysiska ålder." }
    }
}

var scan_levels = {
    bmi:
    {
        male: [
            {
                age: [0, 200],
                levels: {
                    1: { range: [0, 20], title: scan_texts.bmi[1].title, description: scan_texts.bmi[2].description },
                    2: { range: [20.1, 25], title: scan_texts.bmi[2].title, description: scan_texts.bmi[2].description },
                    3: { range: [25.1, 30], title: scan_texts.bmi[3].title, description: scan_texts.bmi[3].description },
                    4: { range: [30.1, 50], title: scan_texts.bmi[4].title, description: scan_texts.bmi[4].description },
                }
            }],
        female: [{
            age: [0, 200],
            levels:
            {
                1: { range: [0, 18.6], title: scan_texts.bmi[1].title, description: scan_texts.bmi[2].description },
                2: { range: [18.7, 23.8], title: scan_texts.bmi[2].title, description: scan_texts.bmi[2].description },
                3: { range: [23.9, 28.6], title: scan_texts.bmi[3].title, description: scan_texts.bmi[3].description },
                4: { range: [28.6, 50], title: scan_texts.bmi[4].title, description: scan_texts.bmi[4].description },
            }
        }]
    },
    fat:
    {
        male: [
            {
                age: [5, 10],
                levels: {
                    1: { range: [0, 4.9], title: scan_texts.fat[1].title, description: scan_texts.fat[1].description },
                    2: { range: [5, 14.9], title: scan_texts.fat[2].title, description: scan_texts.fat[2].description },
                    3: { range: [15, 25], title: scan_texts.fat[3].title, description: scan_texts.fat[3].description },
                    4: { range: [25.1, 35], title: scan_texts.fat[4].title, description: scan_texts.fat[4].description },
                    5: { range: [36.1, 45], title: scan_texts.fat[5].title, description: scan_texts.fat[5].description }
                }
            },
            {
                age: [11, 19],
                levels: {
                    1: { range: [0, 4.9], title: scan_texts.fat[1].title, description: scan_texts.fat[1].description },
                    2: { range: [5, 14.9], title: scan_texts.fat[2].title, description: scan_texts.fat[2].description },
                    3: { range: [15, 25], title: scan_texts.fat[3].title, description: scan_texts.fat[3].description },
                    4: { range: [25.1, 35], title: scan_texts.fat[4].title, description: scan_texts.fat[4].description },
                    5: { range: [36.1, 45], title: scan_texts.fat[5].title, description: scan_texts.fat[5].description }
                }
            },
            {
                age: [20, 39],
                levels: {
                    1: { range: [0, 4.9], title: scan_texts.fat[1].title, description: scan_texts.fat[1].description },
                    2: { range: [5, 14.9], title: scan_texts.fat[2].title, description: scan_texts.fat[2].description },
                    3: { range: [15, 25], title: scan_texts.fat[3].title, description: scan_texts.fat[3].description },
                    4: { range: [25.1, 35], title: scan_texts.fat[4].title, description: scan_texts.fat[4].description },
                    5: { range: [36.1, 45], title: scan_texts.fat[5].title, description: scan_texts.fat[5].description }
                }
            },
            {
                age: [40, 59],
                levels: {
                    1: { range: [0, 4.9], title: scan_texts.fat[1].title, description: scan_texts.fat[1].description },
                    2: { range: [5, 14.9], title: scan_texts.fat[2].title, description: scan_texts.fat[2].description },
                    3: { range: [15, 25], title: scan_texts.fat[3].title, description: scan_texts.fat[3].description },
                    4: { range: [25.1, 35], title: scan_texts.fat[4].title, description: scan_texts.fat[4].description },
                    5: { range: [36.1, 45], title: scan_texts.fat[5].title, description: scan_texts.fat[5].description }
                }
            },
            {
                age: [60, 200],
                levels: {
                    1: { range: [0, 4.9], title: scan_texts.fat[1].title, description: scan_texts.fat[1].description },
                    2: { range: [5, 14.9], title: scan_texts.fat[2].title, description: scan_texts.fat[2].description },
                    3: { range: [15, 25], title: scan_texts.fat[3].title, description: scan_texts.fat[3].description },
                    4: { range: [25.1, 35], title: scan_texts.fat[4].title, description: scan_texts.fat[4].description },
                    5: { range: [36.1, 45], title: scan_texts.fat[5].title, description: scan_texts.fat[5].description }
                }
            }
        ],
        female: [
            {
                age: [5, 10],
                levels: {
                    1: { range: [0, 4.9], title: scan_texts.fat[1].title, description: scan_texts.fat[1].description },
                    2: { range: [5, 14.9], title: scan_texts.fat[2].title, description: scan_texts.fat[2].description },
                    3: { range: [15, 25], title: scan_texts.fat[3].title, description: scan_texts.fat[3].description },
                    4: { range: [25.1, 35], title: scan_texts.fat[4].title, description: scan_texts.fat[4].description },
                    5: { range: [36.1, 45], title: scan_texts.fat[5].title, description: scan_texts.fat[5].description }
                }
            },
            {
                age: [11, 19],
                levels: {
                    1: { range: [0, 4.9], title: scan_texts.fat[1].title, description: scan_texts.fat[1].description },
                    2: { range: [5, 14.9], title: scan_texts.fat[2].title, description: scan_texts.fat[2].description },
                    3: { range: [15, 25], title: scan_texts.fat[3].title, description: scan_texts.fat[3].description },
                    4: { range: [25.1, 35], title: scan_texts.fat[4].title, description: scan_texts.fat[4].description },
                    5: { range: [36.1, 45], title: scan_texts.fat[5].title, description: scan_texts.fat[5].description }
                }
            },
            {
                age: [20, 39],
                levels: {
                    1: { range: [0, 4.9], title: scan_texts.fat[1].title, description: scan_texts.fat[1].description },
                    2: { range: [5, 14.9], title: scan_texts.fat[2].title, description: scan_texts.fat[2].description },
                    3: { range: [15, 25], title: scan_texts.fat[3].title, description: scan_texts.fat[3].description },
                    4: { range: [25.1, 35], title: scan_texts.fat[4].title, description: scan_texts.fat[4].description },
                    5: { range: [36.1, 45], title: scan_texts.fat[5].title, description: scan_texts.fat[5].description }
                }
            },
            {
                age: [40, 59],
                levels: {
                    1: { range: [0, 4.9], title: scan_texts.fat[1].title, description: scan_texts.fat[1].description },
                    2: { range: [5, 14.9], title: scan_texts.fat[2].title, description: scan_texts.fat[2].description },
                    3: { range: [15, 25], title: scan_texts.fat[3].title, description: scan_texts.fat[3].description },
                    4: { range: [25.1, 35], title: scan_texts.fat[4].title, description: scan_texts.fat[4].description },
                    5: { range: [36.1, 45], title: scan_texts.fat[5].title, description: scan_texts.fat[5].description }
                }
            },
            {
                age: [60, 200],
                levels: {
                    1: { range: [0, 4.9], title: scan_texts.fat[1].title, description: scan_texts.fat[1].description },
                    2: { range: [5, 14.9], title: scan_texts.fat[2].title, description: scan_texts.fat[2].description },
                    3: { range: [15, 25], title: scan_texts.fat[3].title, description: scan_texts.fat[3].description },
                    4: { range: [25.1, 35], title: scan_texts.fat[4].title, description: scan_texts.fat[4].description },
                    5: { range: [36.1, 45], title: scan_texts.fat[5].title, description: scan_texts.fat[5].description }
                }
            }
        ]
    },
    water:
    {
        male: [
            {
                age: [0, 15],
                levels: {
                    1: { range: [0, 57.9], title: scan_texts.water[1].title, description: scan_texts.water[2].description },
                    2: { range: [58, 72], title: scan_texts.water[2].title, description: scan_texts.water[2].description },
                    3: { range: [72.1, 100], title: scan_texts.water[3].title, description: scan_texts.water[3].description },
                }
            },
            {
                age: [16, 30],
                levels: {
                    1: { range: [0, 52.9], title: scan_texts.water[1].title, description: scan_texts.water[2].description },
                    2: { range: [53, 67], title: scan_texts.water[2].title, description: scan_texts.water[2].description },
                    3: { range: [67.1, 100], title: scan_texts.water[3].title, description: scan_texts.water[3].description },
                }
            },
            {
                age: [31, 60],
                levels: {
                    1: { range: [0, 46.9], title: scan_texts.water[1].title, description: scan_texts.water[2].description },
                    2: { range: [47, 61], title: scan_texts.water[2].title, description: scan_texts.water[2].description },
                    3: { range: [62.1, 100], title: scan_texts.water[3].title, description: scan_texts.water[3].description },
                }
            },
            {
                age: [61, 200],
                levels: {
                    1: { range: [0, 41.9], title: scan_texts.water[1].title, description: scan_texts.water[2].description },
                    2: { range: [42, 56], title: scan_texts.water[2].title, description: scan_texts.water[2].description },
                    3: { range: [57.1, 100], title: scan_texts.water[3].title, description: scan_texts.water[3].description },
                }
            }],
        female: [
            {
                age: [0, 15],
                levels: {
                    1: { range: [0, 56.9], title: scan_texts.water[1].title, description: scan_texts.water[2].description },
                    2: { range: [57, 67], title: scan_texts.water[2].title, description: scan_texts.water[2].description },
                    3: { range: [67.1, 100], title: scan_texts.water[3].title, description: scan_texts.water[3].description },
                }
            },
            {
                age: [16, 30],
                levels: {
                    1: { range: [0, 46.9], title: scan_texts.water[1].title, description: scan_texts.water[2].description },
                    2: { range: [47, 57], title: scan_texts.water[2].title, description: scan_texts.water[2].description },
                    3: { range: [58.1, 100], title: scan_texts.water[3].title, description: scan_texts.water[3].description },
                }
            },
            {
                age: [31, 60],
                levels: {
                    1: { range: [0, 41.9], title: scan_texts.water[1].title, description: scan_texts.water[2].description },
                    2: { range: [42, 52], title: scan_texts.water[2].title, description: scan_texts.water[2].description },
                    3: { range: [52.1, 100], title: scan_texts.water[3].title, description: scan_texts.water[3].description },
                }
            },
            {
                age: [61, 200],
                levels: {
                    1: { range: [0, 36.9], title: scan_texts.water[1].title, description: scan_texts.water[2].description },
                    2: { range: [37, 47], title: scan_texts.water[2].title, description: scan_texts.water[2].description },
                    3: { range: [47.1, 100], title: scan_texts.water[3].title, description: scan_texts.water[3].description },
                }
            }],
    },
    phys_level:
    {
        male: [
            {
                age: [0, 200],
                levels: {
                    1: { range: [1, 1], title: "Dold fetma", description: "<span class='font-weight-bold'>Lite övervikt.</span> Personen har en hälsosam kroppstyp baserat på fysiken. Men har hög procent kroppsfett och låg nivå muskelmassa." },
                    2: { range: [2, 2], title: "Fetma", description: "<span class='font-weight-bold'>Medium övervikt.</span> Personen verkar ha en hälsosam kroppstyp baserat på fysiken. Men har hög procent kroppsfett och låg nivå muskelmassa." },
                    3: { range: [3, 3], title: "Kraftigt bygd", description: "<span class='font-weight-bold'>Stor övervikt.</span> Personen har både hög procent kroppsfett och hög nivå muskelmassa." },
                    4: { range: [4, 4], title: "Otränad", description: "<span class='font-weight-bold'>Lite muskler och genomsnittlig procent kroppsfett.</span> Personen har en genomsnittlig procent kroppsfett men en låg nivå muskelmassa." },
                    5: { range: [5, 5], title: "Standard", description: "<span class='font-weight-bold'>Genomsnittlig procent kroppsfett och muskler.</span>" },
                    6: { range: [6, 6], title: "Standard muskulär", description: "<span class='font-weight-bold'>Hög muskelmassa och genomsnittlig procent kroppsfett (Atlet).</span> Personen har en genomsnittlig procent kroppsfett och en hög nivå muskelmassa." },
                    7: { range: [7, 7], title: "Tunn", description: "<span class='font-weight-bold'>Lite muskler och lite fett.</span> Både muskelmassan och nivån kroppsfett är under genomsnittet." },
                    8: { range: [8, 8], title: "Tunn och muskulär", description: "<span class='font-weight-bold'>Atlet.</span> Personen har kroppsfett under det genomsnittliga och en lämplig muskelmassa." },
                    9: { range: [9, 9], title: "Väldigt muskulär", description: "<span class='font-weight-bold'>Atlet.</span> Personen har kroppsfett under det genomsnittliga och över genomsnittet i muskelmassa." }
                }
            }],
        female: [
            {
                age: [0, 200],
                levels: {
                    1: { range: [1, 1], title: "Dold fetma", description: "<span class='font-weight-bold'>Lite övervikt.</span> Personen har en hälsosam kroppstyp baserat på fysiken. Men har hög procent kroppsfett och låg nivå muskelmassa." },
                    2: { range: [2, 2], title: "Fetma", description: "<span class='font-weight-bold'>Medium övervikt.</span> Personen verkar ha en hälsosam kroppstyp baserat på fysiken. Men har hög procent kroppsfett och låg nivå muskelmassa." },
                    3: { range: [3, 3], title: "Kraftigt bygd", description: "<span class='font-weight-bold'>Stor övervikt.</span> Personen har både hög procent kroppsfett och hög nivå muskelmassa." },
                    4: { range: [4, 4], title: "Otränad", description: "<span class='font-weight-bold'>Lite muskler och genomsnittlig procent kroppsfett.</span> Personen har en genomsnittlig procent kroppsfett men en låg nivå muskelmassa." },
                    5: { range: [5, 5], title: "Standard", description: "<span class='font-weight-bold'>Genomsnittlig procent kroppsfett och muskler.</span>" },
                    6: { range: [6, 6], title: "Standard muskulär", description: "<span class='font-weight-bold'>Hög muskelmassa och genomsnittlig procent kroppsfett (Atlet).</span> Personen har en genomsnittlig procent kroppsfett och en hög nivå muskelmassa." },
                    7: { range: [7, 7], title: "Tunn", description: "<span class='font-weight-bold'>Lite muskler och lite fett.</span> Både muskelmassan och nivån kroppsfett är under genomsnittet." },
                    8: { range: [8, 8], title: "Tunn och muskulär", description: "<span class='font-weight-bold'>Atlet.</span> Personen har kroppsfett under det genomsnittliga och en lämplig muskelmassa." },
                    9: { range: [9, 9], title: "Väldigt muskulär", description: "<span class='font-weight-bold'>Atlet.</span> Personen har kroppsfett under det genomsnittliga och över genomsnittet i muskelmassa." }
                }
            }
        ]
    },
    bellyfat:
    {
        male: [
            {
                age: [0, 200],
                levels: {
                    1: { range: [1, 6], title: "Låg nivå", description: "Din kropp har en låg nivå av bukfett" },
                    2: { range: [6.1, 12.5], title: "Normal nivå", description: "Din kropp har en nomal nivå av bukfett" },
                    3: { range: [12.6, 30], title: "Hög nivå", description: "Din kropp har en hög nivå av bukfett" },
                    4: { range: [30.1, 59], title: "Extrem nivå", description: "Din kropp har en extremt hög nivå av bukfett" }
                }
            }],
        female: [
            {
                age: [0, 200],
                levels: {
                    1: { range: [1, 6], title: "Låg nivå", description: "Din kropp har en låg nivå av bukfett" },
                    2: { range: [6.1, 12.5], title: "Normal nivå", description: "Din kropp har en nomal nivå av bukfett" },
                    3: { range: [12.6, 30], title: "Hög nivå", description: "Din kropp har en hög nivå av bukfett" },
                    4: { range: [30.1, 59], title: "Extrem nivå", description: "Din kropp har en extremt hög nivå av bukfett" }
                }
            }]
    },
    bone_mas:
    {
    },
    muscles:
    {
        male: [
            {
                age: [0, 200],
                levels: {
                    1: { range: [0, 39.9], title: scan_texts.muscles[1].title, description: scan_texts.muscles[1].description },
                    2: { range: [40, 69.9], title: scan_texts.muscles[2].title, description: scan_texts.muscles[2].description },
                    3: { range: [70, 79.9], title: scan_texts.muscles[3].title, description: scan_texts.muscles[3].description },
                    4: { range: [80, 94.9], title: scan_texts.muscles[4].title, description: scan_texts.muscles[4].description },
                    5: { range: [95, 100], title: scan_texts.muscles[5].title, description: scan_texts.muscles[5].description }
                }
            }],
        female: [{
            age: [0, 200],
            levels: {
                1: { range: [0, 33.9], title: scan_texts.muscles[1].title, description: scan_texts.muscles[1].description },
                2: { range: [34, 54.9], title: scan_texts.muscles[2].title, description: scan_texts.muscles[2].description },
                3: { range: [55, 74.9], title: scan_texts.muscles[3].title, description: scan_texts.muscles[3].description },
                4: { range: [75, 94.9], title: scan_texts.muscles[4].title, description: scan_texts.muscles[4].description },
                5: { range: [95, 100], title: scan_texts.muscles[5].title, description: scan_texts.muscles[5].description }
            }
        }
        ]
    }
}

var default_graph_item =
{
    title: "",
    description: "",
    value: 0,
    trend: { type: 0, dir: 0, text: "" },
    gauge: {
        value: 0,
        options: {
            hasNeedle: true,
            outerNeedle: false,
            needleColor: "#7D2E68",
            needleStartValue: 0,
            arcColors: [],
            arcDelimiters: [],
            rangeLabel: [],
            arcLabels: [],
            chartWidth: 380,
            rangeLabelFontSize: 14,
            needleValue: 0,
            arcPadding: 10,
            arcPaddingColor: "transparent"
        },
    },
    line: {
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scaleOverride: true,
            scaleSteps: 9,
            scaleStepWidth: 1,
            scaleStartValue: 1
        },
        data: {}
    },
    view: "gauge"
}

export default {
    measurements()
    {
        var values = store.getters['user/measurements'];
        if (values) return values;
        return [];
    },
    goals()
    {
        var values = store.getters['user/goals'];
        if (values) return values;
        return [];
    },
    values(name)
    {
        return this._getMeasurements(name);
    },
    getBodyItem(key, levels, max, compare_key)
    {
        var item = this._getMeasurement(key);
        var compValue = item.value;
        if (compare_key)
        {
            var compitem = this._getMeasurement(compare_key);
            compValue = compitem.value;
        }

        var needle = this._number((compValue / max) * 100, 2);
        var t = ""; var d = "";
        var values = this.getRangeValues(levels);
        var arcdel = [];
        for (var i in values)
        {
            var range = values[i].range;
            arcdel.push((range[1] / max) * 100);
        }
        arcdel.splice(-1, 1);
        var fitem = this._getRange(compValue, values);
        if (fitem)
        {
            t = fitem.title;
            d = fitem.description;
        }
        return { value: item.value, prevalue: item.prevalue, needle: needle, title: t, text: d, arcDelimiters: arcdel };
    },
    getBodyItemPercentageOfWeigt(key, levels)
    {
        var weight = this._getMeasurement("weight");
        var item = this._getMeasurement(key);

        var needle = this._number((item.value / weight.value) * 100, 2);
        var t = ""; var d = "";
        var values = this.getRangeValues(levels);
        var arcdel = [];
        for (var i in values)
        {
            var range = values[i].range;
            arcdel.push(range[1]);
        }
        arcdel.splice(-1, 1);
        var fitem = this._getRange(needle, values);
        if (fitem)
        {
            t = fitem.title;
            d = fitem.description;
        }
        return { value: item.value, prevalue: item.prevalue, needle: needle, title: t, text: d, arcDelimiters: arcdel };
    },
    getWeight()
    {
        var max = 40;
        var levels = scan_levels.bmi;
        var key = "weight";
        return this.getBodyItem(key, levels, max, "bmi");
    },
    getBMI()
    {
        var max = 40;
        var levels = scan_levels.bmi;
        var key = "bmi";
        return this.getBodyItem(key, levels, max);
    },
    getFat()
    {
        var max = 45;
        var levels = scan_levels.fat;
        var key = "fat";
        return this.getBodyItem(key, levels, max);
    },
    getWater()
    {
        var max = 100;
        var levels = scan_levels.water;
        var key = "water";
        return this.getBodyItem(key, levels, max);
    },
    getPhysLevel()
    {
        var max = 10;
        var levels = scan_levels.phys_level;
        var key = "phys_level";
        return this.getBodyItem(key, levels, max);
        // var pl = this._getMeasurement("");
        // var needle = this._number((pl.value / 10) * 100, 2);
        // return { value: pl.value, prevalue: pl.prevalue, needle: needle, title: phys_level[pl.value].title, text: phys_level[pl.value].description };
    },
    getBellyFat()
    {
        var max = 60;
        var levels = scan_levels.bellyfat;
        var key = "belly_fat";
        return this.getBodyItem(key, levels, max);
        // var fat = this._getMeasurement("belly_fat");
        // var needle = this._number((fat.value / 60) * 100, 2);
        // var t = "";
        // var d = "";
        // var fitem = this._getRange(fat.value, bellyfat);
        // if (fitem)
        // {
        //     t = fitem.title;
        //     d = fitem.description;
        // }

        // return { value: fat.value, prevalue: fat.prevalue, needle: needle, title: t, text: d };
    },
    getMuscles()
    {
        var max = 100;
        var levels = scan_levels.muscles;
        var key = "muscles";
        return this.getBodyItemPercentageOfWeigt(key, levels);
    },
    getMetAge()
    {
        var age = this.age();
        var max = age * 2;
        var arcdel = [25, 50, 75];
        var key = "met_age";
        var item = this._getMeasurement(key);
        var needle = item.value / max * 100;
        var t = scan_texts.met_age[3].title;
        var d = scan_texts.met_age[3].description;
        if (item.value < age)
        {
            t = scan_texts.met_age[1].title;
            d = scan_texts.met_age[1].description;
        }
        else if (item.value > age)
        {
            t = scan_texts.met_age[2].title;
            d = scan_texts.met_age[2].description;
        }

        return { value: item.value, prevalue: item.prevalue, needle: needle, title: t, text: d, arcDelimiters: arcdel };
    },
    getTapeMeasurement(key)
    {
        var item = this._getMeasurement(key);
        var needle = 0;
        var t = "";
        var d = "";

        return { value: item.value, prevalue: item.prevalue, needle: needle, title: t, text: d };
    },
    _getMeasurement(name)
    {
        var val = 0;
        var preval = 0;
        var measurements = this.measurements();
        var hit = false;
        for (var i = 0; i < measurements.length; i++)
        {
            if (measurements[i].data[name])
            {
                if (!hit)
                {
                    val = measurements[i].data[name].value;
                    hit = true;
                }
                else
                {
                    preval = measurements[i].data[name].value;
                    break;
                }
            }
        }
        return { value: this._number(val, 2), prevalue: this._number(preval, 2) };
    },
    _getMeasurements(name)
    {
        var data = [];
        var measurements = this.measurements();
        for (var i = measurements.length - 1; i >= 0; i--)
        {
            if (measurements[i].data[name])
            {
                var val = measurements[i].data[name].value;
                var date = measurements[i].date;
                data.push({ date: date, value: this._number(val, 2) });
            }
        }
        return data;
    },
    _getGoal(name)
    {
        var val = 0;
        var preval = 0;
        var goals = this.goals();
        var hit = false;
        for (var i = 0; i < goals.length; i++)
        {
            if (goals[i].data[name])
            {
                if (!hit)
                {
                    val = goals[i].data[name].value;
                    hit = true;
                }
                else
                {
                    preval = goals[i].data[name].value;
                    break;
                }
            }
        }
        return { value: this._number(val, 2), prevalue: this._number(preval, 2) };
    },
    _getGoals(name)
    {
        var data = [];
        var goals = this.goals();
        for (var i = goals.length - 1; i >= 0; i--)
        {
            if (goals[i].data[name])
            {
                var val = goals[i].data[name].value;
                var date = goals[i].date;
                data.push({ date: date, value: this._number(val, 2) });
            }
        }
        return data;
    },
    _number(value, decimals)
    {
        var num = this._numb(value, decimals);
        return parseFloat(num);
    },
    _numb(value, decimals)
    {
        value = "" + value;

        value = value.replace(",", ".");
        //value = value.replace(" ", "");
        value = value.replace(/\s/g, "");

        //vi har ändrat så bara punkter? vad om redan fanns?
        // räkna ut om flera?
        var first = value.indexOf(".");
        var last = value.lastIndexOf(".");
        if (first != last)
        {
            // ta bort första
            value = value.replace(".", "");
        }

        if (isNaN(value) || value == "")
            value = 0;
        if (isNaN(decimals))
            decimals = 2; // 2 som default
        value = "" + value;
        //return parseFloat(parseFloat(value.replace(",", ".")).toFixed(decimals));
        var val = parseFloat(value);
        var num = this.correctFloat(val);
        num = num.toFixed(decimals);
        return num;
    },
    correctFloat(float)
    {
        if (float >= 0)
            return float + 0.0000001;
        else
            return float - 0.0000001;
    },
    getRangeValues(array)
    {
        var sex = this.sex();
        var age = this.age();
        for (var i = 0; array[sex].length; i++)
        {
            if (this._inAgeRange(age, array[sex][i].age))
            {
                return array[sex][i].levels;
            }
        }
        return false;
    },
    _getRange(val, items)
    {
        for (var id in items)
        {
            var min = items[id].range[0];
            var max = items[id].range[1];
            if (val >= min && val <= max)
                return items[id];
        }
        return false;
    },
    _inAgeRange(age, ages)
    {
        if (age >= ages[0] && age <= ages[1])
            return true;
        return false;
    },
    _user()
    {
        return JSON.parse(localStorage.getItem('user'));
    },
    _account()
    {
        return JSON.parse(localStorage.getItem('user')).account;
    },
    height()
    {
        return this._user().height;
    },
    sex()
    {
        return personnummer.parse(this._user().ssnr).isMale() ? "male": "female";
    },
    age()
    {
        return personnummer.parse(this._user().ssnr).getAge();
    },
    ageAtDate(aDate)
    {
        var pn = personnummer.parse(this._user().ssnr);
        var pnr = pn.format(true);
        var year = parseInt(pnr.substring(0,4));
        var month = parseInt(pnr.substring(4,6));
        var day = parseInt(pnr.substring(6,8));
        var dob = new Date(year, month, day);

        var diff_ms = new Date(aDate) - dob.getTime();
        var age_dt = new Date(diff_ms); 
      
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    },
    getDefaultObject()
    {
        return JSON.parse(JSON.stringify(default_graph_item));
    }
};

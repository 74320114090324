import axios from "axios";

const API_URL_GET = process.env.VUE_APP_API_URL_GET;

export default {
      state: {
            packages: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            },           
            package:
            {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null,
            }
      },

      getters: {
            packagesloading: state =>
            {
                  return state.packages.loading;
            },
            packages: state =>
            {
                  return state.packages.data;
            },            
            packageloading: state =>
            {
                  return state.package.loading;
            },
            package: state =>
            {
                  return state.package.data;
            }
      },

      mutations: {
            setPackages(state, data)
            {
                  state.packages.data = data;
            },
            setPackagesLoading(state, status)
            {
                  state.packages.loading = status;
            },
            setPackagesError(state, status)
            {
                  state.packages.error = status;
            },
            setPackagesErrorMessage(state, message)
            {
                  state.packages.errorMessage = message;
            },            
            setPackage(state, data)
            {
                  state.package.data = data;
            },
            setPackageLoading(state, status)
            {
                  state.package.loading = status;
            },
            setPackageError(state, status)
            {
                  state.package.error = status;
            },
            setPackageErrorMessage(state, message)
            {
                  state.package.errorMessage = message;
            }
      },

      actions: {
            async getPackages({ commit })
            {
                  commit("setPackagesLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'packages';
                        response = await axios.get(url, { });
                  }
                  catch (error)
                  {
                        commit("setPackagesLoading", false);
                        commit("setPackagesError", true);
                        commit("setPackagesErrorMessage", error.message);
                  }
                  if (response)
                  {
                        commit("setPackages", response.data.data);
                        commit("setPackagesLoading", false);
                        commit("setPackagesError", false);
                        commit("setPackagesErrorMessage", "");
                  }
            },

            async getPackage({ commit }, id)
            {
                  commit("setPackageLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'package?id=' + id;
                        response = await axios.get(url, {});
                  }
                  catch (error)
                  {
                        commit("setPackageLoading", false);
                        commit("setPackageError", true);
                        commit("setPackageErrorMessage", error.message);
                  }
                  if (response)
                  {
                        commit("setPackage", response.data.data);
                        commit("setPackageLoading", false);
                        commit("setPackageError", false);
                        commit("setPackageErrorMessage", "");
                  }
            }
      },

      namespaced: true
};

<template>
<div class="KroppsTable">
      <div>
            <p>Du har totalt {{ measurements.length }} mätningar</p>
      </div>
    <div class="mb-2">
        <b-row>
            <b-col>
                <b-table sort-by="date" stacked="lg" responsive :fields="headerfields" :items="measurements" hover></b-table>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
export default {
    name: 'KroppsTable',
    components: {},
    data() {
        return {
            ready: false,
            headerfields: [{
                    key: 'date',
                    label: 'Datum',
                    headerTitle: 'Datum för mätning',
                    sortable: true,
                    // formatter: (value) => {
                    //       return this.$time.FormatTime(value);
                    // }
                },
                {
                    key: 'data.weight.value',
                    label: 'Vikt',
                    headerTitle: 'Vikt i kilo (kg)',
                    sortable: true,
                    class: 'text-right',
                },
                {
                    key: 'data.bmi.value',
                    label: 'BMI',
                    headerTitle: 'Ditt BMI (Body Mass Index)',
                    sortable: true,
                    class: 'text-right',
                },
                {
                    key: 'data.fat.value',
                    label: 'Fett%',
                    headerTitle: 'Fettprocent %',
                    sortable: true,
                    class: 'text-right',
                },
                {
                    key: 'data.water.value',
                    label: 'Vatten%',
                    headerTitle: 'Vattenprocent %',
                    sortable: true,
                    class: 'text-right',
                },
                {
                    key: 'data.muscles.value',
                    label: 'Muskler',
                    headerTitle: 'Muskler i kilo (kg)',
                    sortable: true,
                    class: 'text-right',
                },
                {
                    key: 'data.phys_level.value',
                    label: 'Fysik',
                    headerTitle: 'Din fysiska grad mellan 1-9, ju högre desto bättre',
                    sortable: true,
                    class: 'text-right',
                },
                {
                    key: 'data.bone_min.value',
                    label: 'BMD',
                    headerTitle: 'Benmineral (Bone Mineral Density)',
                    sortable: true,
                    class: 'text-right',
                },
                {
                    key: 'data.base_met.value',
                    label: 'BMR',
                    headerTitle: 'Basmetabolism (Basal Metabolic Rate), eg Kalorier per dag',
                    sortable: true,
                    class: 'text-right',
                },
                {
                    key: 'data.met_age.value',
                    label: 'Ålder',
                    headerTitle: 'Metabolisk ålder, ämnesomsättningsålder är kroppens inre ålder',
                    sortable: true,
                    class: 'text-right',
                },
                {
                    key: 'data.belly_fat.value',
                    label: 'Bukfett',
                    headerTitle: 'Andel fett runt buken, Ju lägre desto bättre',
                    sortable: true,
                    class: 'text-right',
                },
                {
                    key: 'data.bust_cm.value',
                    label: 'Byst',
                    headerTitle: 'Mått runt byst/bröst i cm',
                    sortable: true,
                    class: 'text-right',
                },
                {
                    key: 'data.waist_cm.value',
                    label: 'Midja',
                    headerTitle: 'Mått runt midjan i cm',
                    sortable: true,
                    class: 'text-right',
                },
                {
                    key: 'data.stomach_cm.value',
                    label: 'Mage',
                    headerTitle: 'Mått över magen i cm (navelhöjd)',
                    sortable: true,
                    class: 'text-right',
                },
                {
                    key: 'data.hip_cm.value',
                    label: 'Höft',
                    headerTitle: 'Mått över höften i cm',
                    sortable: true,
                    class: 'text-right',
                },
            ]
        };
    },
    computed: {
        measurements() {
            return this.$measurements.measurements();
        }
    },
    methods: {
        date(date) {
            return this.$date.stringToIsoDateOnly(date);
        },
    },
    mounted() {},
};
</script>

<template>
<div class="ingredients-table">
      <div class="mt-4 mb-4">
            <b-form-group>
                  <b-input-group>
                        <b-form-input v-model="filter" type="search" id="filterInput" class="form-control" placeholder="Sök ingrediens..."></b-form-input>
                        <b-input-group-append>
                              <b-button :disabled="!filter" @click="filter = ''">Rensa</b-button>
                        </b-input-group-append>
                  </b-input-group>
            </b-form-group>
      </div>
      <div>
            <b-row>
                  <b-col>
                        <b-table sort-by="name" stacked="sm" responsive :filter="filter" :fields="headerfields" :items="ingredients"  hover selectable select-mode="single" @row-selected="rowSelected">
                        </b-table>
                  </b-col>
            </b-row>
      </div>
</div>
</template>

<script>
export default {
      name: 'IngredientsTable',
      components: {},
      data() {
            return {
                  ready: false,
                  filter: '',
                  headerfields: [{
                              key: 'name',
                              label: 'Namn',
                              headerTitle: 'Ingrediens',
                              sortable: true
                        },
                        {
                              key: 'calories',
                              label: 'Kalorier (kcal)',
                              headerTitle: 'Innehåll av kalorier',
                              sortable: true,
                              class: 'text-right',
                        },
                        {
                              key: 'protein',
                              label: 'Protein',
                              headerTitle: 'Innehåll av protein',
                              sortable: true,
                              class: 'text-right',
                        },
                        {
                              key: 'carbs',
                              label: 'Kolhydrater',
                              headerTitle: 'Innehåll av kolhydrater',
                              sortable: true,
                              class: 'text-right',
                        },
                        {
                              key: 'fat',
                              label: 'Fett',
                              headerTitle: 'Innehåll av fett',
                              sortable: true,
                              class: 'text-right',
                        }
                  ]
            };
      },
      computed: {
            ingredients() {
                  return this.$store.getters["ingredients/data"];
            }
      },
      methods: {
            date(date) {
                  return this.$date.stringToIsoDateOnly(date);
            },
            rowSelected(items) {
                  var item = items[0];
                  this.$router.push({
                        name: 'ingrediens',
                        params: {
                              id: item.id,
                              name: this.$formatter.url(item.name)
                        }
                  });
            },
      },
      mounted() {},
};
</script>

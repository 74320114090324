<template>
<Graph v-if="ready" :item="item"></Graph>
</template>

<script>
import Graph from '@/components/kroppskoll/Graph';

export default {
      name: 'AgeComponent',
      components: {
            Graph
      },
      data() {
            return {
                  ready: false,
                  key: "met_age",    
                  title: "Metabolisk ålder",              
                  item: false,
                  arcColors: ["rgb(0,128,0)", "rgb(61,204,91)", "rgb(239,214,19)", "rgb(255,84,84)"],
                  arcDelimiters: [25, 50, 75],
                  rangeLabel: ["Ung", "Gammal"],
                  arcLabels: ["Yngre", "Yngre/Äldre", "Äldre"],
            };
      },
      computed: {

      },
      methods: {
            init() {
                  var obj = this.$measurements.getDefaultObject();
                  this.item = obj;
                  this.item.gauge.options.arcColors = this.arcColors;
                  this.item.gauge.options.arcDelimiters = this.arcDelimiters;
                  this.item.gauge.options.rangeLabel = this.rangeLabel;
                  this.item.gauge.options.arcLabels = this.arcLabels;
                  this.setValues();
                  this.setGaugeWidth();
                  this.ready = true;
            },
            setValues() {
                  // hämta senaste BMI
                  var age = this.$measurements.getMetAge();
                  //this.item.gauge.options.arcDelimiters = age.arcDelimiters;
                  // ta fram "needle"
                  this.item.gauge.options.needleValue = age.needle;
                  this.item.gauge.value = age.title;
                  this.item.value = age.value;
                  this.item.title = this.title + ": " + age.value + "år, " + this.item.gauge.value;
                  this.item.description = age.text;

                  if (age.value < age.prevalue) {
                        this.item.trend.type = 1;
                        this.item.trend.dir = -1;
                  } else if (age.value > age.prevalue) {
                        this.item.trend.type = -1;
                        this.item.trend.dir = 1;
                  }
                  this.item.trend.value = age.value - age.prevalue;

                  var allgoals = this.$measurements._getGoals(this.key);
                  var cgoal = 0;
                  var hasgoal = false;
                  
                  // linje
                  var labels = [];
                  var data = [];
                  var agedata = [];
                  var goals = [];
                  var all = this.$measurements.values(this.key);
                  for (var i = 0; i < all.length; i++) {
                        labels.push(all[i].date);
                        data.push(all[i].value);
                        var currentAge = this.$measurements.ageAtDate(all[i].date);
                        agedata.push(currentAge);
                        var goal = this.getGoal(all[i].date, allgoals);
                        if (goal) {
                              cgoal = goal;
                              hasgoal = true;
                        }

                        goals.push(cgoal);
                  }
                  this.item.line.data = {
                        labels: labels,
                        datasets: [{
                              label: this.title,
                              borderColor: "#7D2E68",
                              data: data,
                              fill: false
                        },
                        {
                              label: "Din ålder",
                              borderColor: "blue",
                              data: agedata,
                              fill: false
                        }]
                  };
                  if (hasgoal)
                        this.item.line.data.datasets.push({
                              label: "Ditt mål",
                              borderColor: "green",
                              data: goals,
                              borderDash: [30, 25],
                              spanGaps: true,
                              fill: false
                        });
            },
            getGoal(date, goals) {
                  for (var i = 0; i < goals.length; i++) {
                        if (goals[i].date == date)
                              return goals[i].value;
                  }
                  return false;
            },
            setGaugeWidth() {
                  var h = 400;
                  if (window.innerWidth < 500) {
                        h = 300;
                  }

                  this.item.gauge.options.chartWidth = h;
            }
      },
      mounted() {
            this.init();
      }
};
</script>

<template>
<div class="p-3 rounded-lg shadow">
      <h3>{{item.title}}
            <Trend :trend="item.trend"></Trend>
            <b-icon icon="question-circle" class="pointer cursor" v-b-popover.html="item.description"></b-icon>
      </h3>
      <div v-if="item.view == 'gauge'">
            <vue-gauge class="nocolor" :refid="id" :options="item.gauge.options"></vue-gauge>
      </div>
      <div v-else-if="item.view == 'line'">
            <LineChart :chartData="item.line.data" :chartOptions="item.line.options" :height="250"></LineChart>
      </div>
      <div>
            <b-button @click="setView('line')" style="margin-top:-50px;" variant="outline-primary" v-if="item.view == 'gauge'">
                  <b-icon class="mr-2" icon="graph-up"></b-icon>Visa utveckling
            </b-button>
            <b-button @click="setView('gauge')" variant="outline-primary" v-if="item.view == 'line'">
                  <b-icon class="mr-2" icon="pie-chart"></b-icon>Visa nuvarande
            </b-button>
      </div>
</div>
</template>

<script>
import VueGauge from 'vue-gauge';
import LineChart from '@/components/charts/LineChart';
import Trend from '@/components/kroppskoll/Trend';
/* eslint-disable */
export default {
      name: 'GraphComponent',
      props: ["item"],
      components: {
            VueGauge,
            LineChart,
            Trend
      },
      computed: {
            id() {
                  return "gauge_" + this.$formatter.round((Math.random() * 10000), 0);
            }
      },
      methods: {
            setView(type){
                  this.item.view = type;
            }
      }
};
</script>

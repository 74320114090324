import axios from 'axios';

const API_URL_GET = process.env.VUE_APP_API_URL_GET;

class ProductsService {
  GetProducts() {
    return axios.get(API_URL_GET + 'products');
  }

  GetProduct(id) {
    return axios.get(API_URL_GET + 'product?id=' + id);
  }
}

export default new ProductsService();

<template>
<div class="ingredients">
      <div class="mt-0 mb-5 text-center">
            <h1 class="text-main">Ingredienser och näringsinnehåll</h1>
      </div>
      <div v-if="view == 1">
            <div v-if="ingredients.ready">
                  <div class="mb-5 font-italic text-center">
                        Alla värden nedan är näringsinnehåll per 100 gram.
                  </div>
                  <IngredientsTable></IngredientsTable>
            </div>
            <div v-else>
                  <Loader loadingtext="Hämtar ingredienser..."></Loader>
            </div>
      </div>
      <div v-else-if="view == 2">
            <div v-if="ingredient.ready">
                  <Ingredient></Ingredient>
            </div>
            <div v-else>
                  <Loader loadingtext="Hämtar ingrediens..."></Loader>
            </div>
      </div>
</div>
</template>

<script>
import IngredientsTable from '@/components/ingredients/IngredientsTable';
import Ingredient from '@/components/ingredients/Ingredient';
import Loader from '@/components/common/Loader';
/* eslint-disable */
export default {
      name: 'Kroppkoll',
      components: {
            IngredientsTable,
            Ingredient,
            Loader
      },
      data() {
            return {
                  view: 1,
                  ingredients: {
                        ready: false
                  },
                  ingredient: {
                        ready: false
                  }
            };
      },
      metaInfo() {
            return {
                  title: "Ingredienser och näringsinnehåll",
                  meta: [{
                        name: 'description',
                        content: "Här listas alla ingredienter du använder dagligen och dess näringsinnehåll"
                  }]
            }
      },
      methods: {
            checkPage() {
                  var id = this.$route.params.id;
                  if (id && id != "") {
                        this.view = 2;
                        this.GetIngredient(id);
                  } else {
                        this.view = 1;
                        this.GetIngredients();
                  }
            },
            GetIngredients() {
                  // hämta in ingredienter
                  this.$store.dispatch('ingredients/getIngredients').then(() => {
                        this.ingredients.ready = true;
                        //this.$store.dispatch("favoritesget/getFavorites", 3).then(() => {});
                  });
            },
            GetIngredient(id) {
                  this.$store.dispatch('ingredients/getIngredient', id).then(() => {
                        this.ingredient.ready = true;
                  });
            }
      },
      watch: {
            $route(to, from) {
                  if (to != from)
                        this.checkPage();
            }
      },
      mounted() {
            this.checkPage();
      },
};
</script>

import axios from "axios";

const API_URL_GET = process.env.VUE_APP_API_URL_GET;
const API_URL_POST = process.env.VUE_APP_API_URL_POST;

export default {
      state: {
            error: false,
            errorMessage: "",
            loading: false,
            data: false,
            pagesize: 40,
            page: 1,
      },

      getters: {
            loading: state =>
            {
                  return state.loading;
            },
            data: state =>
            {
                  var items = [];                  
                  for (var i = 0; i < state.data.length; i++)
                  {
                        var item = state.data[i];                        
                        var node = JSON.parse(item.json);
                        if(node.is_video)
                              continue;
                        items.push(node);
                  }
                  if(items.length > state.page * state.pagesize)
                        return items.splice(0, state.page * state.pagesize);
                  else return items;
            },
            error: state =>
            {
                  return state.error;
            },
            message: state =>
            {
                  return state.message;
            }
      },

      mutations: {
            setData(state, data)
            {
                  state.data = data;
            },
            setLoading(state, status)
            {
                  state.loading = status;
            },
            setError(state, status)
            {
                  state.error = status;
            },
            setErrorMessage(state, message)
            {
                  state.errorMessage = message;
            },
            increasePage(state)
            {
                  state.page += 1;
            }
      },

      actions: {
            async getInstagram({ commit })
            {
                  commit("setLoading", true);
                  let response;
                  try
                  {
                        let url = API_URL_GET + 'instagram';
                        response = await axios.get(url);
                  }
                  catch (error)
                  {
                        commit("setLoading", false);
                        commit("setError", true);
                        commit("setErrorMessage", error.message);
                        return Promise.reject(console);
                  }
                  if (response)
                  {
                        commit("setData", response.data.saved);
                        commit("setLoading", false);
                        commit("setError", false);
                        commit("setErrorMessage", "");
                        return Promise.resolve(response);
                  }
            },
            async addInstagram({ commit }, items)
            {
                  commit("setLoading", true);
                  var add = {
                        items: items,
                        key: "instaadd"
                  };
                  let response;
                  try
                  {
                        let url = API_URL_POST + 'instagram-add';
                        response = await axios.post(url, add);
                  }
                  catch (error)
                  {
                        commit("setLoading", false);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setLoading", false);
                        return Promise.resolve(true);
                  }
                  return response;
            },
            async checkforupdates({ dispatch })
            {
                  let response;
                  try
                  {
                        let url = API_URL_GET + 'instagram-update';
                        response = await axios.get(url);
                        if (response && response.data.update == "true")
                        {
                              var instagram = JSON.parse(response.data.data);
                              var items = [];
                              for (var i = 0; i < instagram.data.user.edge_owner_to_timeline_media.edges.length; i++)
                              {
                                    var item = instagram.data.user.edge_owner_to_timeline_media.edges[i].node;
                                    var additem =
                                    {
                                          id: item.id,
                                          json: JSON.stringify(item)
                                    };
                                    items.push(additem);
                              }
                              dispatch("addInstagram", items);
                              return Promise.resolve(true);
                        }
                  }
                  catch (error)
                  {
                        return false;
                  }

            },

            increasepage({commit})
            {
                  commit("increasePage");
            }
      },

      namespaced: true
};

import axios from 'axios';
import authHeader from './auth-header';

//const API_URL_GET = process.env.VUE_APP_API_URL_GET;
const API_URL_POST = process.env.VUE_APP_API_URL_POST;

class ContactService {
  AddMessage(email, message) {
      
    return axios.post(API_URL_POST + 'contact', 
    { email: email, message: message, method: "add" }, 
    { 
      headers:
      {
        "content-type": "application/x-www-form-urlencoded"
      }});
  }

  KroppsKontakt()
  {
    return axios.post(API_URL_POST + 'kroppskontakt', {}, { headers: authHeader() });
  }
}

export default new ContactService();

export default {
      getSvgUrl(svgImg)
      {
            const url = require("@/assets/svg/" + svgImg);
            return url;
      },
      getProductImage(id)
      {
            let url = '/images/products/png/' + id + '.png';
            //let url = "/images/products/" + id + ".jpg";
            return url;
      },
      getFailedProductImage() {
            let url = '/images/products/default.png';
            return url;
      },   
};

<template>
  <b-container>
    <b-row class="font-weight-bold table border-bottom">
        <b-col>Vad</b-col><b-col>{{ m1.date }} ({{ m1_age }} år)</b-col><b-col>{{ m2.date }} ({{ m2_age }} år)</b-col><b-col>+/-</b-col>
    </b-row>
    <b-row v-for="row in rows" :key="row.id" class="py-2  compare-row justify-content-center">
        <b-col>{{ row.title }}</b-col>
        <b-col>{{ row.values.m1 }} <span v-if="row.rel.m1">({{ row.rel.m1 }})</span></b-col>
        <b-col>{{ row.values.m2 }} <span v-if="row.rel.m2">({{ row.rel.m2 }})</span></b-col>
        <b-col><Trend :trend="row.trend"></Trend></b-col>
    </b-row>    
  </b-container>
</template>

<style>

.compare-row:nth-child(even) 
{
    background-color: rgba(0,0,0,.05);
}

</style>

<script>

import Trend from '@/components/kroppskoll/Trend';

export default {
    props: ["m1", "m2"],
    components:{ 
        Trend
    },
    computed:
    {
        rows(){
            var rows = [];
            rows.push(this.buildRow("phys_level", "Fysisk nivå", false));
            rows.push(this.buildRow("weight", "Vikt", true, "kg"));
            rows.push(this.buildRow("fat", "Fettprocent", true, "%"));
            rows.push(this.buildRow("muscles", "Muskler", false, "kg"));
            rows.push(this.buildRow("belly_fat", "Bukfett", true, ""));
            rows.push(this.buildRow("bmi", "BMI", true, ""));
            rows.push(this.buildRow("water", "Andel vätska", false, "%"));
            rows.push(this.buildRow("met_age", "Metabolisk ålder", true, "år", this.m1_age, this.m2_age));
            rows.push(this.buildRow("bust_cm", "Bystmått", false, "cm"));
            rows.push(this.buildRow("waist_cm", "Midjemått", true, "cm"));
            rows.push(this.buildRow("stomach_cm", "Mage", true, "cm"));
            rows.push(this.buildRow("hip_cm", "Höftmått", true, "cm"));            
            return rows;
        },
        m1_age(){
            return this.$measurements.ageAtDate(this.m1.date);
        },
        m2_age(){
            return this.$measurements.ageAtDate(this.m2.date);
        },
        age(){
            return this.$measurements.age();
        }
    },
    methods: {
        buildRow(key, title, negative_positive, value_suffix, rel_value_1, rel_value_2){
            if(value_suffix)
                value_suffix = " " + value_suffix;
            else value_suffix = "";
            var m1_value = this.m1.data[key]?.value ?? 0;
            var m2_value = this.m2.data[key]?.value ?? 0;
            var trendType = 0;
            var trendDir = 0;
            if (m1_value < m2_value) 
            {
                trendType = negative_positive ? 1 : -1;
                trendDir = -1;
            } 
            else if (m1_value > m2_value) 
            {
                trendType = negative_positive ? -1 : 1;
                trendDir = 1;
            }

            var rel = false;

            if(rel_value_1 && rel_value_2){
                rel = {
                    m1: (m1_value - rel_value_1) + value_suffix,
                    m2: (m2_value - rel_value_2) + value_suffix
                };
            }                            

            return {
                key: key,
                title: title,
                values: {
                    m1: m1_value + value_suffix,
                    m2: m2_value + value_suffix
                },   
                rel: rel,             
                trend: {
                    value: m1_value - m2_value,
                    type: trendType,
                    dir: trendDir,
                    suffix: value_suffix
                }
            }
        }
    }
}
</script>

<style>

</style>
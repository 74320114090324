import images from "@/helpers/images";
import formatter from "@/helpers/formatter";
import time from "@/helpers/time";
import date from "@/helpers/date";
import instagram from "@/helpers/instagram";
import measurements from "@/helpers/measurements";
import recipe from "@/helpers/recipe";
import lookup from "@/helpers/lookup";
import url from "@/helpers/url";
import cart from "@/helpers/cart";

export default {
    install(Vue) {        
        Vue.prototype.$images = images;
        Vue.prototype.$formatter = formatter;
        Vue.prototype.$time = time;
        Vue.prototype.$date = date;
        Vue.prototype.$instagram = instagram;
        Vue.prototype.$measurements = measurements;    
        Vue.prototype.$recipe = recipe;
        Vue.prototype.$url = url;
        Vue.prototype.$lookup = lookup;
        Vue.prototype.$cart = cart;
    }
};
<template>
<div class="stepper">
      <div v-for="step in steps" :class="Classnames(step)" :key="step.index">
            <div class="step-box">
                  <div class="progress-left"></div>
                  <div class="progress-right"></div>
                  <div class="step-title">
                        <inline-svg :src="svg(step.icon)" class="mr-2 align-middle"></inline-svg> <label class="align-middle">{{step.title}}</label>
                  </div>
                  <div class="step-sub-title">
                        {{step.subtitle}}
                  </div>                  
            </div>
      </div>
</div>
</template>

<script>
export default {
      name: 'StepperComponent',
      props: ["steps", "current"],
      data() {
            return {                  
            };
      },
      computed: {           
            currentIndex()
            {
                  return this.current || 0;
            }
      },
      mounted() {

      },
      methods: {
            Classnames(step) {
                  var classes = "step-item"
                  if (step.index < this.currentIndex)
                        classes += " complete";
                  else if (step.index == this.currentIndex)
                        classes += " active";
                  return classes;
            },
            svg(path) {
                  return this.$images.getSvgUrl(path);
            }
      }
}
</script>

import AdminService from '../services/admin.service';

export default {
      namespaced: true,
      state:
      {
            instagram: 
            {
                  loading: false,
                  success: false,
                  error: false,
                  errors: []
            }
      },

      getters: {
            instagramLoading: state =>
            {
                  return state.instagram.loading;
            },
            instagramError: state =>
            {
                  return state.instagram.error;
            },
            instagramErrors: state =>
            {
                  return state.instagram.errors;
            }
      },

      mutations: {
            setInstagramLoading(state, loading)
            {
                  state.instagram.loading = loading;
            },
            setInstagramSuccess(state, success)
            {
                  state.instagram.success = success;
            },
            setInstagramError(state, error)
            {
                  state.instagram.error = error;
            },
            setInstagramErrors(state, errors)
            {
                  state.instagram.errors = errors;
            }
      },

      actions: {
            SaveUpdateInstagramItem({ commit }, item)
            {
                  commit("setInstagramLoading", true);
                  return AdminService.AddInstagram(item).then(
                        item =>
                        {
                              commit('setInstagramSuccess', true);
                              commit('setInstagramError', false);
                              commit("setInstagramLoading", false);
                              return Promise.resolve(item);
                        },
                        error =>
                        {
                              commit('setInstagramSuccess', false);
                              commit('setInstagramError', true);
                              commit('setInstagramErrors', error.response.data.errors);
                              commit("setInstagramLoading", false);
                              return Promise.reject(error.response.data);
                        }
                  );
            }
      }
};

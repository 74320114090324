<template>
<div class="contact">
      <b-container>
            <div class="mt-0 mb-5 text-center ">
                  <h1 class="text-main">Kontakt</h1>
            </div>
            <div v-if="loading">
                  <Loader loadingtext="Vänta..."></Loader>
            </div>
            <div v-else-if="!sent">
                  <b-alert show variant="info" class="text-center">
                        Ange din e-postadress och ett meddelande så återkopplar jag till din e-postadress så fort jag kan!
                  </b-alert>
                  <h4>Skriv ett meddelande</h4>
                  <form name="form" @submit.prevent="sendForm">
                        <div class="mt-2 mb-2">
                              <input v-model="email" type="email" name="email" v-validate="'required'" placeholder="Ange din e-postadress" class="form-control" />
                              <div v-if="errors.has('email')" class="alert alert-danger mt-1" role="alert">Ange din e-postadress!</div>
                        </div>
                        <div class="mt-2 mb-2">
                              <b-form-textarea id="message" placeholder="Ange meddelande" rows="3" max-rows="8" name="message" v-model="message" v-validate="'required'"></b-form-textarea>
                              <div v-if="errors.has('message')" class="alert alert-danger mt-1" role="alert">Ange ett meddelande!</div>
                        </div>
                        <div class="mt-2 mb-2 text-center">
                              <button class="btn btn-primary">Skicka</button>
                        </div>
                  </form>
            </div>
            <div v-else>

                  <b-alert class="text-center" v-if="success" show variant="success">{{responsemessage}}</b-alert>

                  <b-alert class="text-center" v-else show variant="danger">{{responsemessage}}</b-alert>

            </div>
      </b-container>
      <b-modal @ok="doSendForm" title="Bekräfta meddelande" ref="confirmmodal" footer-class="text-center" cancel-title="Avbryt">
            <div class="p-2 text-center">
                  <h3>Vill du skicka meddelandet?</h3>
                  <div>
                        <span class="font-weight-bold">Din epostadress:</span>
                        <br>
                        <div class="font-italic">
                              {{email}}
                        </div>
                  </div>
                  <div>
                        <span class="font-weight-bold">Ditt meddelande:</span>
                        <br>
                        <div class="font-italic">
                              {{message}}
                        </div>
                  </div>
            </div>
      </b-modal>
</div>
</template>

<script>
import ContactService from '@/services/contact.service';
import Loader from "@/components/common/Loader";

export default {
      name: 'ContactComponent',
      components: {
            Loader
      },
      metaInfo: {
            title: 'Kontakt',
            meta: [{
                  name: 'description',
                  content: "Behöver du komma i kontakt med mig? Fyll i formuläret och ange dina kontaktuppgiter så hör jag av mig så fort jag kan!"
            }]
      },
      data() {
            return {
                  email: '',
                  formValid: false,
                  loading: false,
                  message: '',
                  sent: false,
                  success: false,
                  responsemessage: ""
            };
      },
      methods: {
            sendForm() {
                  this.$validator.validateAll().then(isValid => {
                        if (!isValid) {
                              this.formValid = false;
                              return;
                        }
                        this.formValid = true;
                        this.$refs.confirmmodal.show();
                  });
            },
            doSendForm() {
                  if (!this.formValid)
                        return;
                  this.loading = true;
                  ContactService.AddMessage(this.email, this.message).then(
                        response => {
                              var item = response.data;
                              this.loading = false;
                              this.responsemessage = item.message;
                              this.sent = true;
                              if (item.status == "ok") {
                                    this.success = true;
                              }
                        },
                        error => {
                              this.loading = false;
                              this.content =
                                    (error.response && error.response.data) ||
                                    error.message ||
                                    error.toString();
                        }
                  );
            }
      }
};
</script>

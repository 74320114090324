import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_GET = process.env.VUE_APP_API_URL_GET;

export default {
      state: {
            recipes: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null,
                  hasimages: []
            },
            categories:
            {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null,
            },
            recipe:
            {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null,
                  images: null,
                  favorite: false,
            }
      },

      getters: {
            recipesloading: state =>
            {
                  return state.recipes.loading;
            },
            recipes: state =>
            {
                  return state.recipes.data;
            },
            hasimages: state => {
                  return state.recipes.hasimages;
            },
            categoriesloading: state =>
            {
                  return state.categories.loading;
            },
            categories: state =>
            {
                  return state.categories.data;
            },
            recipeloading: state =>
            {
                  return state.categories.loading;
            },
            recipe: state =>
            {
                  return state.recipe.data;
            },
            recipeimages: state =>
            {
                  return state.recipe.images;
            },
            recipefavorite: state =>
            {
                  return state.recipe.favorite;
            }
      },

      mutations: {
            setRecipes(state, data)
            {
                  state.recipes.data = data;
            },
            setRecipesLoading(state, status)
            {
                  state.recipes.loading = status;
            },
            setRecipesImages(state, images)
            {
                  state.recipes.hasimages = images;
            },
            setRecipesError(state, status)
            {
                  state.recipes.error = status;
            },
            setRecipesErrorMessage(state, message)
            {
                  state.recipes.errorMessage = message;
            },
            setCategories(state, data)
            {
                  state.categories.data = data;
            },
            setCategoriesLoading(state, status)
            {
                  state.categories.loading = status;
            },
            setCategoriesError(state, status)
            {
                  state.categories.error = status;
            },
            setCategoriesErrorMessage(state, message)
            {
                  state.categories.errorMessage = message;
            },
            setRecipe(state, data)
            {
                  state.recipe.data = data;
            },
            setRecipeImages(state, data)
            {
                  state.recipe.images = data;
            },
            setRecipeFavorite(state, data)
            {
                  state.recipe.favorite = data;
            },
            setRecipeLoading(state, status)
            {
                  state.recipe.loading = status;
            },
            setRecipeError(state, status)
            {
                  state.recipe.error = status;
            },
            setRecipeErrorMessage(state, message)
            {
                  state.recipe.errorMessage = message;
            }
      },

      actions: {
            async getRecipes({ commit })
            {
                  commit("setRecipesLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'recipes';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setRecipesLoading", false);
                        commit("setRecipesError", true);
                        commit("setRecipesErrorMessage", error.message);
                  }
                  if (response)
                  {
                        commit("setRecipes", response.data.data);
                        commit("setRecipesImages", response.data.hasimages);
                        commit("setRecipesLoading", false);
                        commit("setRecipesError", false);
                        commit("setRecipesErrorMessage", "");
                  }
            },

            async getCategories({ commit })
            {
                  commit("setCategoriesLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'categories';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setCategoriesLoading", false);
                        commit("setCategoriesError", true);
                        commit("setCategoriesErrorMessage", error.message);
                  }
                  if (response)
                  {
                        commit("setCategories", response.data.data);
                        commit("setCategoriesLoading", false);
                        commit("setCategoriesError", false);
                        commit("setCategoriesErrorMessage", "");
                  }
            },

            async getRecipe({ commit }, id)
            {
                  commit("setRecipeLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'recipe?id=' + id;
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setRecipeLoading", false);
                        commit("setRecipeError", true);
                        commit("setRecipeErrorMessage", error.message);
                  }
                  if (response)
                  {
                        commit("setRecipe", response.data.data);
                        commit("setRecipeImages", response.data.images);
                        commit("setRecipeFavorite", response.data.favorite);                        
                        commit("setRecipeLoading", false);
                        commit("setRecipeError", false);
                        commit("setRecipeErrorMessage", "");
                  }
            },

            setRecipeFavorite({commit}, value)
            {
                  commit("setRecipeFavorite", value);
            }
      },

      namespaced: true
};

<template>
<div class="col-md-12">
      <div class="text-center text-main">
            <h1>Logga in</h1>
      </div>
      <div class="card card-container">
            <div v-if="display == 2">
                  <b-list-group>
                        <b-list-group-item button v-for="user in allusers" :key="user.email" @click="SetLatest(user)">
                              <div class="d-flex align-items-center">
                                    <div>
                                          <b-img class="login-avatar" center rounded="circle" :src="uavatar(user)"></b-img>
                                    </div>
                                    <div class="ml-3">
                                          <h3 class="text-primary">{{user.email}}</h3>
                                    </div>
                              </div>
                        </b-list-group-item>
                  </b-list-group>
                  <div class="mt-4 text-center">
                        <h4><a class="link-primary pointer" @click="DisplayNone()">Annan användare</a></h4>
                  </div>
            </div>
            <form v-else name="form" @submit.prevent="handleLogin">
                  <div v-if="users.latest">
                        <div class="text-center">
                              <b-img class="login-avatar" center rounded="circle" :src="avatar()"></b-img>
                              <div class="mt-2 mb-2 font-large">
                                    {{users.latest.name}}</div>
                              (<a class="mt-2 link-primary pointer" @click="DisplayAllUsers()">Byt användare</a>)
                              <div class="d-none">
                                    <input v-model="user.email" v-validate="'required'" type="text" class="form-control" name="email" />
                              </div>
                        </div>
                  </div>
                  <div v-else>
                        <div class="form-group">
                              <label for="email">E-postadress</label>
                              <input v-model="user.email" v-validate="'required'" type="text" class="form-control" name="email" />
                              <div v-if="errors.has('email')" class="alert alert-danger mt-1" role="alert">Ange en e-postadress!</div>
                        </div>
                  </div>
                  <div class="form-group">
                        <label for="password">Lösenord</label>
                        <input v-model="user.password" v-validate="'required'" type="password" class="form-control" name="password" />
                        <div v-if="errors.has('password')" class="alert alert-danger mt-1" role="alert">Ange ett lösenord!</div>
                  </div>
                  <div class="form-group">
                        <button class="btn btn-primary btn-block" :disabled="loading">
                              <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                              <span>Logga in</span>
                        </button>
                  </div>
                  <div class="form-group">
                        <div v-if="message" class="alert alert-danger" role="alert">{{message}}</div>
                  </div>
                  <b-row>
                        <b-col md="12">
                              <router-link class="btn btn-primary w-100" v-bind:to="{ path: '/glomtlosenord'}">Glömt lösenord</router-link>
                        </b-col>
                        <!-- <b-col md="6">
                              <router-link class="btn btn-primary w-100" v-bind:to="{ path: '/registrera'}">Registrera</router-link>
                        </b-col> -->
                  </b-row>
            </form>
      </div>
</div>
</template>

<script>
import User from '@/models/user';
/* eslint-disable */
export default {
      name: 'Login',
      data() {
            return {
                  user: new User('', ''),
                  loading: false,
                  message: '',
                  display: 1
            };
      },
      metaInfo: {
            title: 'Logga in',
            meta: [{
                  name: 'description',
                  content: "Är du medlem på kroppskollen loggar du in för att komma åt dina uppgifter och få tillgång till erbjudanden för medlemmar"
            }]
      },
      computed: {
            loggedIn() {
                  return this.$store.state.auth.status.loggedIn;
            },
            users() {
                  return this.prevusers();
            },
            allusers() {
                  var all = [];
                  var used = {};
                  for (var id in this.users.all) {
                        if (used[id])
                              continue;
                        var u = this.users.all[id];
                        all.push(u);
                        used[u.email] = u;
                  }

                  return all;
            }
      },
      created() {
            if (this.loggedIn) {
                  this.$router.push('/minsida');
            } else {
                  if (this.users.latest)
                        this.user.email = this.users.latest.email;
            }
      },
      methods: {
            handleLogin() {
                  this.loading = true;
                  this.$validator.validateAll().then(isValid => {
                        if (!isValid) {
                              this.loading = false;
                              return;
                        }

                        if (this.user.email && this.user.password) {
                              this.$store.dispatch('auth/login', this.user).then(
                                    () => {
                                          var redirect = this.$route.query["redirect"];
                                          if (redirect)
                                                this.$router.push(redirect);
                                          else
                                                this.$router.push('/minsida');
                                    },
                                    error => {
                                          this.loading = false;
                                          this.message =
                                                (error.response && error.response.data) ||
                                                error.message ||
                                                error.toString();
                                    }
                              );
                        }
                  });
            },
            prevusers() {
                  var users = JSON.parse(localStorage.getItem("savedusers"));
                  if (!users)
                        return {
                              latest: false,
                              all: {}
                        };
                  return users;
            },
            hasavatar() {
                  if (this.users.latest && this.users.latest.image)
                        return true;
                  return false;
            },
            avatar() {
                  if (this.hasavatar())
                        return "/images/users/" + this.users.latest.image;
                  else
                        return require("@/assets/images/user.jpg");
            },
            uavatar(user) {
                  if (user.image)
                        return "/images/users/" + user.image;
                  else
                        return require("@/assets/images/user.jpg");
            },
            DisplayAllUsers() {
                  this.display = 2;
            },
            DisplayNone() {
                  var users = this.users;
                  users.latest = false;
                  this.user.email = '';
                  this.user.password = '';
                  localStorage.setItem("savedusers", JSON.stringify(users));
                  this.display = 1;
            },
            SetLatest(user) {
                  var users = this.users;
                  users.latest = user;
                  this.user.email = user.email;
                  this.user.password = '';
                  localStorage.setItem("savedusers", JSON.stringify(users));
                  this.display = 1;
            },
      }
};
</script>

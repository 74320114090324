<template>
      <Graph v-if="ready" :item="item"></Graph>
</template>

<script>
import Graph from '@/components/kroppskoll/Graph';

export default {
      name: 'WeightComponent',
      components: {
            Graph
      },
      data() {
            return {
                  ready: false,
                  item: false,
                  key: "weight",
                  title: "Vikt",
                  arcColors: ["rgb(255,84,84)", "rgb(239,214,19)", "rgb(61,204,91)", "rgb(239,214,19)", "rgb(255,84,84)"],
                  arcDelimiters: [20, 47, 63, 75],
                  rangeLabel: ["extrem undervikt", "extrem fetma"],
                  arcLabels: ["undervikt", "normal", "övervikt", "fetma"],
            };
      },
      computed: {

      },
      methods: {
            init() {
                  var obj = this.$measurements.getDefaultObject();
                  this.item = obj;                  
                  this.item.gauge.options.arcColors = this.arcColors;
                  this.item.gauge.options.arcDelimiters = this.arcDelimiters;
                  this.item.gauge.options.rangeLabel = this.rangeLabel;
                  this.item.gauge.options.arcLabels = this.arcLabels;
                  this.setValues();
                  this.setGaugeWidth();
                  this.ready = true;
            },
            setValues() {
                  // hämta senaste BMI
                  var weight = this.$measurements.getWeight();
                  //this.item.gauge.options.arcDelimiters = weight.arcDelimiters;
                  // ta fram "needle"
                  this.item.gauge.options.needleValue = weight.needle;
                  this.item.gauge.value = weight.title;
                  this.item.value = weight.value;
                  this.item.title = this.title + ": " + weight.value + " kg, " + this.item.gauge.value;
                  this.item.description = weight.text;

                  if (weight.value < weight.prevalue) {
                        this.item.trend.type = 1;
                        this.item.trend.dir = -1;                        
                  } else if (weight.value > weight.prevalue) {
                        this.item.trend.type = -1;
                        this.item.trend.dir = 1;
                  }
                  this.item.trend.value = weight.value - weight.prevalue;

                  var allgoals = this.$measurements._getGoals(this.key);
                  var cgoal = 0;
                  var hasgoal = false;

                  // linje
                  var labels = [];
                  var data = [];
                  var goals = [];
                  var all = this.$measurements.values(this.key);
                  for (var i = 0; i < all.length; i++) {
                        labels.push(all[i].date);
                        data.push(all[i].value);
                        var goal = this.getGoal(all[i].date, allgoals);
                        if (goal) {
                              cgoal = goal;
                              hasgoal = true;
                        }
                        
                        goals.push(cgoal);
                  }
                  this.item.line.data = {
                        labels: labels,
                        datasets: [{
                                    label: this.title,
                                    borderColor: "#7D2E68",
                                    data: data,
                                    fill: false
                              }]
                  };
                   if (hasgoal)
                        this.item.line.data.datasets.push({
                              label: "Din målvikt",
                              borderColor: "green",
                              data: goals,
                              borderDash: [30, 25],
                              spanGaps: true,
                              fill: false
                        });
            },
            getGoal(date, goals)
            {
                  for(var i = 0; i < goals.length; i++)
                  {
                        if(goals[i].date == date)
                              return goals[i].value;
                  }
                  return false;
            },
            setGaugeWidth() {
                  var h = 400;
                  if (window.innerWidth < 500) {
                        h = 300;
                  }

                  this.item.gauge.options.chartWidth = h;
            }
      },
      mounted() {
            this.init();
      }
};
</script>

<template>
<div>
      <div v-if="currentUser">
            <div>
                  <router-link class="btn btn-primary" to="/recept">Alla recept</router-link>
            </div>
            <div v-if="hasrecipe">
                  <div class="mt-0 mb-3 text-center">
                        <h1 class="text-main">{{ recipe.title }}
                              <span :title="isFavorite? 'Avmarkera som favorit': 'Markera som favorit'">
                                    <inline-svg class="favorite ml-2 large" v-on:click.stop.prevent="EditFavorite()" :src="svgimage('common/star.svg')" v-bind:class="{ 'filled': isFavorite }"></inline-svg>
                              </span>
                        </h1>
                  </div>
                  <div class="recipe">
                        <div class="container">
                              <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                          <div class="card">
                                                <h3 class="text-center">
                                                      <inline-svg :src="svgimage('recipe/info.svg')" class="header-icon" />
                                                      <span>Info</span>
                                                </h3>
                                                <div class="container">
                                                      <div class="row">
                                                            <div class="col font-weight-bold">
                                                                  <inline-svg :src="svgimage('recipe/category.svg')" />
                                                                  Kategori
                                                            </div>
                                                            <div class="col text-right">
                                                                  {{ recipe.categoryitem.name }}
                                                            </div>
                                                      </div>
                                                      <div class="row">
                                                            <div class="col font-weight-bold">
                                                                  <inline-svg :src="svgimage('recipe/portions.svg')" />
                                                                  Portioner
                                                            </div>
                                                            <div class="col text-right">
                                                                  {{ recipe.portions }}
                                                            </div>
                                                      </div>
                                                      <div class="row">
                                                            <div class="col font-weight-bold">
                                                                  <inline-svg :src="svgimage('recipe/tid.svg')" />
                                                                  Tid
                                                            </div>
                                                            <div class="col text-right">
                                                                  {{ time(recipe.time) }}
                                                            </div>
                                                      </div>
                                                      <div class="row">
                                                            <div class="col font-weight-bold">
                                                                  <inline-svg :src="svgimage('recipe/added.svg')" />
                                                                  Inlagd
                                                            </div>
                                                            <div class="col text-right">
                                                                  {{ date(recipe.createdate) }}
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                          <div class="card">
                                                <h3 class="text-center">
                                                      <inline-svg :src="svgimage('recipe/nutrition.svg')" class="header-icon" />
                                                      <span>Näringsinnehåll per portion</span>
                                                </h3>
                                                <div class="container">
                                                      <div class="row">
                                                            <div class="col font-weight-bold">
                                                                  <inline-svg :src="svgimage('recipe/calories.svg')" />
                                                                  Kalorier
                                                            </div>
                                                            <div class="col text-right">
                                                                  {{getNutrition("calories")}} kcal
                                                            </div>
                                                      </div>
                                                      <div class="row">
                                                            <div class="col font-weight-bold">
                                                                  <inline-svg :src="svgimage('recipe/carbs.svg')" />
                                                                  Kolhydrater
                                                            </div>
                                                            <div class="col text-right">
                                                                  {{ getNutrition("carbs") }} gram
                                                            </div>
                                                      </div>
                                                      <div class="row">
                                                            <div class="col font-weight-bold">
                                                                  <inline-svg :src="svgimage('recipe/proteins.svg')" />
                                                                  Protein
                                                            </div>
                                                            <div class="col text-right">
                                                                  {{ getNutrition("protein") }} gram
                                                            </div>
                                                      </div>
                                                      <div class="row">
                                                            <div class="col font-weight-bold">
                                                                  <inline-svg :src="svgimage('recipe/fat.svg')" />
                                                                  Fett
                                                            </div>
                                                            <div class="col text-right">
                                                                  {{ getNutrition("fat") }} gram
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div class="container">
                              <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                          <div class="card">
                                                <h3 class="text-center">
                                                      <inline-svg :src="svgimage('recipe/recipes.svg')" class="header-icon" />
                                                      <span>Ingredienser</span>
                                                </h3>
                                                <div class="ingredients-group mt-2 mb-2 pb-4" v-for="(group, index) in recipe.ingredients" :key="index">
                                                      <div class="ml-2 mb-2 text-main h4 font-weight-bold">{{ recipe.groups[index].name }}</div>
                                                      <b-container>
                                                            <b-row>
                                                                  <b-col cols="6">Namn</b-col>
                                                                  <b-col cols="3" class="text-right">Mått</b-col>
                                                                  <b-col cols="3" class="text-right">Gram</b-col>
                                                            </b-row>
                                                            <div v-for="item in group" :key="item.id">
                                                                  <b-row>
                                                                        <b-col cols="6" @click="goToIngredient(item)" class="btn-link pointer">
                                                                              <span v-html="getIngredeientName(item)"></span>
                                                                        </b-col>
                                                                        <b-col cols="3" class="text-right">
                                                                              <span v-html="getAmountText(item)"></span>
                                                                        </b-col>
                                                                        <b-col cols="3" class="text-right">
                                                                              <span v-html="getGramText(item)"></span>
                                                                        </b-col>
                                                                  </b-row>
                                                            </div>
                                                      </b-container>
                                                </div>
                                          </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                          <div class="card">
                                                <h3 class="text-center">
                                                      <inline-svg :src="svgimage('recipe/instructions.svg')" class="header-icon" />
                                                      <span>Gör så här</span>
                                                </h3>
                                                <div class="mt-2" v-for="item in recipe.texts" :key="item.id">
                                                      <span v-html="item.text"></span>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        <div class="container">
                              <div class="row">
                                    <div class="col">
                                          <div class="card">
                                                <h3 class="text-center">
                                                      <inline-svg :src="svgimage('recipe/recipes.svg')" class="header-icon" />
                                                      <span>Detaljerat näringsinnehåll</span>
                                                </h3>
                                                <div class="mt-4 pb-4">
                                                      <RecipeIngredientsTable ref="IngredientsTableRef" :recipe="recipe"></RecipeIngredientsTable>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        <div class="container mb-5">
                              <div class="row">
                                    <div class="col" cols="auto">
                                          <div class="card">
                                                <h3 class="text-center">
                                                      <inline-svg :src="svgimage('recipe/images.svg')" class="header-icon" />
                                                      Bilder
                                                </h3>                                                
                                                <div v-if="hasimages()" class="text-center">
                                                      <p class="font-italic">Klicka på en bild för att förstora</p>
                                                      <b-img @click="showImage(item, index)" class="recipe-image m-2 pointer" v-for="(item, index) in images" :key="index" thumbnail fluid rounded :src="recipeimage(item)" alt="Center image"></b-img>
                                                </div>
                                                <div v-else>
                                                      Inga bilder...
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
            <div v-else>
                  <Loader loadingtext="Läser recept..."></Loader>
            </div>
            <b-modal ok-only ok-title="Stäng" hide-header v-model="largeimage.visible" size="xl">                  
                  <b-carousel :fade="true" v-model="largeimage.index" :interval="3500" controls indicators >
                        <b-carousel-slide v-for="(item, index) in images" :key="index" :img-src="recipeimage(item)"></b-carousel-slide>
                  </b-carousel>
            </b-modal>
      </div>
      <div v-else>
            <NeedLogin :redirect="url()" :text="text()"></NeedLogin>
      </div>
</div>
</template>

<script>
const Loader = () => import('@/components/common/Loader');
import NeedLogin from '@/components/common/NeedLogin';
import RecipeIngredientsTable from '@/components/recipe/RecipeIngredientsTable';

export default {
      name: 'RecipeComponent',
      components: {
            Loader,
            RecipeIngredientsTable,
            NeedLogin
      },
      data() {
            return {
                  largeimage: {
                        src: "",
                        index: 0,
                        visible: false
                  },
            };
      },
      computed: {
            currentUser() {
                  return this.$store.state.auth.user;
            },
            hasrecipe() {
                  var rec = this.recipe;
                  if (rec)
                        return true;
                  return false;
            },
            recipe() {
                  var rec = this.$store.getters["recipe/recipe"];
                  if (rec)
                        return rec;
                  return false;
            },
            isFavorite() {
                  return this.$store.getters["recipe/recipefavorite"];
            },
            images() {
                  var images = this.$store.getters["recipe/recipeimages"];
                  if (images)
                        return images;
                  return [];
            },
            loading() {
                  return this.$store.getters["recipe/recipeloading"];
            },
            sums() {
                  return this.$recipe.sums(this.recipe, false).sums;
            }
      },
      mounted() {
            this.$store.commit("recipe/setRecipe", false);
            if (this.currentUser) {
                  this.$store.dispatch("recipe/getRecipe", this.$route.params.id);
            }
      },
      methods: {
            getIngredeientName(item) {
                  var name = this.recipe.ingredientsdata[item.ingredient].name;
                  return name;
            },
            getAmountText(item) {
                  var text = "";
                  if (item.amount <= 0)
                        text = "<span class='font-italic text-secondary'>Efter behag</span>";
                  else {
                        var unit = item.unit;
                        if (item.unit == "g")
                              unit = "gram";
                        text = this.rounding(item.amount) + " " + unit;
                  }
                  return text;
            },
            getGramText(item) {
                  var text = "";
                  if (item.amount > 0) {
                        text = this.rounding(item.gram) + " g";
                  }
                  return text;
            },
            getNutrition(key) {
                  if (this.sums[key])
                        return this.$formatter.round(this.sums[key], 0);
                  return 0;
            },
            rounding(val) {
                  val = parseFloat(val);
                  return Math.round((val + Number.EPSILON) * 100) / 100;
            },
            hasimages() {
                  if (this.images && this.images.length > 0)
                        return true;
                  return false;
            },
            time(time) {
                  return this.$time.FormatTime(time);
            },
            date(date) {
                  return this.$date.stringToReadableFormat(date);
            },
            svgimage(path) {
                  return this.$images.getSvgUrl(path);
            },
            recipeimage(name) {
                  return "/images/recipes/" + this.recipe.id + "/" + name;
            },
            showImage(name, index) {
                  var url = this.recipeimage(name);
                  this.largeimage.visible = true;
                  this.largeimage.index = index;
                  this.largeimage.src = url;
            },
            hideLargeImage() {
                  this.largeimage.visible = false;
            },
            url() {
                  return this.$route.path;
            },
            text() {
                  return "Du måste vara inloggad för att visa detta receptet!";
            },
            EditFavorite() {
                  var post = {
                        type: 1,
                        favid: this.recipe.id
                  };
                  if (this.isFavorite) {
                        // ta bort                            
                        this.$store.dispatch("favoritespost/deleteFavorite", post).then(() => {
                              this.$store.dispatch("recipe/setRecipeFavorite", false);
                        });
                  } else {
                        // lägg till                        
                        this.$store.dispatch("favoritespost/addFavorite", post).then(() => {
                              this.$store.dispatch("recipe/setRecipeFavorite", true);
                        });
                  }
            },
            goToIngredient(item) {
                  var name = this.getIngredeientName(item);
                  var id = item.ingredient;
                  this.$router.push({
                        name: 'ingrediens',
                        params: {
                              id: id,
                              name: this.$formatter.url(name)
                        }
                  });
            }
      },
};
</script>

<template>
<div>
      <!-- <Packages :packages="packages"></Packages> -->
      <!-- <div v-if="packages">
            <Packages :packages="packages"></Packages>
      </div>
      <div v-else-if="loading">
            <Loader loadingtext="Läser paket..."></Loader>
      </div> -->
</div>
</template>

<script>
// const Loader = () => import('@/components/common/Loader');
// const Packages = () => import('@/components/packages/Packages');

export default {
      name: 'PackagesComponent',
      components: {
            // Loader,
            // Packages
      },
      computed: {
            // hasPackages() {
            //       return this.packages;
            // },
            packages() {
                  var packages = this.$store.getters["packages/packages"];
                  if (packages)
                        return packages;
                  return false;
            },
            loading() {
                  return this.$store.getters["packages/packagesloading"];
            }
      },
      mounted() {
            this.$store.dispatch("packages/getPackages");
      },
      methods: {}
};
</script>

<template>
<div class="product">
<ProductInfo v-if="complete" :showbuy="false" :aproduct="currentProduct"></ProductInfo>
</div>
</template>

<script>
import ProductsService from '@/services/products.service';
//http://productinfo.herbalife.com/productlabels/labels-sv_SE.html
const ProductInfo = () => import('@/components/shop/Product');

export default {
    name: 'ProductComponent',
    components: {
        ProductInfo
    },
    data() {
        return {
            complete: false,
            currentProduct: {}
        };
    },
    mounted() {
    ProductsService.GetProduct(this.$route.params.id).then(
      response => {
        this.currentProduct = response.data.data;
        this.complete = true;
      },
      error => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
    methods: {
        getProductImage(id) {
            try {
                let url = "/images/products/" + id + ".jpg";
                return url;
            } catch (e) {
                //console.error('SVG Image [' + path + '] was called but not found');
            }
        }        
    }
};
</script>

<template>
<div class="checkout">
    <div v-if="!loading">
        <router-link to="/produkter" class="btn btn-primary ml-2">Fortsätt handla</router-link>
        <div v-if="showcheckout">
            <CheckoutInfo v-on:showconfirm="showConfirm()"></CheckoutInfo>
        </div>
        <div v-if="showconfirm">
            <div>
                <h1 class="text-center mb-4 text-main">
                    Betalning
                </h1>
                <b-container>
                    <b-row>
                        <b-col cols="12" md="8" lg="8">
                            <div class="text-center h3 mb-4">
                                Dina uppgifter
                            </div>
                            <div v-if="currentUser.id > 0 || (lookupcomplete && !lookuperror)">
                                <div class="row">
                                    <div class="col-md-6 mb-1">
                                        <label>Förnamn</label>
                                        <div class="checkoutdetail">{{currentUser.firstname}}</div>
                                    </div>
                                    <div class="col-md-6 mb-1">
                                        <label>Efternamn</label>
                                        <div class="checkoutdetail">{{currentUser.lastname}}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mb-1">
                                        <label>Epost</label>
                                        <div v-if="loggedin" class="checkoutdetail">{{currentUser.account.email}}</div>
                                        <div v-else>
                                            <b-form-input v-model="guest.email" size="md" placeholder="Ange epost..."></b-form-input>
                                            <b-alert v-if="emailerror" show variant="danger">
                                                {{emailerror}}
                                            </b-alert>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-1">
                                        <label>Mobil</label>
                                        <div v-if="loggedin" class="checkoutdetail">{{currentUser.account.phone}}</div>
                                        <div v-else>
                                            <b-form-input v-model="guest.phone" size="md" placeholder="Ange telefon..."></b-form-input>
                                            <b-alert v-if="phoneerror" show variant="danger">
                                                {{phoneerror}}
                                            </b-alert>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 mb-3">
                                        <label>Leveransadress</label>
                                        <div class="checkoutdetail">{{lookup.street}}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 mb-3">
                                        <label>Postnummer</label>
                                        <div class="checkoutdetail">{{lookup.postalNumber}}</div>
                                    </div>
                                    <div class="col-md-8 mb-3">
                                        <label>Postort</label>
                                        <div class="checkoutdetail">{{lookup.city}}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <b-row>
                                    <b-col lg="12">
                                        <div class="mt-4 text-center">
                                            <b-alert variant="danger" show>Du måste vara inloggad för att slutföra beställningen.</b-alert>
                                        </div>
                                        <div class="mt-4 text-center">
                                            <router-link class="btn btn-primary" v-bind:to="{ path: '/loggain', query:{ redirect: '/betalning'}}">Logga in</router-link>
                                            <label class="font-italic mx-3">eller</label>
                                            <router-link class="btn btn-secondary" v-bind:to="{ path: '/registrera', query:{ redirect: '/betalning'}}">Registrera</router-link>
                                        </div>
                                        <div class="mt-4 text-center">
                                            <b-alert variant="success" show>Som registrerad användare får du direkt 10% rabatt.</b-alert>
                                        </div>
                                    </b-col>
                                    <b-col class="mt-3" lg="12">
                                        <div class="mt-4 text-center">
                                            <b-alert variant="info" show>Du kan även köpa via snabbutcheckning genom att ange ditt personnummer.</b-alert>
                                        </div>
                                        <div class="mt-4 text-center">
                                            <div v-if="validssnr && !lookuperror">
                                                <b-alert show variant="info">
                                                    Hämtar uppgifter...<b-spinner small variant="primary"></b-spinner>
                                                </b-alert>
                                            </div>
                                            <div v-else>
                                                <b-form-input v-model="guest.ssnr" class="text-center" size="lg" placeholder="Ange personnummer..."></b-form-input>
                                                <b-alert variant="danger" show v-if="lookuperror">{{lookuperror}}</b-alert>
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <b-col cols="12" md="4" lg="4">
                            <div class="text-center h3 mb-4">
                                Sammanfattning
                            </div>
                            <div>
                                <table class="w-100">
                                    <tr>
                                        <td class="font-weight-bold">Antal varor</td>
                                        <td class="text-right">{{nrofitems}} st</td>
                                    </tr>
                                    <tr v-bind:class="{ 'text-gray pricecrossed': hasDiscount }">
                                        <td class="font-weight-bold">Summa</td>
                                        <td class="text-right">{{formatPrice(sums.net)}}</td>
                                    </tr>
                                    <tr v-bind:class="{ 'text-gray pricecrossed': hasDiscount }">
                                        <td class="font-weight-bold">Moms</td>
                                        <td class="text-right">{{formatPrice(sums.vat)}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">&nbsp;</td>
                                    </tr>
                                    <tr v-if="hasDiscount" class="text-gray pricecrossed">
                                        <td class="font-weight-bold pricecrossed">Att betala</td>
                                        <td class="text-right pricecrossed">{{formatPrice(total)}}</td>
                                    </tr>
                                    <tr v-if="hasDiscount" class="h4 text-success font-weight-bold">
                                        <td class="">Rabatt ({{currentUser.discount}}%)</td>
                                        <td class="text-right">-{{formatPrice(total-totald)}}</td>
                                    </tr>
                                    <tr v-if="hasDiscount" class="">
                                        <td class="font-weight-bold">Summa</td>
                                        <td class="text-right">{{formatPrice(sums.netd)}}</td>
                                    </tr>
                                    <tr v-if="hasDiscount" class="">
                                        <td class="font-weight-bold">Moms</td>
                                        <td class="text-right">{{formatPrice(sums.vatd)}}</td>
                                    </tr>
                                    <tr class="h3 border-top">
                                        <td class="font-weight-bold">Att betala</td>
                                        <td class="text-right font-weight-bold">{{formatPrice(totald)}}</td>
                                    </tr>
                                </table>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="mt-5">
                                <div class="text-center h2 mb-4">
                                    Betalningsalternativ
                                </div>
                                <div>
                                    <b-list-group>
                                          <b-list-group-item v-if="paymentsenabled[2]" style="height:90px">
                                            <b-row class="align-items-center" style="height:100%">
                                                <b-col cols="1">
                                                    <b-form-radio style="top:-10px" v-on:change="ChangePaymentType('2')" value="2" v-model="selectedpayment" name="CurrentPaymentMethod" size="lg"></b-form-radio>
                                                </b-col>
                                                <b-col cols="11">                                                    
                                                      <inline-svg style="width:150px; height:auto;" src="images/svg/capitum-logga.svg"></inline-svg>                                                                                                            
                                                      <small class="ml-2 font-italic">- Betala med Capitum efter att du fått din leverans.</small>                                                    
                                                </b-col>                                                
                                            </b-row>
                                        </b-list-group-item>
                                        <b-list-group-item v-if="paymentsenabled[1]" style="height:90px">
                                            <b-row class="align-items-center" style="height:100%">
                                                <b-col cols="1">
                                                    <b-form-radio style="top:-10px"  v-on:change="ChangePaymentType('1')" value="1" v-model="selectedpayment" name="CurrentPaymentMethod" size="lg"></b-form-radio>
                                                </b-col>
                                                <b-col>                                                    
                                                <inline-svg style="width:150px; height:auto;"  src="images/svg/swish_h.svg"></inline-svg>                                                                                                            
                                                    <small class="ml-2 font-italic">Betala med Swish innan varorna skickas.</small>
                                                </b-col>
                                            </b-row>

                                        </b-list-group-item>                                        
                                    </b-list-group>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
                <b-container class="mt-4">
                    <div class="mb-4" v-if="!create.success && create.errors.length > 0">
                        <b-alert show variant="danger">
                            <ul>
                                <li v-for="(error, index) in create.errors" :key="index">{{error}}</li>
                            </ul>
                        </b-alert>
                    </div>
                    <div class="text-center">
                        <b-button class="m-1" @click="ViewCart()" variant="primary">Visa varukorg</b-button>
                        <b-button class="m-1" @click="CreateOrder()" variant="success">Slutför beställning</b-button>
                    </div>
                </b-container>
            </div>
        </div>
    </div>
    <div v-else>
        <Loader :loadingtext="loadingtext"></Loader>
    </div>
</div>
</template>

<script>
/* eslint-disable */
//import ProductsService from '@/services/products.service';
import personnummer from "personnummer";

const CheckoutInfo = () => import('@/components/shop/CheckoutInfo');
const Loader = () => import('@/components/common/Loader');

const defaultuser = {
    id: 0,
    firstname: "",
    lastname: "",
    email: "",
    ssnr: "",
    phone: "",
    lookup: "{}"
}

export default {
    name: 'Products',
    components: {
        CheckoutInfo,
        Loader
    },
    data() {
        return {
            showcheckout: false,
            showconfirm: false,
            loading: false,
            create: {
                success: false,
                errors: []
            },
            guest: defaultuser,
            lookupdata: {},
            loadingssnr: false,
            lookupcomplete: false,
            lookuperror: false,
            emailerror: false,
            phoneerror: false,
            selectedpayment: "2",
            paymentsenabled: {
                1: true,
                2: true
            },
            sums: {
                net: 0,
                netd: 0,
                vat: 0,
                vatd: 0
            }

        };
    },
    mounted() {
        this.calculateValues();
        if (this.nrofitems <= 0)
            this.$router.push("/produkter")
        this.showconfirm = false;
        this.showcheckout = true;
        if (this.$route.path == "/betalning") {
            this.showconfirm = true;
            this.showcheckout = false;
        }
        if (this.loggedin)
            this.selectedpayment = 1;
    },
    computed: {
        loggedin() {
            return this.currentUser.id > 0;
        },
        currentUser() {
            var loggeduser = this.$store.state.auth.user;
            if (loggeduser)
                return loggeduser;
            return this.guest;
        },
        validssnr() {
            var valid = personnummer.validate(this.guest.ssnr);
            if (valid) {
                if (!this.lookupcomplete)
                    this.lookupssnr();
            }
            return valid;
        },
        lookup() {
            if (this.loggedin)
                return JSON.parse(this.currentUser.lookup);
            else return this.lookupdata;
        },
        loadingtext() {
            return "Vänligen vänta...";
        },
        nrofitems() {
            return this.$store.getters["cart/nrofitems"];
        },
        cartitems() {
            return this.$store.getters["cart/items"];
        },
        total() {
            return this.round(this.sums.net + this.sums.vat, 0);
        },
        totald() {
            return this.round(this.sums.netd + this.sums.vatd, 0);
        },
        hasDiscount() {
            if (this.sums.net != this.sums.netd)
                return true;
            return false;
        }
    },
    methods: {
        ViewCart() {
            this.showconfirm = false;
            this.showcheckout = true;
            this.$router.push("/kassa");
        },
        lookupssnr() {
            this.loadingssnr = true;
            this.guest.ssnr = personnummer.normalise(this.guest.ssnr);
            this.$store.dispatch('auth/lookup', this.guest.ssnr).then(
                data => {
                    this.lookupdata = JSON.parse(data.data.response);
                    this.guest.firstname = this.getFirstName(this.lookupdata.name);
                    this.guest.lastname = this.getLastName(this.lookupdata.name);
                    this.lookupcomplete = true;
                    this.loadingssnr = false;
                    this.lookuperror = false;
                },
                error => {
                    var message =
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString();
                    this.lookuperror = message;
                    this.lookupcomplete = true;
                    this.loadingssnr = false;
                }
            );
        },
        calculateValues() {
            var total = 0;
            var totaldiscount = 0;
            var vat = 0;
            var vatdiscount = 0;
            var items = this.cartitems;

            for (var id in items) {
                var item = items[id];
                var t = this.$cart.getProductPrice(item, false, true);
                var td = this.$cart.getProductPrice(item, true, true);
                total += t;
                totaldiscount += td;
                var v = this.$cart.getVatPrice(t, item.vat);
                var vd = this.$cart.getVatPrice(td, item.vat);
                vat += v;
                vatdiscount += vd;
            }
            // if (this.$store.getters["cart/priceincvat"]) {
            //       total -= vat;
            //       totaldiscount -= vatdiscount;
            // }
            this.sums.net = this.round(total, 2);
            this.sums.netd = this.round(totaldiscount, 2);
            this.sums.vat = this.round(vat, 2);
            this.sums.vatd = this.round(vatdiscount, 2);
        },
        formatPrice(price) {
            return this.$formatter.formatCurrency(price);
        },
        round(number, decimals) {
            return this.$formatter.round(number, decimals);
        },
        showConfirm() {
            this.showcheckout = false;
            this.showconfirm = true;
            this.calculateValues();
            this.$router.push("/betalning");
        },
        ValidateForm() {
            // validera värden och visa felmeddelanden
            // eg bara om man ej är inloggad som vi ska validera en epost och ett telefonnummer
            if (!this.loggedin) {
                var emailreg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
                var phonereg = /^([+]46)\s*(7[0236])\s*(\d{4})\s*(\d{3})$/;
                if (this.guest.email == null || this.guest.email == '') {
                    this.emailerror = "Ange en epostadress";
                } else if (!emailreg.test(this.guest.email)) {
                    this.emailerror = "Ange en korrekt epostadress";
                } else this.emailerror = false;

                if (this.guest.phone == null || this.guest.phone == '') {
                    this.phoneerror = "Ange ett mobilnummer";
                } else if (!phonereg.test(this.guest.phone)) {
                    this.phoneerror = "Ange ett korrekt mobilnummer";
                } else this.phoneerror = false;

                return (!this.emailerror && !this.phoneerror);
            }
            return true;
        },
        BuildOrderRows() {
            var orderrows = [];
            for (var id in this.cartitems) {
                var item = this.cartitems[id];
                var price = this.$cart.getProductPrice(item, true, false);
                var row = {
                    productid: item.id,
                    quantity: item.quantity,
                    price: price,
                    orgprice: item.price,
                    vat: item.vat
                };
                orderrows.push(row);
            }
            return orderrows;
        },
        CreateOrder() {
            if (this.ValidateForm()) {
                var rows = this.BuildOrderRows();
                var draftid = this.$store.getters["cart/draftid"];
                var order = {
                    paymenttype: this.selectedpayment,
                    orderrows: rows,
                    userdata: JSON.stringify(this.lookup),
                    draftid: draftid,
                    name: this.currentUser.firstname + " " + this.currentUser.lastname
                };

                // lägg till user för leveransadress osv
                if (this.loggedin) {
                    // om inloggad lägg vem man är
                    order.token = this.currentUser.accessToken;
                    order.email = this.currentUser.account.email;
                    order.phone = this.currentUser.account.phone;
                } else {
                    order.user = this.guest;
                    order.user.lookup = JSON.stringify(this.lookupdata);
                    order.email = this.guest.email;
                    order.phone = this.guest.phone;
                }

                //console.log(order);
                this.loading = true;
                this.$store.dispatch('shop/createOrder', order).then(
                    (response) => {
                        // order complete?                                    
                        //console.log(response);
                        this.loading = false;
                        if (response.status == "ok") {
                            // ok                                                        
                            // töm basket
                            this.$store.dispatch("cart/ClearCart");
                            // visa order klar med betalningsinformation
                            var orderurl = "/slutfor/" + response.order.id + "/";
                            this.$router.push(orderurl);
                        }
                        else {
                            // error
                            this.create.success = false;
                            this.create.errors = response.errors;
                        }
                    },
                    error => {
                        this.loading = false;
                        this.message =
                            (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                    }
                );
            }
        },
        ChangePaymentType(ptype) {
            this.selectedpayment = ptype;
        },
        showInfo(item) {
            this.setGroupItems(item);
            this.currentProduct = item;
            this.$refs.infomodal.show();
        },
        getProductImage(id) {
            return this.$images.getProductImage(id);
        },
        setFailedImageSrc(e) {
            e.target.src = this.$images.getFailedProductImage();
        },
        setGroupItems(product) {
            if (!product.groupnr)
                this.currentGroupItems = [];
            else {
                this.currentGroupItems = this.products.filter(item => {
                    return item.groupnr == product.groupnr
                });
            }
        },
        getFirstName(fullname) {
            var fi = fullname.indexOf("'");
            var li = fullname.lastIndexOf("'");
            if (fi >= 0 && li > 0)
                return fullname.substring(fi + 1, li);
            // fallback, first name possible
            var names = fullname.split(" ");
            return names[0];
        },
        getLastName(fullname) {
            var names = fullname.split(" ");
            return names[names.length - 1];
        }
    }
};
</script>

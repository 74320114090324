import axios from 'axios';
import store from "@/store/";

const API_URL_POST = process.env.VUE_APP_API_URL_POST;


class AuthService {
  login(user) {
    var params = new URLSearchParams();
    params.append('email', user.email);
    params.append('password', user.password);
    return axios
      .post(API_URL_POST + 'login', params)
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));          
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
    store.dispatch("cart/ClearCart", true);
    localStorage.removeItem('checkout');    
  }
  
  validateRegistrationCode(email, code)
  {
    var params = new URLSearchParams();
    params.append('code', code);
    params.append('email', email);
    return axios.post(API_URL_POST + 'validateregistrationcode', params);
  }

  activate(emailhash) {
    var params = new URLSearchParams();
    params.append('email', emailhash);    
    return axios.post(API_URL_POST + 'activate', params);
  }

  lookup(ssnr) {    
    return axios.post(API_URL_POST + 'lookup', { ssnr: ssnr});
  }
  completeregistration(user) { 
    return axios.post(API_URL_POST + 'completeregistration', user,
    { 
      headers:
      {
        "content-type": "application/x-www-form-urlencoded"
      }}).then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }
}

export default new AuthService();

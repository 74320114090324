<template>
<div class="ingredient">
      <div class="mt-0 mb-5 text-center">
            <h2>Näringsvärden för {{ingredient.name}} per 100 gram</h2>
      </div>
      <b-container>
            <b-row class="text-center">
                  <b-col xs="12" sm="6" lg="3" class="mb-4">
                        <h3>
                              <inline-svg :src="svgimage('recipe/calories.svg')" class="ingredient-icon" /> Kalorier</h3>
                        <div class="info-box">
                              {{ingredient.calories}}
                        </div>
                  </b-col>
                  <b-col xs="12" sm="6" lg="3" class="mb-4">
                        <h3>
                              <inline-svg :src="svgimage('recipe/fat.svg')" class="ingredient-icon" /> Fett</h3>
                        <div class="info-box">
                              {{ingredient.fat}}
                        </div>
                  </b-col>
                  <b-col xs="12" sm="6" lg="3" class="mb-4">
                        <h3>
                              <inline-svg :src="svgimage('recipe/proteins.svg')" class="ingredient-icon" /> Protein</h3>
                        <div class="info-box">
                              {{ingredient.protein}}
                        </div>
                  </b-col>
                  <b-col xs="12" sm="6" lg="3" class="mb-4">
                        <h3>
                              <inline-svg :src="svgimage('recipe/carbs.svg')" class="ingredient-icon" /> Kolhydrater</h3>
                        <div class="info-box">
                              {{ingredient.carbs}}
                        </div>
                  </b-col>
            </b-row>
            <b-row class="mt-3 mb-3" v-if="brand">
                  <b-col>
                        <b-alert variant="info" class="text-center font-large" show>
                              OBS! Näringsvärden för {{ingredient.name}} är beräknade från tillverkaren <span class="font-weight-bold">{{brand}}</span>.
                        </b-alert>
                  </b-col>
            </b-row>
            <hr class="mt-5 mb-5" />
            <div class="mt-0 mb-5 text-center">
                  <h2>{{ingredient.name}} används i följande recept</h2>
            </div>
            <div>
                  <b-list-group>
                        <b-list-group-item button v-for="r in ingredient.recipes" :key="r.id" @click="GoTo(r)">
                              {{r.title}}
                        </b-list-group-item>
                  </b-list-group>
            </div>
      </b-container>
</div>
</template>

<script>
export default {
      name: 'IngredientComponent',
      components: {},
      data() {
            return {
                  ready: false,
            };
      },
      computed: {
            ingredient() {
                  return this.$store.getters["ingredients/dataI"];
            },
            brand() {
                  var i = this.ingredient;
                  if (i && i.brand)
                        return i.brand;
                  return false;
            },
            metadescription() {
                  var text = "Näringsvärdet för " + this.ingredient.name + " per 100 gram är: Kalorier = " + this.ingredient.calories + ", Fett = " +
                        this.ingredient.fat + ", Protein = " + this.ingredient.protein + ", Kolhydrater = " + this.ingredient.carbs;

                  return text;
            }
      },
      metaInfo() {
            return {
                  title: this.ingredient.name,
                  meta:[{name: 'description', content: this.metadescription}]
            }
      },
      methods: {
            GoTo(item) {
                  this.$router.push({
                        name: 'recept',
                        params: {
                              id: item.id,
                              name: this.$formatter.url(item.title)
                        }
                  });
            },
            date(date) {
                  return this.$date.stringToIsoDateOnly(date);
            },
            svgimage(path) {
                  return this.$images.getSvgUrl(path);
            }
      },
      mounted() {},
};
</script>

<template>
<div class="col-md-12">

      <div v-if="!validssnr">
            <b-form-input v-model="ssnr" ref="ssnrbox" class="text-center" size="lg" placeholder="Ange personnummer..."></b-form-input>
            <b-alert variant="danger" show>Ange ett giltigt personnummer</b-alert>
            <b-alert variant="danger" show v-if="message">{{message}}</b-alert>
      </div>
      <div v-else>
            <div v-if="loading">
                  <b-alert show variant="info">
                        Hämtar uppgifter...<b-spinner small variant="primary"></b-spinner>
                  </b-alert>
            </div>
            <div v-else>
                  <div v-if="confirm_loading">
                        <b-alert show variant="info">
                              Slutför registrering...<b-spinner small variant="primary"></b-spinner>
                        </b-alert>
                  </div>
                  <div v-else>
                        <div class="text-center">
                              <h3>Bekräfta dina uppgifter</h3>
                        </div>
                        <div>{{getFirstName(lookupdata.name)}} {{getLastName(lookupdata.name)}}</div>
                        <div>{{lookupdata.personalNumber}}</div>
                        <div>{{lookupdata.street}}</div>
                        <div>{{lookupdata.postalNumber}} {{lookupdata.city}}</div>
                        <div class="mt-4 text-center">
                              <b-button @click="ssnr=''" variant="secondary" class="m-1">Bakåt</b-button>
                              <b-button variant="primary" v-on:click="createAccount()" class="m-1">Slutför</b-button>
                        </div>
                  </div>
            </div>
      </div>
</div>
</template>

<script>
import personnummer from "personnummer";

export default {
      name: 'CompleteRegister',
      props: ["user"],
      data() {
            return {
                  ssnr: "",
                  submitted: false,
                  successful: false,
                  loading: false,
                  lookupcomplete: false,
                  confirm_loading: false,
                  message: ''
            };
      },
      computed: {
            loggedIn() {
                  return this.$store.state.auth.status.loggedIn;
            },
            validssnr() {
                  var valid = personnummer.validate(this.ssnr);
                  if (valid)
                        this.lookupssnr();
                  return valid;
            },
            lookupdata() {
                  return this.$store.getters["register/lookup"];
            }

      },
      mounted() {
            if (this.loggedIn) {
                  this.$router.push('/minsida');
            } else {
                  this.$refs.ssnrbox.focus();
                  this.handleActivate();
            }
      },
      methods: {
            handleActivate() {
                  this.message = '';
                  this.loading = true;
                  this.submitted = true;
            },
            lookupssnr() {
                  this.loading = true;
                  this.lookupcomplete = false;
                  this.$store.dispatch('register/lookup', {
                        ssnr: this.ssnr,
                        isvalidate: true
                  }).then(() => {
                        this.successful = true;
                        this.loading = false;
                        this.lookupcomplete = true;
                  }).catch(error => {
                        this.ssnr = "";
                        this.message = error;
                        this.successful = false;
                        this.loading = false;
                  });
            },
            createAccount() {
                  var user = {
                        email: this.user.email,
                        firstname: this.getFirstName(this.lookupdata.name),
                        lastname: this.getLastName(this.lookupdata.name),
                        ssnr: this.lookupdata.personalNumber,
                        lookup: JSON.stringify(this.lookupdata)
                  };

                  this.confirm_loading = true;
                  this.$store.dispatch('register/completeregistration', user).then(
                        () => {
                              this.$router.push('/minsida');
                        },
                        error => {
                              this.ssnr = "";
                              this.validate.code = "";
                              this.message =
                                    (error.response && error.response.data) ||
                                    error.message ||
                                    error.toString();
                              this.confirm_loading = false;
                        }
                  );
            },
            getFirstName(fullname) {
                  var fi = fullname.indexOf("'");
                  var li = fullname.lastIndexOf("'");
                  if (fi >= 0 && li > 0)
                        return fullname.substring(fi + 1, li);
                  // fallback, first name possible
                  var names = fullname.split(" ");
                  return names[0];
            },
            getLastName(fullname) {
                  var names = fullname.split(" ");
                  return names[names.length - 1];
            }
      }
};
</script>

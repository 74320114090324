import AuthService from './../../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState, 
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          // store user 
          var rememberuser = {
            image: user.image,
            name: user.firstname + " " + user.lastname,
            email: user.account.email 
          };
          var users = JSON.parse(localStorage.getItem("savedusers"));
          if(!users)
              users = { latest: null, all:{}};
          users.latest = rememberuser;
          users.all[rememberuser.email] = rememberuser;
          localStorage.setItem("savedusers", JSON.stringify(users));
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();      
      commit('logout');
    },
    lookup({commit},ssnr) {
      commit('lookupstart');
      return AuthService.lookup(ssnr);
    }
  },  
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    lookupstart(state){
      state.lookup = true;
    }
  }
};

import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_GET = process.env.VUE_APP_API_URL_GET;

export default {
      state: {
            error: false,
            errorMessage: "",
            loading: false,
            data: null
      },

      getters: {
            favorites: state =>
            {
                  return state.data;
            },
            loading: state =>
            {
                  return state.loading;
            },
            error: state =>
            {
                  return state.error;
            },
            errormessage: state =>
            {
                  return state.errorMessage;
            }
      },

      mutations: {
            setData(state, data)
            {
                  state.data = data;
            },
            setLoading(state, status)
            {
                  state.loading = status;
            },
            setError(state, status)
            {
                  state.error = status;
            },
            setErrorMessage(state, message)
            {
                  state.errorMessage = message;
            }
      },

      actions: {
            async getFavorites({ commit }, type)
            {
                  commit("setLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'favorites?type=' + type;
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setLoading", false);
                        commit("setError", true);
                        commit("setErrorMessage", error.message);
                  }
                  if (response)
                  {
                        commit("setData", response.data.data);
                        commit("setLoading", false);
                        commit("setError", false);
                        commit("setErrorMessage", "");
                  }
            }
      },

      namespaced: true
};

<template>
<div>
      <h1>Test</h1>
      <b-card>
            <h3>Instagram</h3>
            <div v-for="item in instagramitems" :key="item.node.id">
                  <pre>{{item}}</pre>
            </div>
      </b-card>
      <hr/>
      <b-card>
      <h3>Swish</h3>
      <img :src="image" />
      </b-card>
</div>
</template>

<script>

export default {
      name: 'TestView',
       data() {
            return {
                 image: "",
            };
      },
      mounted() {
            //this.SaveInsta();
      },
      computed:
      {
            instagramitems()
            {
                  return this.$store.getters["instagram/data"].data.user.edge_owner_to_timeline_media.edges;
            }
      },
      methods: {
            SaveInsta()
            {
                  var items = [];
                  for(var i = 0; i < this.instagramitems.length; i++)
                  {
                        var item = this.instagramitems[i].node;
                        var additem = 
                        {
                              id: item.id,
                              json: JSON.stringify(item)
                        };
                        items.push(additem);
                  }
                  this.$store.dispatch("instagram/addInstagram", items);
            },
            TestSwish() {
                 
            
            }
      }
};
</script>

<template>
  <div id="app" class="d-flex align-items-stretch align-content-stretch flex-column flex-lg-row">
        <Menu></Menu>
        <div id="content">
              <div class="page pt-1 pt-lg-3 pl-1 pr-1 pl-lg-3 pr-lg-3">
                    <router-view />
              </div>
              <div id="bottom" class="d-none d-lg-block">
                    <Footer></Footer>
              </div>
        </div>
  </div>
  </template>
  
  <script>
  import Footer from '@/components/common/Footer';
  import Menu from "@/components/common/Menu.vue";
  
  export default {
        name: 'App',
        components: {
              Menu,
              Footer
        },
        metaInfo: {
              // if no subcomponents specify a metaInfo.title, this title will be used
              title: 'Få kontroll över din kropp!',
              meta:[{name: 'description', content: "Kroppskollen hjälper dig att ta kontroll över din kropp och din hälsa!"}],
              // all titles will be injected into this template
              titleTemplate: '%s | Kroppskollen.com'
        },
        data() {
              return {
                    visible: true
              }
        },
        computed: {
              currentUser() {
                    return this.$store.state.auth.user;
              }
        },
        methods: {
              CheckLogin() {
                    if (this.currentUser) {
                          this.$store.dispatch("user/checkUserLogin").then((response) => {
                                if (response) {
                                      if (response.data.status == "update") {
                                            localStorage.setItem('user', JSON.stringify(response.data.user));
                                      }
                                      var self = this;
                                      setTimeout(self.CheckLogin, 300 * 1000); // 300 = 5minuter
                                }
                          }).catch(() => {
                               this.$store.dispatch("auth/logout");
                               this.$router.push("loggain");
                          });
                    }
              }
        },
        watch: {
              currentUser() {
                    this.CheckLogin();
              }
        },
        mounted() {
              this.CheckLogin();
        }
  };
  </script>
  
  <style lang="scss">
  @import 'styles/main.scss';
  </style>
  
import axios from "axios";
import authHeader from './../services/auth-header';

const API_URL_GET = process.env.VUE_APP_API_URL_GET;

export default {
      state: {
            measurements:
            {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null,
            },
            goals:
            {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null,
            }
      },

      getters: {
            measurementsloading: state =>
            {
                  return state.measurements.loading;
            },
            measurements: state =>
            {
                  return state.measurements.data;
            },
            goalsloading: state =>
            {
                  return state.goals.loading;
            },
            goals: state =>
            {
                  return state.goals.data;
            }         
      },

      mutations: {
            setMeasurements(state, data)
            {
                  state.measurements.data = data;
            },
            setMeasurementsLoading(state, status)
            {
                  state.measurements.loading = status;
            },
            setMeasurementsError(state, status)
            {
                  state.measurements.error = status;
            },
            setMeasurementsErrorMessage(state, message)
            {
                  state.measurements.errorMessage = message;
            },
            setGoals(state, data)
            {
                  state.goals.data = data;
            },
            setGoalsLoading(state, status)
            {
                  state.goals.loading = status;
            },
            setGoalsError(state, status)
            {
                  state.goals.error = status;
            },
            setGoalsErrorMessage(state, message)
            {
                  state.goals.errorMessage = message;
            },
            setRecipe(state, data)
            {
                  state.recipe.data = data;
            },
            setRecipeLoading(state, status)
            {
                  state.recipe.loading = status;
            },
            setRecipeError(state, status)
            {
                  state.recipe.error = status;
            },
            setRecipeErrorMessage(state, message)
            {
                  state.recipe.errorMessage = message;
            }
      },

      actions: {
            async checkUserLogin()
            {
                  let response;
                  try
                  {
                        let url = API_URL_GET + 'checklogin';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {                        
                        return Promise.reject(error);
                  }
                  if (response)
                  {             
                        return Promise.resolve(response);
                  }
            },

            async getUserMeasurments({ commit })
            {
                  commit("setMeasurementsLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'mymeasurements';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setMeasurementsLoading", false);
                        commit("setMeasurementsError", true);
                        commit("setMeasurementsErrorMessage", error.message);
                        
                  }
                  if (response)
                  {
                        commit("setMeasurements", response.data.data);
                        commit("setMeasurementsLoading", false);
                        commit("setMeasurementsError", false);
                        commit("setMeasurementsErrorMessage", "");                        
                  }
            },

            async getUserGoals({ commit })
            {
                  commit("setGoalsLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'mygoals';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setGoalsLoading", false);
                        commit("setGoalsError", true);
                        commit("setGoalsErrorMessage", error.message);
                  }
                  if (response)
                  {
                        commit("setGoals", response.data.data);
                        commit("setGoalsLoading", false);
                        commit("setGoalsError", false);
                        commit("setGoalsErrorMessage", "");
                  }
            },

      },

      namespaced: true
};

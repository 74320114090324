<template>
<div class="checkout-complete">
      <div v-if="!loading">
            <Complete :order="order"></Complete>
      </div>      
      <Loader v-else loadingtext="Hämtar order..."></Loader>      
</div>
</template>

<script>
/* eslint-disable */
import UserService from '@/services/user.service';
const Loader = () => import('@/components/common/Loader');
const Complete = () => import('@/components/shop/CheckoutComplete');

export default {
      name: 'CheckoutComplete',
      components: {
            Complete,
            Loader
      },
      props: ["id"],
      data() {
            return {
                  loading: false,
                  order: {}
            };
      },
      computed: {

      },
      methods: {},
      mounted() {            
            this.loading = true;
            UserService.getUserOrder(this.id).then(
                  response => {
                        this.order = response.data.data;
                        this.loading = false;
                  }
            );
      }
};
</script>

<template>
<b-container class="finalize-order">
      <div v-if="!loading">
            <div v-if="isSwish">
                
            </div>
            <div v-else-if="isCapitum">
                <Capitum :capitumdata="orderpayment"></Capitum>
            </div>
      </div>      
      <Loader v-else loadingtext="Slutför order"></Loader>      

</b-container>
</template>

<script>
/* eslint-disable*/
import UserService from '@/services/user.service';
const Loader = () => import('@/components/common/Loader');
import Capitum from "@/components/shop/Capitum";
import Swish from "@/components/shop/Swish";

export default {
      name: 'FinalizeOrder',
      components: {
            Loader,
            Capitum,
            Swish
      },
      props: ["id"],
      data() {
            return {
                loading: true,
                order: {},
                orderpayment: {}
            };
      },
      computed: {
            isSwish(){
                  return this.order.paymenttype == 10;
            },
             isCapitum(){
                  return this.order.paymenttype == 30;
            }
      },
      methods: {
       
      },
      mounted(){
        
        this.loading = true;
         UserService.getUserOrder(this.id).then(
            response => {
                this.order = response.data.data;
                UserService.getUserOrderPayment(this.id).then(
                  response => {             
                        debugger;       
                        this.orderpayment = response.data.data;
                        this.loading = false;
                  }
            );
            });            
      }
}
</script>

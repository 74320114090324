<template>
<TapeMeasurement v-if="ready" :item="item"></TapeMeasurement>
</template>

<script>
import TapeMeasurement from '@/components/kroppskoll/items/tapemeasures/TapeMeasurement';

export default {
      name: 'WaistComponent',
      components: {
            TapeMeasurement
      },
      data() {
            return {
                  ready: false,
                  item: false,
                  key: "waist_cm",
                  title: "Midjemått"
            };
      },
      computed: {

      },
      methods: {
            init() {
                  var obj = this.$measurements.getDefaultObject();
                  this.item = obj;
                  this.setValues();
                  this.ready = true;
            },
            setValues() {
                  // hämta senaste BMI
                  var mitem = this.$measurements.getTapeMeasurement(this.key);
                  this.item.value = mitem.value;
                  this.item.title = this.title + ": " + mitem.value + " cm";
                  this.item.description = mitem.text;

                  if (mitem.value < mitem.prevalue) {
                        this.item.trend.type = 1;
                        this.item.trend.dir = -1;
                  } else if (mitem.value > mitem.prevalue) {
                        this.item.trend.type = -1;
                        this.item.trend.dir = 1;
                  }

                  this.item.trend.value = mitem.value - mitem.prevalue;

                  var allgoals = this.$measurements._getGoals(this.key);
                  var cgoal = 0;
                  var hasgoal = false;

                  // linje
                  var labels = [];
                  var data = [];
                  var goals = [];
                  var all = this.$measurements.values(this.key);
                  for (var i = 0; i < all.length; i++) {
                        labels.push(all[i].date);
                        data.push(all[i].value);
                        var goal = this.getGoal(all[i].date, allgoals);
                        if (goal) {
                              cgoal = goal;
                              hasgoal = true;
                        }

                        goals.push(cgoal);
                  }
                  this.item.line.data = {
                        labels: labels,
                        datasets: [{
                              label: this.title,
                              borderColor: "#7D2E68",
                              data: data,
                              fill: false
                        }]
                  };
                  if (hasgoal)
                        this.item.line.data.datasets.push({
                              label: "Ditt mål",
                              borderColor: "green",
                              data: goals,
                              borderDash: [30, 25],
                              spanGaps: true,
                              fill: false
                        });
            },
            getGoal(date, goals) {
                  for (var i = 0; i < goals.length; i++) {
                        if (goals[i].date == date)
                              return goals[i].value;
                  }
                  return false;
            },
            setGaugeWidth() {
                  var h = 400;
                  if (window.innerWidth < 500) {
                        h = 300;
                  }

                  this.item.gauge.options.chartWidth = h;
            }
      },
      mounted() {
            this.init();
      }
};
</script>

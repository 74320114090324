import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from './account/auth.module';
import register  from './account/register';
import forgotpassword  from './account/forgotpassword';
import admin from './admin';
import recipe from './get/recipe';
import ingredients from './get/ingredients';
import instagram from './get/instagram';
import packages from './packages';
import user from './user';
import favoritesget from './get/favorites';
import favoritespost from './post/favorites';
import cart from './shop/cart';
import shop from './shop/shop';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    register,
    forgotpassword,
    admin,
    ingredients,    
    recipe,
    packages,
    user,
    favoritesget,
    favoritespost,
    cart,
    shop,
    instagram
  }
});

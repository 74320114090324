<template>
 <LineChartGenerator :chart-options="chartOptions" :chart-data="chartData"  :height="height" />
</template>

<script>
/* eslint-disable */
import {
    Line as LineChartGenerator
} from 'vue-chartjs/legacy'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
)

export default {
    name: 'LineChart',
    components: {
        LineChartGenerator
    },
    props: {
        chartData: {
            type: Object,
            default: null
        },
        chartOptions: {
            type: Object,
            default: null
        },
        height: {
      type: Number,
      default: 400
    },
    }
}
</script>

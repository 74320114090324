<template>
<div>
      <div class="mt-0 mb-5 text-center">
            <h1 class="text-main">Recept</h1>
      </div>
      <div v-if="currentUser">
            <div v-if="ready">
                  <b-container fluid>
                        <div class="mt-4 mb-4">
                              <b-form-group>
                                    <b-input-group>
                                          <b-form-input v-model="filter" type="search" id="filterInput" class="form-control" placeholder="Sök recept..."></b-form-input>
                                          <b-input-group-append>
                                                <b-button :disabled="!filter" @click="filter = ''">Rensa</b-button>
                                          </b-input-group-append>
                                    </b-input-group>
                              </b-form-group>
                        </div>
                        <b-row class="mt-4 mb-4">
                              <b-col md="6">
                                    <b-form-group label="Filtrera på recept för olika tillfällen">
                                          <b-form-checkbox-group size="lg" v-model="selected_categories" :options="options" name="categories"></b-form-checkbox-group>
                                    </b-form-group>
                              </b-col>
                              <b-col md="6" class="text-md-right">
                                    <b-form-group label="Visa alla recept eller dina favoriter">
                                          <b-form-select v-model="viewfilter.view" :options="viewfilter.options"></b-form-select>
                                    </b-form-group>
                              </b-col>
                        </b-row>
                  </b-container>
                  <div class="mt-4">
                        <b-table sort-by="title" selected-variant="primary" responsive :filter="filter" striped hover :items="filteredrecipes" :fields="headerfields" selectable select-mode="single" @row-selected="rowSelected" :sort-compare="mySortCompare">
                              <template v-slot:cell(favorite)="data">
                                    <inline-svg class="favorite" v-on:click.stop.prevent="EditFavorite(data.item)" :src="svg('common/star.svg')" v-bind:class="{ filled: isRecipeFavorite(data.item) }"></inline-svg>
                              </template>
                              <template v-slot:cell(image)="data">
                                    <inline-svg v-if="HasImage(data.item)" :src="svg('common/image.svg')"></inline-svg>
                              </template>
                              <template v-slot:cell(category)="data">
                                    {{ getCategoryName(data.item) }}
                              </template>
                        </b-table>
                        <div v-if="viewfilter.view == 2 && filteredrecipes.length <= 0">
                              <b-alert class="text-center" variant="info" show>Du har inte lagt till några favoritrecept ännu!
                                    <b-button variant="primary" @click="viewfilter.view = 1">Visa alla</b-button>
                              </b-alert>
                        </div>
                  </div>
            </div>
            <Loader v-else></Loader>
      </div>
      <div v-else>
            <NeedLogin redirect="/recept" text="Här får du mängder med bra recept för både frukost, lunch, middag, mellanmål, desert och snacks!<br />För att ta del av receptbanken måste du logga in eller registrera dig!"></NeedLogin>
      </div>
</div>
</template>

<script>
import NeedLogin from '@/components/common/NeedLogin';
import Loader from '@/components/common/Loader';

export default {
      name: 'RecipesComponent',
      components: {
            NeedLogin,
            Loader,
      },
      data() {
            return {
                  ready: false,
                  filter: '',
                  selected_categories: [],
                  headerfields: [{
                              key: 'favorite',
                              label: 'Fav',
                              headerTitle: "Markera dina favoriter",
                              sortable: true,
                              class: 'text-center',
                        },
                        {
                              key: 'title',
                              headerTitle: "Namn på receptet",
                              label: 'Namn',
                              sortable: true,
                        },
                        {
                              key: 'image',
                              label: 'Bild?',
                              headerTitle: "Finns det bilder?",
                              sortable: true,
                              class: 'text-center d-none d-md-table-cell',
                        },
                        {
                              key: 'portions',
                              label: 'Portioner',
                              headerTitle: "Grundreceptet är för antal personer",
                              sortable: true,
                              class: 'text-right d-none d-md-table-cell',
                        },
                        {
                              key: 'time',
                              label: 'Tid',
                              sortable: true,
                              class: 'text-right d-none d-md-table-cell',
                              formatter: (value) => {
                                    return this.$time.FormatTime(value);
                              },
                        },
                        {
                              key: 'calories',
                              label: 'Kalorier/portion',
                              sortable: true,
                              class: 'text-right',
                              formatter: (value) => {
                                    return this.$measurements._numb(value, 0);
                              },
                        },
                        {
                              key: 'category',
                              label: 'Kategori',
                              sortable: true,
                              class: 'text-right d-none d-md-table-cell',
                        },
                  ],
                  selected_recipe: [],
                  viewfilter: {
                        view: 1, // 1 = all, 2 = favorites
                        options: [{
                                    value: 1,
                                    text: 'Alla recept',
                              },
                              {
                                    value: 2,
                                    text: 'Mina favoriter',
                              },
                        ],
                  },
            };
      },
      created() {
            this.ready = false;
            if (this.currentUser) {
                  this.$store.dispatch('recipe/getRecipes').then(() => {
                        this.$store.dispatch('recipe/getCategories').then(() => {
                              this.$store.dispatch('favoritesget/getFavorites', 1).then(() => {
                                    if (localStorage.userrecipeview)
                                          this.viewfilter.view = localStorage.userrecipeview;
                                    this.ready = true;
                              });
                        });
                  });
            } else this.ready = true;
      },
      methods: {
            rowSelected(items, event) {
                  var item = items[0];
                  this.$router.push({
                        name: 'recept',
                        params: {
                              id: item.id,
                              name: this.$formatter.url(item.title),
                        },
                  });
                  item = event;
            },
            EditFavorite(item) {
                  var isfavorite = this.isRecipeFavorite(item);
                  var post = {
                        type: 1,
                        favid: item.id,
                  };
                  if (isfavorite) {
                        // ta bort
                        this.$store.dispatch('favoritespost/deleteFavorite', post).then(() => {
                              this.$store.dispatch('favoritesget/getFavorites', 1);
                        });
                  } else {
                        // lägg till
                        this.$store.dispatch('favoritespost/addFavorite', post).then(() => {
                              this.$store.dispatch('favoritesget/getFavorites', 1);
                        });
                  }
            },
            checkItemCategory(item) {
                  if (!this.selected_categories || this.selected_categories.length <= 0) {
                        return true;
                  }
                  if (this.selected_categories.includes(item.category)) return true;
                  return false;
            },
            checkItemFavorite(item) {
                  if (this.viewfilter.view == 1)
                        // visa alla
                        return true;
                  return this.isRecipeFavorite(item); // annars om det är favorit
            },
            getCategoryName(item) {
                  for (var i = 0; i < this.options.length; i++) {
                        if (this.options[i].value == item.category) return this.options[i].text;
                  }
                  return item.category;
            },
            isRecipeFavorite(item) {
                  if (this.favorites && this.favorites[item.id]) {
                        return true;
                  }
                  return false;
            },
            svg(path) {
                  return this.$images.getSvgUrl(path);
            },
            HasImage(item) {
                  var rimages = this.recipesimages;
                  if (rimages && rimages[item.id]) return true;
                  return false;
            },
            mySortCompare(a, b, key) {
                  if (key === 'image') {
                        // Assuming the date field is a `Date` object, subtraction
                        // works on the date serial number (epoch value)
                        var ai = this.HasImage(a) ? 1 : 0;
                        var bi = this.HasImage(b) ? 1 : 0;
                        return ai - bi;
                  } else {
                        // Let b-table handle sorting other fields (other than `date` field)
                        return false;
                  }
            },
      },
      computed: {
            currentUser() {
                  return this.$store.state.auth.user;
            },
            recipes() {
                  var recipes = this.$store.getters['recipe/recipes'];
                  if (recipes) return recipes;
                  return [];
            },
            recipesimages() {
                  return this.$store.getters['recipe/hasimages'];
            },
            filteredrecipes() {
                  let filterd = this.recipes.filter((item) => {
                        return (
                              item.title.toLowerCase().indexOf(this.filter.toLowerCase()) > -1 &&
                              this.checkItemCategory(item) &&
                              this.checkItemFavorite(item)
                        );
                  });
                  // sort(filterd).by({
                  //       asc: this.currentSort,
                  //       comparer: new Intl.Collator(undefined, {
                  //             numeric: true,
                  //             sensitivity: 'base'
                  //       }).compare
                  // });
                  // if (this.currentSortReveresed)
                  //       filterd.reverse();
                  return filterd;
            },
            categories() {
                  return this.$store.getters['recipe/categories'];
            },
            options() {
                  var options = [];
                  if (this.categories && this.categories.length > 0) {
                        for (var i = 0; i < this.categories.length; i++) {
                              var item = this.categories[i];
                              options.push({
                                    text: item.name,
                                    value: item.id,
                              });
                        }
                  }
                  return options;
            },
            favorites() {
                  var favorites = this.$store.getters['favoritesget/favorites'];
                  if (favorites) return favorites;
                  return [];
            },
            userview() {
                  return this.viewfilter.view;
            }
      },
      watch: {
            userview(view) {
                  localStorage.userrecipeview = view;
            }
      }
};
</script>

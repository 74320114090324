import store from "./../store/";

export default {
      getProductPrice(product, with_discount, use_quantity){
            var price = parseFloat(product.price);
            // if(store.getters["cart/priceincvat"])
            // {
            //       price +=  (product.vat/ 100) * price;
            // }              
            if(use_quantity)
                  price = price * parseInt(product.quantity);
            return this.getPrice(price, with_discount);
      },
      getPrice(price, with_discount)
      {            
            price = parseFloat(price);
            if(isNaN(price))
                  price = 0;

            var loggeduser = store.state.auth.user;
            if (loggeduser && with_discount)
            {
                  var total = 1 - (loggeduser.discount / 100);
                  return price * total;
            }            
            return price;
      },
      getVatPrice(price, vat)
      {
            return price * (parseInt(vat) / 100);
      }
};

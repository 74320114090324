<template>
<div class="need-login">
      <b-container>
            <div class="alert alert-danger mt-1 text-center">Du måste vara inloggad för att se den efterfrågade sidan</div>
            <div class="mt-5 text-center">
                  <span v-html="text"></span>
            </div>
            <div class="mt-4 text-center">
                  <router-link class="btn btn-primary" v-bind:to="{ path: '/loggain', query:{ redirect: url()}}">Logga in</router-link>
                  <!-- <router-link class="btn btn-primary m-2" v-bind:to="{ path: '/registrera'}">Registrera</router-link> -->
            </div>
      </b-container>
</div>
</template>

<script>
export default {
      name: 'NeedLogin',
      props: ["redirect", "text"],
      components: {},
      computed: {},
      methods: {
            url() {
                  if (this.redirect)
                        return this.redirect;
                  return "/minsida";
            }
      },
      mounted() {},
};
</script>

<template>
    <div class="kroppkoll">
          <div class="mt-0 mb-5 text-center">
                <h1 class="text-main">Dina Mätningar</h1>                
                <h2 v-if="currentUser && latestmeasurement">Senaste mätning: {{latestmeasurement.date}}</h2>
          </div>
          <div v-if="currentUser">
                <b-container fluid>                    
                      <KroppsTable v-if="ready"></KroppsTable>
                </b-container>
          </div>
          <div v-else>
                <NeedLogin redirect="/kroppskollen" text=" Här får du full kontroll på din kropp! Följ din utveckling och resultat av dina kroppsskannnigar.<br />För att ta del av kroppkollen måste du logga in eller registrera dig!"></NeedLogin>
          </div>
    </div>
    </template>
    
    <script>
    
    import KroppsTable from '@/components/kroppskoll/KroppsTable';
        
    import NeedLogin from '@/components/common/NeedLogin';
    
    export default {
          name: 'MatningarComponent',
          components: {
                KroppsTable,               
                NeedLogin
          },
          data() {
                return {
                      ready: false
                };
          },
          computed: {
                measurements() {
                      return this.$measurements.measurements();
                },
                latestmeasurement() {
                      if (this.measurements.length > 0)
                            return this.measurements[0];
                      return false;
                },
                currentUser() {
                      return this.$store.state.auth.user;
                },
                age() {
                      return this.$measurements.age();
                },
                height() {
                      return this.$measurements.height();
                }
          },
          methods: {
                date(date) {
                      return this.$date.stringToIsoDateOnly(date);
                },
          },
          mounted() {
                // hämta in mätningar och mål
                if (this.currentUser) {
                      this.$store.dispatch('user/getUserMeasurments').then(() => {
                            this.$store.dispatch('user/getUserGoals').then(() => {
                                  this.ready = true;
                            });
                      });
                }
          },
    };
    </script>
    
<template>
<div class="KroppsInfo">
      <b-container>
            <div v-if="!sent">
                  <div class="text-center">
                        <h3 class="font-italic">Vill du veta mer om din kropps olika hälsotillstånd?</h3>
                        <p class="mt-4 h5">
                              Med kroppskollen får du full koll genom kompletta skanningar och mätningar med uppföljning.
                        </p>
                        <p class="h5">
                              Du följer enkelt alla dina mätningar och din nuvarande fysiska status visas med tydliga grafer.
                        </p>
                        <p class="h5">
                              Du följer även din utvecklingskurva och du väljer själv om du vill följa upp rådgivningen med kostscheman och produktförslag.
                        </p>
                        <div class="mt-5">
                              <h3>Vill du bli kontakta utav mig för mer information?</h3>
                              <p>Klicka på kontakta mig så hör jag av mig så fort jag kan!</p>
                        </div>
                        <div class="mt-5">                              
                              <b-button v-if="!loading" @click="contact" variant="primary">Kontakta mig</b-button>
                              <Loader v-else loadingtext="Vänta..."></Loader>
                        </div>
                  </div>
            </div>
            <div v-else class="text-center">
                  <b-alert v-if="success" show variant="success">{{responsemessage}}</b-alert>

                  <b-alert v-else show variant="danger">{{responsemessage}}</b-alert>
            </div>
      </b-container>
</div>
</template>

<script>
import ContactService from '@/services/contact.service';
import Loader from "@/components/common/Loader";

export default {
      name: 'KroppsInfo',
      components: {Loader},
      data() {
            return {
                  email: '',
                  formValid: false,
                  loading: false,
                  message: '',
                  sent: false,
                  success: false,
                  responsemessage: ""
            };
      },
      computed: {
            currentUser() {
                  return this.$store.state.auth.user;
            }
      },
      methods: {
            contact() {
                  this.loading = true;
                  ContactService.KroppsKontakt().then(
                        response => {
                              var item = response.data;
                              this.responsemessage = item.message;
                              this.sent = true;
                              this.loading = false;
                              if (item.status == "ok") {
                                    this.success = true;                                    
                              }
                        },
                        error => {
                              this.content =
                                    (error.response && error.response.data) ||
                                    error.message ||
                                    error.toString();
                              this.loading = false;
                        }
                  );
            }
      }
};
</script>

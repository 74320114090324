<template>
<div id="home">
    <div class="container">
          <div class="mt-0 mb-5 text-center main-back">                
                <div class="main-text-block font-header-large font-weight-bold">Kroppskollen.com</div>
          </div>

          <div class="mt-5">
                <h3 class="text-center">Paketlösningar</h3>
          </div>
          <div v-if="hasPackages">                
                <Packages :packages="packages"></Packages>
          </div>
          <div v-else-if="loading">
                <Loader loadingtext="Läser paket..."></Loader>
          </div>
          <div class="info mt-4">
                <h2 class="text-center text-main">Hej och välkomna till Kroppskollen.com!</h2>
                <p class="mt-4">
                      Mitt mål är att inspirera, hjälpa och stötta dig till en hållbar
                      livsstilsförändring mot en hälsosammare, aktiv vardag.
                </p>

                <p>
                      Se dig själv som en bil, ett maskineri som behöver vissa grundläggande förutsättningar
                      för att fungera optimalt. Först, vi behöver bränsle. Bränsle i form av protein,
                      kolhydrater och fetter. Men, vi behöver rätt bränsle. Vi behöver underhålla kroppens
                      alla 100 biljoner celler med 114 olika näringsämnen. Näringsämnen i form av vitaminer,
                      mineraler och vatten. Här någonstans är det vi människor, i dagens moderna samhälle,
                      väljer fel kost. Det är så mycket enklare att stanna på vägen hem och köpa snabbmat än att
                      laga riktig, välbalanserad middag.
                </p>

                <p>
                      En ohälsosam livsstil kan ge diverse oönskade konsekvenser så som viktuppgång, viktnedgång,
                      trötthet, sömnbrist, huvudvärk, dålig hy, sämre träningsresultat, sötsug eller dåligt immunförsvar.
                      Genom att inte ge kroppen det den behöver så blir den trött, hängig och slö.
                      Resultatet blir fler dåliga måltidsval, brist på fysisk aktivitet, hjärntrötthet och/eller viktuppgång.
                </p>

                <p>
                      Att vilja gå ner i vikt, vilja forma om kroppen, få mer energi eller bygga mer muskler är inte fel.
                      Däremot att tro att vår lycka är kopplad till vår kroppsvikt är fel. Att motivera oss själva med självhat
                      för en bättre livsstil är inte hållbart. Att bygga en hälsosam livsstil på en tro att vi förtjänar hälsa,
                      lycka och välmående kommer däremot ge resultat som håller i längden, oavsett om det gäller viktnedgång,
                      muskeluppbyggnad eller bara ökad energi.
                </p>

                <p>
                      Hälsa ser olika ut för olika individer. Det måste inte handla om varje dag på gymmet, väga mat,
                      utesluta vissa råvaror och pressa sig själv till max. Hälsa kan vara daglig utevistelse, positivt tänkande,
                      äta regelbundet och våga testa något nytt.
                </p>

                <p>
                      Jag vill visa dig smarta lösningar för att ge kroppen det den behöver utan att krångla till det.
                      Snabba lösningar när du är på språng eller när du kommer hem sent. Lösningar som är enklare än hämtmat och godis.
                      Jag vill ge dig verktygen för en aktiv, balanserad livsstil som ger hållbar energi.
                </p>

                <p>Ta ditt första steg mot din nya livsstil genom att välja något av paketen ovan.</p>
          </div>
    </div>
</div>
</template>

<script>
const Loader = () => import('@/components/common/Loader');
const Packages = () => import('@/components/packages/Packages');
const Carousel = () => import('@/components/home/Carousel');
/* eslint-disable */
export default {
      name: 'Home',
      components: {
            Carousel,
            Packages,
            Loader
      },      
      computed:
      {
            hasPackages()
            {
                  return this.packages;
            },
            packages()
            {
                  var packages = this.$store.getters["packages/packages"];
                  if(packages)
                        return packages;
                  return false;
            },
            loading()
            {
                  return this.$store.getters["packages/packagesloading"];
            }
      },
      mounted() {
            this.$store.dispatch("packages/getPackages");
      },
      methods: {
           
      }
};
</script>

<template>
  <div></div>
</template>

<script>
export default {
    name: "SwishComponent"
}
</script>

<style>

</style>
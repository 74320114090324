<template>
<div class="col-md-12">

      <div class="text-center text-main">
            <h1>Registrera</h1>
      </div>
      <b-container>
            <Stepper :steps="steps" :current="currentstep"></Stepper>
            <div class="p-2 m-4 text-center" v-if="!step1.complete">
                  <b-alert show variant="info">Ange din epostadress och ett lösenord för att bli medlem. En kod kommer skickas till din e-postadress så var noga med att ange en giltig e-postadress.
                        Först när du har angett koden för att verifiera din e-postadresss kan du slutföra din registrering.</b-alert>
            </div>
            <div class="card card-container">
                  <div v-if="!step1.complete">
                        <form name="form" @submit.prevent="handleRegister">
                              <Loader v-if="step1.loading" loadingtext="Kontrollerar uppgifter..."></Loader>
                              <div v-else>
                                    <div class="form-group">
                                          <label for="email">Välj en E-postadress för inloggning</label>
                                          <input v-model="step1.user.email" v-validate="'required|email|max:50'" type="email" class="form-control" name="email" />
                                          <b-alert show v-if="errors.has('email')" variant="danger">Ange en giltig e-postadress</b-alert>
                                    </div>
                                    <div class="form-group">
                                          <label for="password">Välj ditt önskade Lösenord vid inloggningar</label>
                                          <input v-model="step1.user.password" v-validate="'required|min:8|max:40'" type="password" class="form-control" name="password" />
                                          <b-alert show v-if="errors.has('password')" variant="danger">Ange ett lösenord på mellan 8 och 40 tecken långt</b-alert>
                                    </div>
                                    <div class="form-group">
                                          <button class="btn btn-primary btn-block">
                                                <span>Nästa</span>
                                          </button>
                                    </div>
                                    <b-alert variant="danger" v-if="step1.message" show class="text-center">{{step1.message}}</b-alert>
                              </div>
                        </form>
                  </div>

                  <div v-if="step1.complete && !step2.complete" class="text-center">
                        <Loader v-if="codeEntered" loadingtext="Kontrollerar koden..."></Loader>
                        <div v-else>
                              <p class="font-style-italic">Ange koden som skickades till din e-postadress {{step1.user.email}}</p>
                              <b-alert variant="info" show>
                                    <inline-svg :src="svg('common/clock.svg')"></inline-svg>
                                    Du har 5 felaktiga försök eller max 5 minuter på dig
                              </b-alert>
                              <div v-if="step2.tries >= 5">
                                    <b-alert show variant="danger">Du har uppnått max antal försök!</b-alert>
                              </div>
                              <div v-else>
                                    <div class="mt-4 mb-4">
                                          <PincodeInput v-model="step2.code" placeholder="_" :length="6" />
                                    </div>
                                    <div class="mt-2" v-if="step2.message">
                                          <b-alert show variant="danger">{{step2.message}}</b-alert>
                                    </div>
                              </div>
                        </div>
                  </div>

                  <div v-if="step1.complete && step2.complete && !step3.complete" class="text-center">
                        <Loader v-if="step3.loading" loadingtext="Slutför registrering..."></Loader>
                        <div v-else>
                        </div>
                  </div>

                  <div v-if="step1.complete && step2.complete && step3.complete" class="text-center">
                        <b-alert variant="success" show class="text-center">Ditt konto är nu skapat!</b-alert>
                        <div>
                              <router-link to="/loggain">Logga in</router-link>
                        </div>
                  </div>

            </div>

            <div class="text-center mt-4 card">
                  <h4 class="text-center">Varje ny medlem får automatiskt 10% rabatt på alla köp!<br>När du nått en viss inköpsnivå ökar din rabattnivå!</h4>
                  <p class="mt-2 mb-2">
                        Det är helt gratis att bli medlem på MyLifeStyleChange.se. Som medlem får du även alltid rabatterade priser i webshopen.
                  </p>
                  <p>
                        Som medlem får du tillgång till hela receptbanken med mängder av bra recept. Det finns både recept med och utan Herbalife-produkter!
                        Är du medlem och bor i närheten av Göteborg erbjuds du även gratis regelbunden kroppsskanning med uppföljning och rådgivning vid köp
                        av något av produktpaketen. I varje produktpaket ingår även ett personligt kostschema!
                  </p>
            </div>

      </b-container>
</div>
</template>

<script>
const Loader = () => import('@/components/common/Loader');
/* eslint-disable */
import User from '../../models/user';
import PincodeInput from 'vue-pincode-input';
import CompleteRegister from "@/views/front/CompleteRegister";
import Stepper from "@/components/stepper/Stepper";

export default {
      name: 'Register',
      components: {
            Stepper,
            PincodeInput,
            Loader,
            CompleteRegister
      },
      metaInfo: {            
            title: 'Registrera konto',
            meta:[{name: 'description', content: "Är du inte medlem på kroppskollen.com kan du enkelt registrera ditt egna konto! Det är helt gratis!"}]
      },
      data() {
            return {
                  steps: [{
                              index: 0,
                              title: "Epost",
                              subtitle: "Skapa en inloggning",
                              icon: "common/mail.svg"
                        },
                        {
                              index: 1,
                              title: "Kod",
                              subtitle: "Ange kod",
                              icon: "common/key.svg"
                        },
                        {
                              index: 2,
                              title: "Slutför",
                              subtitle: "Slutför registrering",
                              icon: "common/check-circle.svg"
                        }
                  ],
                  step1: { // epost
                        user: new User('', '', ''),
                        loading: false,
                        success: false,
                        complete: false
                  },
                  step2: {
                        code: '',
                        loading: false,
                        success: false,
                        message: '',
                        complete: false,
                        tries: 0
                  },
                  step3: {
                        password: '',
                        repassword: '',
                        loading: false,
                        success: false,
                        complete: false
                  }
            };
      },
      computed: {
            loggedIn() {
                  return this.$store.state.auth.status.loggedIn;
            },
            codeEntered() {
                  // kolla att alla fält är ifyllda samt endast en sifra
                  var valid = this.step2.code.length == 6;
                  if (valid && !this.step2.loading) {
                        this.step2.loading = true;
                        this.validateEnteredCode(this.step2.code);
                  }
                  return valid;
            },
            currentstep() {
                  if (!this.step1.complete)
                        return 0;
                  if (this.step1.complete && !this.step2.complete)
                        return 1;
                  if (this.step1.complete && this.step2.complete && !this.step3.complete)
                        return 2;
                  return 3;
            }
      },
      mounted() {
            if (this.loggedIn) {
                  this.$router.push('/minsida');
            }
      },
      methods: {
            handleRegister() {
                  this.$validator.validate().then(isValid => {
                        if (isValid) {
                              this.step1.loading = true;
                              this.$store.dispatch('register/registeraccount', this.step1.user).then(() => {
                                    this.step1.complete = true;
                                    this.step1.success = true;
                                    this.step1.loading = false;
                              }).catch((error) => {
                                    this.step1.complete = false;
                                    this.step1.success = error;
                                    this.step1.loading = false;
                              });
                        }
                  });
            },
            validateEnteredCode(code) {
                  // ajax!
                  var email = this.step1.user.email;
                  this.step2.loading = true;
                  this.$store.dispatch("register/validateRegistrationCode", {
                        email: email,
                        code: code
                  }).then(() => {
                        this.step2.complete = true;
                        this.step2.success = true;
                        this.step2.loading = false;
                  }).catch((error) => {
                        this.step2.loading = false;
                        this.step2.complete = false;
                        this.step2.success = false;
                        this.step2.code = "";
                        this.step2.tries += 1;
                        this.step2.message = error.response.data;
                  });
            },
            svg(path) {
                  return this.$images.getSvgUrl(path);
            }
      }
};
</script>

<template>
<div>
      <div class="mb-4 font-italic text-center">
            Nedan listas totala mängden näringsinnehåll per ingrediens. Visa för
            <b-form-select v-model="view" :options="options"></b-form-select>
      </div>
      <div v-if="ready">
            <b-table sort-by="name" stacked="sm" responsive :items="items" :fields="headerfields">
                  <template #cell(name)="data">
                        <b-button variant="link" @click="goToIngredient(data.item)" class="py-0 px-0">{{data.item.name }}</b-button>
                  </template>
                  <template slot="bottom-row">
                        <td class="font-weight-bold">Total</td>
                        <td></td>
                        <td class="font-weight-bold text-right">{{ sums.calories }}</td>
                        <td class="font-weight-bold text-right">{{ sums.carbs }}</td>
                        <td class="font-weight-bold text-right">{{ sums.protein }}</td>
                        <td class="font-weight-bold text-right">{{ sums.fat }}</td>
                  </template>
            </b-table>
      </div>
</div>
</template>

<script>
export default {
      name: 'RecipeIngredientsTable',
      props: ["recipe"],
      components: {},
      data() {
            return {
                  ready: false,
                  view: 2,
                  options: [{
                              value: 1,
                              text: "Hela rätten"
                        },
                        {
                              value: 2,
                              text: "Per portion"
                        }
                  ],
                  items: [],
                  headerfields: [{
                              key: 'name',
                              label: "Namn",
                              sortable: true
                        },
                        {
                              key: 'gram',
                              label: "Mängd",
                              sortable: true,
                              class: 'text-right',
                              formatter: (value) => {
                                    if (!isNaN(value))
                                          return value + " g";
                                    return value;
                              }
                        },
                        {
                              key: 'calories',
                              label: "Kalorier",
                              sortable: true,
                              class: 'text-right'
                        },
                        {
                              key: 'carbs',
                              label: 'Kolhydrater',
                              sortable: true,
                              class: 'text-right'
                        },
                        {
                              key: 'protein',
                              label: 'Protein',
                              sortable: true,
                              class: 'text-right'
                        },
                        {
                              key: 'fat',
                              label: 'Fett',
                              sortable: true,
                              class: 'text-right'
                        }
                  ],
                  sums: {}
            }
      },
      computed: {},
      methods: {
            setItems() {
                  var data = this.$recipe.sums(this.recipe, this.view == 1);
                  this.sums = data.sums;
                  this.items = data.items;
            },
            goToIngredient(item) {
                  this.$router.push({
                        name: 'ingrediens',
                        params: {
                              id: item.id,
                              name: this.$formatter.url(item.name)
                        }
                  });
            }
      },
      watch: {
            view: function (val) {
                  this.view = val;
                  this.setItems();
            }
      },
      mounted() {
            this.setItems();
            this.ready = true;
      }
};
</script>

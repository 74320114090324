<template>
<div class="instagram">
      <div v-if="loading">
            <Loader loadingtext="Läser instagram..."></Loader>
      </div>
      <div v-else>
            <div class="mt-0 mb-5 text-center">
                  <h1 class="text-main">{{username}}</h1>
            </div>
            <div v-if="completed">
                  <div class="d-flex flex-wrap justify-content-center flex-shrink-1 flex-grow-1">
                        <InstagramItem v-on:clicked="showinstagramlarge" v-for="item in instagramitems" :key="item.id" :item="item"></InstagramItem>
                  </div>
                  <div class="mt-4 mb-4 text-center">
                        <a class="btn btn-primary" target="_blank" href="https://www.instagram.com/linnohlander2.0/?hl=sv">Se mer på instagram</a>
                  </div>
                  <b-modal ref="instagrammodal" :title="modaltitle" size="xl" scrollable centered hide-footer>

                        <div id="instagram-modal">
                              <InstagramLarge :item="previewitem"></InstagramLarge>
                        </div>
                  </b-modal>
            </div>
      </div>
</div>
</template>

<script>
const Loader = () => import('@/components/common/Loader');
const InstagramItem = () => import('@/components/instagram/InstagramItem');
const InstagramLarge = () => import('@/components/instagram/InstagramLarge');

/* eslint-disable */
export default {
      name: 'InstagramComponent',
      components: {
            InstagramItem,
            InstagramLarge,
            Loader
      },
      metaInfo: {            
            title: 'Instagram',
            meta:[{name: 'description', content: "Följ mig på instagram"}]
      },
      data() {
            return {
                  loading: true,
                  completed: false,
                  previewitem: null,
                  loadingmore: false,
            };
      },
      computed: {
            instagramitems() {                                   
                  return this.$store.getters["instagram/data"];
            },
            fulldata() {

            },
            username() {
                  if (this.instagramitems && this.instagramitems.length > 0)
                        return this.$instagram.username(this.instagramitems[0]);
                  return "";
            },
            modaltitle() {
                  if (this.previewitem) {
                        var adate = this.$instagram.timetaken(this.previewitem);
                        var datestring = this.$date.dateToDateWithoutHour(adate);
                        return this.$date.stringToReadableFormat(datestring);
                  }
                  return "";
            }
      },
      mounted() {
            this.loading = true;
            // EasyInstaFeed("https://www.instagram.com/graphql/query/?query_hash=" + this.hash + "&variables={\"id\":\"" + this.userid + "\",\"first\":\"" + this.photos + "\"}").then(
            //       (data) => {
            //             this.loading = false;
            //             this.data = data;
            //             this.completed = true;
            //       });  

            this.$store.dispatch("instagram/getInstagram").then(() => {
                  this.loading = false;
                  this.completed = true;
            });
            this.$store.dispatch("instagram/checkforupdates");

            // Detect when scrolled to bottom.            
            window.addEventListener('scroll', e => {
                  if (300 + window.scrollY + window.innerHeight >= document.body.scrollHeight) {
                        this.loadMore();
                  }
            });
      },
      methods: {
            showinstagramlarge(item) {
                  this.previewitem = item;
                  this.$refs.instagrammodal.show();
            },
            loadMore()
            {
                  if(this.loadingmore)
                        return;
                  this.loadingmore = true;                  
                  this.$store.dispatch("instagram/increasepage");
                  var self = this;
                  setTimeout(function(){
                        self.loadingmore = false;
                  }, 1000);
            }

      }
};
</script>

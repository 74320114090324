/* eslint-disable */
var checkoutdefault = {
      items: {},
      total: 0,
      totalvat: 0,
      nrofitems: 0,
      draftid: "",
};
const API_URL_GET = process.env.VUE_APP_API_URL_GET;
const API_URL_POST = process.env.VUE_APP_API_URL_POST;
const checkout = JSON.parse(localStorage.getItem('checkout'));
const initialcart = checkout || checkoutdefault;

import axios from "axios";
import authHeader from '@/services/auth-header';
import carthelper from "@/helpers/cart";

export default {
      namespaced: true,
      state: {
            cart: initialcart,
            settings: {
                  incvat: false, // ska priser visas ink eller ex moms
            }
      },

      getters: {
            priceincvat: state =>
            {
                  return state.settings.incvat;
            },
            checkout: state =>
            {
                  return state.cart;
            },
            nrofitems: state =>
            {
                  return state.cart.nrofitems;
            },
            items: state => 
            {
                  return state.cart.items;
            },
            total: state => 
            {
                  return state.cart.total;
            },
            totalvat: state =>
            {
                  return state.cart.totalvat;
            },
            draftid: state => 
            {
                  return state.cart.draftid;
            }

      },

      mutations: {
            SetPriceType(state, value)
            {
                  state.settings.incvat = value;
            },
            AddItem(state, item)
            {
                  if (!state.cart.items[item.id])
                        state.cart.items[item.id] = JSON.parse(JSON.stringify(item));
                  else
                        state.cart.items[item.id].quantity += parseInt(item.quantity);
            },
            RemoveItem(state, id)
            {
                  delete state.cart.items[id];
            },
            ChangeNrOfItems(state, nrofitems)            
            {
                  var items = 0;
                  for (var id in state.cart.items)
                  {
                        items += parseInt(state.cart.items[id].quantity);
                  }
                  state.cart.nrofitems = items;
            },
            ChangeQuantity(state, qchange)
            {
                  if (!state.cart.items[qchange.id])
                        return;

                  state.cart.items[qchange.id].quantity = parseInt(state.cart.items[qchange.id].quantity) + qchange.diff;

                  if (state.cart.items[qchange.id].quantity <= 0)
                        delete state.cart.items[qchange.id];
            },
            ChangeTotalPrice(state)
            {
                  var total = 0;
                  var vat = 0;
                  for (var id in state.cart.items)
                  {
                        var p = state.cart.items[id];
                        var rowtotal = carthelper.getProductPrice(p, true, true);
                        var rowvat = carthelper.getVatPrice(rowtotal, p.vat);
                        if (state.settings.incvat)
                        {
                              rowtotal += rowvat;
                        }
                        total += rowtotal;
                        vat += rowvat;
                  }
                  state.cart.total = total;
                  state.cart.totalvat = vat;
            },
            ClearCart(state)
            {
                  state.cart.items = {};
                  state.cart.total = 0;
                  state.cart.totalvat = 0;
                  state.cart.nrofitems = 0;
            },
            SaveCart(state)
            {
                  const parsed = JSON.stringify(state.cart);
                  localStorage.setItem('checkout', parsed);
            },
            SetFromDraft(state, cart)
            {
                  state.cart.items = cart.items;
                  state.cart.total = cart.total;
                  state.cart.totalvat = cart.totalvat;
                  state.cart.nrofitems = cart.nrofitems;
                  state.cart.draftid = cart.draftid;
            }

      },

      actions: {
            AddToCart({ commit, dispatch }, item)
            {
                  item.description = "";
                  item.instructions = "";
                  commit("AddItem", item);
                  commit("ChangeNrOfItems");
                  commit("ChangeTotalPrice");
                  dispatch("SaveCartDraft");

            },
            RemoveFromCart({ commit, dispatch, state }, id)
            {
                  if (!state.cart.items[id])
                        return;
                  commit("RemoveItem", id);
                  commit("ChangeNrOfItems");
                  commit("ChangeTotalPrice");
                  dispatch("SaveCartDraft");
            },
            ChangeQuantity({ commit, dispatch, state }, qchange)
            {
                  if (!state.cart.items[qchange.id])
                        return;
                  var item = state.cart.items[qchange.id];

                  var diff = 1;
                  if (!qchange.isadd)
                  {
                        diff *= -1;
                  }

                  commit("ChangeQuantity", { id: qchange.id, diff: diff });
                  commit("ChangeTotalPrice");
                  commit("ChangeNrOfItems");
                  dispatch("SaveCartDraft");
            },
            ClearCart({ commit, dispatch }, skipsave)
            {
                  commit("ClearCart");
                  if (!skipsave)
                        dispatch("SaveCartDraft");
            },
            async CheckCartDraft({ dispatch })
            {
                  let response;
                  try
                  {
                        let url = API_URL_GET + 'cartdraft';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        if (response.data.status == "ok"
                              && response.data.data
                              && response.data.data.id)
                        {
                              var draft = JSON.parse(response.data.data.json);
                              draft.draftid = response.data.data.id;
                              dispatch("SetCartFromDraft", draft);
                        }
                        return Promise.resolve(response);
                  }
            },

            SetCartFromDraft({ commit }, draft)
            {
                  commit("SetFromDraft", draft);
            },

            async SaveCartDraft({ commit, state })
            {
                  commit("SaveCart");
                  let response;
                  var draft = {
                        id: state.cart.draftid,
                        json: JSON.stringify(state.cart)
                  }
                  try
                  {
                        let url = API_URL_POST + 'saveupdateorderdraft';
                        response = await axios.post(url, draft, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        return Promise.resolve(response);
                  }
            }
      },
};

<template>
<div class="products">
      <div class="mt-0 mb-5 text-center">
            <h1 class="text-main main-block">Produkter</h1>
      </div>
      <div v-if="!currentUser">
            <NeedLogin redirect="/produkter" text="För tillfället måste du vara registrerad användare för att handla hos kroppskollen! Vi siktar på att öppna för snabb-checkout inom en snar framtid!"></NeedLogin>
      </div>
      <div v-else>
            <b-modal @hide="disclaimerClose" ref="shopmodal" hide-header title="Herbalife disclaimer" size="xl" scrollable centered hide-footer>
                  <div id="disclaimer">
                        <h6 class="text-center">Denna hemsida drivs av en Oberoende Herbalife Nutrition Medlem: <span class="font-weight-bold h5">Linn Ohlander</span></h6>
                        <div class="container mt-4">
                              <div class="row">
                                    <div class="col">
                                          <h5 class="text-center">Existerande kund?</h5>
                                          <div class="mt-4 text-center">
                                                Den personliga uppföljningen du får från Medlemmen är nyckeln för att uppnå dina mål.
                                                Om du tidigare har köpt produkter från en annan Herbalife Nutrition Medlem,
                                                rekommenderar vi att du kontaktar denne. Annars kan du klicka på krysset, eller
                                                någonstans utanför rutan för att fortsätta till mylifestylechange.se.
                                          </div>
                                    </div>
                                    <div class="col">
                                          <h5 class="text-center">Redan medlem?</h5>
                                          <div class="mt-4 text-center">
                                                För att köpa via ditt eget medlemskonto och få alla medlemsfördelar,
                                                vänligen gå till
                                                <a v-b-tooltip title="Logga in på myherbalife.se" href="https://se.myherbalife.com/" target="_blank">myherbalife.se</a>
                                          </div>
                                          <div class="text-right mt-2">
                                                <img src="@/assets/images/herbalife_logo_xs.png" />
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </b-modal>
            <div v-if="!isshowcheckout" class="shop">
                  <b-alert :variant="discountVariant" class="text-center" show>{{discountText}}</b-alert>
                  <div class="container border-bottom" style="position:sticky;top:0; background:white; z-index:200">
                        <b-container>
                              <b-row align-v="center">
                                    <b-col cols="12" md="6" lg="4" order-md="1" order-lg="1">
                                          <b-input-group>
                                                <b-form-input v-model="search" type="search" id="filterInput" class="form-control" placeholder="Sök produkter"></b-form-input>
                                                <b-input-group-append>
                                                      <b-button v-on:click="search=''">Rensa</b-button>
                                                </b-input-group-append>
                                          </b-input-group>
                                    </b-col>
                                    <b-col cols="12" md="12" lg="4" order-md="3" order-lg="2">
                                          <span class="btn" v-b-tooltip.hover title="Visa enskilda" v-on:click="ToggleDisplayType()" :variant="gridvariant">
                                                <inline-svg :src="svg('shop/grid.svg')"></inline-svg>
                                          </span>
                                          <span class="btn" v-b-tooltip.hover title="Visa i lista" v-on:click="ToggleDisplayType()" :variant="listvariant">
                                                <inline-svg :src="svg('shop/list.svg')"></inline-svg>
                                          </span>
                                          <b-dropdown v-b-tooltip.hover title="Sortera" id="ShopSortDropDown" right v-if="!islistview" variant="link-dark" no-flip toggle-class="text-decoration-none">
                                                <template v-slot:button-content>
                                                      <span class>
                                                            <inline-svg :src="svg('shop/arrow-down.svg')"></inline-svg>
                                                      </span>
                                                      <span class="">{{SortName}}</span>
                                                </template>
                                                <b-dropdown-item @click="sortChange('name')">
                                                      <span>
                                                            <inline-svg :src="svg('shop/arrow-down.svg')"></inline-svg>
                                                      </span>
                                                      <span>Namn</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="sortChange('price-asc')">
                                                      <span>
                                                            <inline-svg :src="svg('shop/arrow-down.svg')"></inline-svg>
                                                      </span>
                                                      <span>Billigaste</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="sortChange('price-desc')">
                                                      <span>
                                                            <inline-svg :src="svg('shop/arrow-down.svg')"></inline-svg>
                                                      </span>
                                                      <span>Dyraste</span>
                                                </b-dropdown-item>
                                          </b-dropdown>
                                          <span v-b-tooltip.hover title="Filtrera" @click="showfilter = !showfilter" class="ml-2 pointer">
                                                <inline-svg :src="svg('shop/sliders.svg')"></inline-svg>
                                          </span>
                                    </b-col>
                                    <b-col cols="12" md="6" lg="4" order-md="2" order-lg="3">
                                          <b-row no-gutters>
                                                <b-col class="text-center">
                                                      <div class="custom-control custom-switch pointer" style="top:4px;">
                                                            <input @change="SetPriceType()" v-model="pricetype" type="checkbox" class="custom-control-input pointer" id="price-ink-switch">
                                                            <label v-if="!priceincvat" class="custom-control-label text-danger pointer" for="price-ink-switch">Pris ink. moms</label>
                                                            <label v-if="priceincvat" class="custom-control-label text-success pointer" for="price-ink-switch">Pris ink. moms</label>
                                                      </div>
                                                </b-col>
                                                <b-col class="text-right">
                                                      <b-dropdown ref="basketdropdown" class="basketdropdown" no-caret variant="link-dark" no-flip right toggle-class="text-decoration-none">
                                                            <template v-slot:button-content>
                                                                  <span class="h2 mb-2">
                                                                        <inline-svg :src="svg('shop/shopping-cart.svg')"></inline-svg>
                                                                  </span>
                                                                  <span class="ml-1 mr-1">Varukorg</span>
                                                                  <b-badge :class="{ 'animate__flip' : badge_flip }" id="basketnritems" class="animate__animated animate__faster" v-if="nrofitems > 0" pill variant="success">{{nrofitems}}</b-badge>
                                                            </template>
                                                            <div class="mybasket p-3 container">
                                                                  <table class="table nowrap shop-table stacked-md">
                                                                        <thead>
                                                                              <tr>
                                                                                    <th class="d-none d-md-table-cell" colspan="3">Produkt</th>
                                                                                    <th class="d-table-cell d-md-none">Produkt</th>
                                                                                    <th class="text-center d-none d-md-table-cell">Antal</th>
                                                                                    <th class="text-right d-none d-md-table-cell">Pris {{pricetext}}</th>
                                                                                    <th></th>
                                                                              </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                              <tr v-for="pid in cartitems" :key="pid.id">
                                                                                    <td class="d-none d-md-table-cell">{{pid.artnr}}</td>
                                                                                    <td class="d-none d-md-table-cell">
                                                                                          <b-img class="product-image-small" @error="setFailedImageSrc" :src="getProductImage(pid.artnr)"></b-img>
                                                                                          {{pid.name}}
                                                                                    </td>
                                                                                    <td class="d-table-cell d-md-none">
                                                                                          {{pid.name}}
                                                                                    </td>
                                                                                    <td class="d-none d-md-table-cell">
                                                                                          <span class="font-italic d-none d-lg-table-cell">{{pid.flavor}}</span>
                                                                                    </td>
                                                                                    <td class="text-center d-none d-md-table-cell">
                                                                                          <span v-on:click="changeQuantity(pid.id, false)" class="pointer p-2">-</span>
                                                                                          {{pid.quantity}}
                                                                                          <span v-on:click="changeQuantity(pid.id, true)" class="pointer p-2">+</span>
                                                                                    </td>
                                                                                    <td class="text-right d-none d-md-table-cell">{{formatProductPrice(pid, true, true)}}</td>
                                                                                    <td class="pointer text-right" @click="removeFromCart(pid.id)">
                                                                                          <inline-svg :src="svg('shop/trash.svg')"></inline-svg>
                                                                                    </td>
                                                                              </tr>
                                                                        </tbody>
                                                                        <tfoot>
                                                                              <tr>
                                                                                    <th class="d-none d-md-table-cell" colspan="3"></th>
                                                                                    <th class="text-center d-none d-md-table-cell">{{nrofitems}}</th>
                                                                                    <th class="text-right">{{formatPrice(total)}}</th>
                                                                                    <th class="pointer" v-on:click="clearCart()">
                                                                                          <inline-svg :src="svg('shop/trash.svg')"></inline-svg>
                                                                                    </th>
                                                                              </tr>
                                                                        </tfoot>
                                                                  </table>
                                                                  <div class="text-right mt-2">
                                                                        <button v-on:click="closebasket()" class="btn btn-primary">Fortsätt handla</button>
                                                                        <router-link v-if="nrofitems > 0" to="/kassa" class="btn btn-success ml-2">Till kassan</router-link>
                                                                  </div>
                                                            </div>
                                                      </b-dropdown>
                                                </b-col>
                                          </b-row>

                                    </b-col>
                              </b-row>
                              <b-row align-v="center" v-if="showfilter">
                                    <b-col cols="12" md="5">
                                          <b-card>
                                                <b-form-group label="Välj bland produkttyper">
                                                      <b-form-checkbox-group size="lg" v-model="selected_producttypes" :options="producttypes" name="producttypes"></b-form-checkbox-group>
                                                </b-form-group>
                                          </b-card>
                                    </b-col>
                                    <b-col cols="12" md="7">
                                          <b-card>
                                                <b-form-group label="Välj bland kategorier">
                                                      <b-form-checkbox-group size="lg" v-model="selected_categories" name="categories">
                                                            <b-form-checkbox v-for="category in categories" :value="category.value" :key="category.value">{{category.text}}</b-form-checkbox>
                                                      </b-form-checkbox-group>
                                                </b-form-group>
                                          </b-card>
                                    </b-col>
                              </b-row>
                        </b-container>
                  </div>
                  <div class="mt-3 container">
                        <div v-if="!islistview">
                              <b-container>
                                    <b-row>
                                          <b-col v-on:click="showInfo(product)" cols="12" sm="6" md="4" lg="3" class="d-flex flex-column align-content-stretch product-container mb-4 pb-4 pointer" v-for="product in filteredproducts" :key="product.id">
                                                <div>
                                                      <b-img class="product-image" @error="setFailedImageSrc" center fluid :src="getProductImage(product.artnr)"></b-img>
                                                </div>
                                                <div class="text-center h4 mt-2">{{product.name}}</div>
                                                <div class="font-italic font-small text-center">Artnr: {{product.artnr}}</div>
                                                <div class="font-italic text-center">{{product.flavor}}</div>
                                                <div class="text-center font-weight-bold mt-auto extra-price" v-if="loggedin">{{formatProductPrice(product, true)}}</div>
                                                <div v-bind:class="{ pricecrossed: loggedin }" class="text-center font-weight-bold mt-auto">{{formatProductPrice(product, false)}}</div>
                                                <div class="text-center mt-auto">
                                                      <button v-on:click.stop="showInfo(product)" class="btn btn-primary m-1 animate__animated animate__faster">Info</button>
                                                      <button v-on:click.stop="addToCart(product, $event)" class="btn btn-success m-1 animate__animated animate__faster">Köp</button>
                                                </div>
                                          </b-col>
                                    </b-row>
                              </b-container>
                        </div>
                        <div v-else>
                              <b-row>
                                    <b-col>
                                          <b-table responsive sort-by="name" :filter="filter" hover :items="filteredproducts" :fields="headerfields">
                                                <template v-slot:cell(price)="data">
                                                      <div class="font-weight-bold extra-price" v-if="loggedin">{{formatProductPrice(data.item, true)}}</div>
                                                      <div v-bind:class="{ pricecrossed: loggedin }" class="font-weight-bold">{{formatProductPrice(data.item, false)}}</div>
                                                </template>
                                                <template v-slot:cell(name)="data">
                                                      <b-img class="product-image-small" @error="setFailedImageSrc" :src="getProductImage(data.item.artnr)"></b-img>
                                                      <span v-on:click="showInfo(data.item)" class="pointer hover-link animate__animated animate__faster">{{data.item.name}}</span>
                                                </template>
                                                <template v-slot:cell(buy)="data">
                                                      <button v-on:click="addToCart(data.item, $event)" class="btn btn-success m-1 animate__animated animate__faster">Köp</button>
                                                </template>
                                          </b-table>
                                    </b-col>
                              </b-row>
                        </div>
                  </div>
            </div>
            <b-modal size="xl" @hide="closeInfo" ok-title="Stäng" ok-only footer-class="justify-content-center" ref="infomodal" centered scrollable :title="currentProduct.name + ' (' + currentProduct.artnr +')'">
                  <ProductInfo v-on:selectChange="setGroupItems" v-on:buyButton="addToCart" :showbuy="true" :aproduct="currentProduct" :gitems="currentGroupItems"></ProductInfo>
            </b-modal>
      </div>
</div>
</template>

<script>
import ProductsService from '@/services/products.service';
//http://productinfo.herbalife.com/productlabels/labels-sv_SE.html

import sort from 'fast-sort';

const ProductInfo = () => import('@/components/shop/Product');
import NeedLogin from '@/components/common/NeedLogin';

export default {
      name: 'ProductsComponent',
      components: {
            ProductInfo,
            NeedLogin
      },
      metaInfo: {
            title: 'Webshop',
            meta: [{
                  name: 'description',
                  content: "Handla dina produkter här och få rabatt som registrerad medlem"
            }]
      },
      data() {
            return {
                  isshowcheckout: false,
                  showfilter: false,
                  islistview: false,
                  search: '',
                  listvariant: 'secondary',
                  gridvariant: 'primary',
                  currentProduct: {},
                  currentSort: 'name',
                  currentSortReveresed: false,
                  currentGroupItems: [],
                  // checkout: {
                  //       items: {},
                  //       nrofitems: 0,
                  //       total: 0,
                  //       totalvat: 0,
                  // },
                  products: [],
                  filter: null,
                  headerfields: [{
                              key: 'artnr',
                              label: 'Art.nr',
                              sortable: true,
                        },
                        {
                              key: 'name',
                              label: 'Namn',
                              sortable: true,
                        },
                        {
                              key: 'flavor',
                              label: 'Smak',
                              sortable: true,
                        },
                        {
                              key: 'price',
                              label: 'Pris',
                              sortable: true,
                              class: 'text-right',
                        },
                        {
                              key: 'buy',
                              label: 'Köp',
                              class: 'text-right',
                        },
                  ],
                  producttypes: [{
                              text: 'Standard',
                              value: '1',
                        },
                        {
                              text: 'H24 Sport',
                              value: '2',
                        },
                        {
                              text: 'Hud & hår-vård',
                              value: '3',
                        },
                        {
                              text: 'Tillbehör',
                              value: '4',
                        },
                  ],
                  selected_producttypes: [],
                  selected_categories: [],
                  badge_flip: false,
                  pricetype: 1
            };
      },
      computed: {
            filteredproducts() {
                  let filterd = this.products.filter((item) => {
                        return (
                              (item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
                                    item.description.toLowerCase().indexOf(this.search.toLowerCase()) >
                                    -1 ||
                                    item.flavor.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
                                    item.artnr.toLowerCase().indexOf(this.search.toLowerCase()) > -1) &&
                              this.checkItemCategoryAndProductType(item)
                        );
                  });
                  sort(filterd).by({
                        asc: this.currentSort,
                        comparer: new Intl.Collator(undefined, {
                              numeric: true,
                              sensitivity: 'base'
                        }).compare
                  });
                  if (this.currentSortReveresed)
                        filterd.reverse();
                  return filterd;
            },
            pricetext() {
                  if (this.$store.getters["cart/priceincvat"])
                        return "inkl moms";
                  return "ex moms";
            },
            priceincvat() {
                  return this.$store.getters["cart/priceincvat"];
            },
            SortName() {
                  switch (this.currentSort) {
                        case "price":
                              if (this.currentSortReveresed)
                                    return "Dyrast först";
                              else
                                    return "Billigast först";
                        case "name":
                        default:
                              return "Namn, fallande";
                  }
            },
            SortIcon() {
                  switch (this.currentSort) {
                        case "price":
                              if (this.currentSortReveresed)
                                    return "sort-numeric-down-alt";
                              else
                                    return "sort-numeric-down";
                        case "name":
                        default:
                              return "sort-alpha-down";
                  }
            },
            categories() {
                  var usedid = [];
                  var defcats = [];
                  var h24cats = [];
                  var skincats = [];
                  var tillcats = [];
                  if (
                        this.selected_producttypes.includes('1') ||
                        this.selected_producttypes.length == 0
                  ) {
                        defcats = [{
                                    text: 'Shakepulver',
                                    value: '0',
                              },
                              {
                                    text: 'Dryck',
                                    value: '1',
                              },
                              {
                                    text: 'Tabletter',
                                    value: '2',
                              },
                              {
                                    text: 'Bars',
                                    value: '3',
                              },
                              {
                                    text: 'Övrigt',
                                    value: '4',
                              },
                        ];
                        usedid = ['0', '1', '2', '3', '4'];
                  }
                  if (
                        this.selected_producttypes.includes('2') ||
                        this.selected_producttypes.length == 0
                  ) {
                        h24cats = [];
                        if (!usedid.includes('0')) {
                              h24cats.push({
                                    text: 'Shakepulver',
                                    value: '0',
                              });
                              usedid.push('0');
                        }
                        if (!usedid.includes('1')) {
                              h24cats.push({
                                    text: 'Dryck',
                                    value: '1',
                              });
                              usedid.push('1');
                        }
                  }
                  if (
                        this.selected_producttypes.includes('4') ||
                        this.selected_producttypes.length == 0
                  ) {
                        tillcats = [];
                        if (!usedid.includes('5')) {
                              tillcats.push({
                                    text: 'Shakers',
                                    value: '5',
                              });
                              usedid.push('5');
                        }
                        if (!usedid.includes('4')) {
                              tillcats.push({
                                    text: 'Övrigt',
                                    value: '4',
                              });
                              usedid.push('4');
                        }
                  }
                  if (
                        this.selected_producttypes.includes('3') ||
                        this.selected_producttypes.length == 0
                  ) {
                        skincats = [];
                        skincats.push({
                              text: 'Hår ',
                              value: '6',
                        });
                        usedid.push('6');
                        skincats.push({
                              text: 'Hud',
                              value: '7',
                        });
                        usedid.push('7');
                        skincats.push({
                              text: 'Ansikte',
                              value: '8',
                        });
                        usedid.push('8');
                  }

                  return defcats.concat(h24cats).concat(skincats).concat(tillcats);
            },
            currentUser() {
                  var loggeduser = this.$store.state.auth.user;
                  if (loggeduser)
                        return loggeduser;
                  return false;
            },
            loggedin() {
                  if (this.currentUser)
                        return this.currentUser.id > 0;
                  return false;
            },
            discountVariant() {
                  if (this.loggedin)
                        return "success";
                  return "danger";
            },
            discountText() {
                  if (this.loggedin) {
                        return "Du har " + this.currentUser.discount + "% rabatt på samtliga produkter!";
                  } else {
                        return "Alla registrerade användare får från 10% rabatterade priser vid alla köp!";
                  }
            },
            nrofitems() {
                  return this.$store.getters["cart/nrofitems"];
            },
            cartitems() {
                  return this.$store.getters["cart/items"];
            },
            total() {
                  return this.$store.getters["cart/total"];
            }
      },
      mounted() {
            if (localStorage.getItem('disclaimer') != 'true') {
                  this.$refs.shopmodal.show();
            }
            this.pricetype = this.priceincvat;
            ProductsService.GetProducts().then(
                  (response) => {
                        this.products = response.data.data;

                        // check url och kolla om man ska visa popup
                        var qid = this.$route.params.id;
                        if (qid) {
                              // get product by id
                              var item = this.getById(qid);
                              if (item)
                                    this.showInfo(item, true);
                        }
                  },
                  (error) => {
                        this.content =
                              (error.response && error.response.data) ||
                              error.message ||
                              error.toString();
                  }
            );
            this.$store.dispatch("cart/CheckCartDraft");
            // if (localStorage.getItem('checkout')) {
            //       try {
            //             this.checkout = JSON.parse(localStorage.getItem('checkout'));
            //       } catch (e) {
            //             localStorage.removeItem('checkout');
            //       }
            // }
            if (localStorage.search) {
                  this.search = localStorage.search;
            }
      },
      methods: {
            productPrice(product, with_discount, use_quantity) {
                  return this.$cart.getProductPrice(product, with_discount, use_quantity);
            },
            getPrice(price, with_discount) {
                  return this.$cart.getPrice(price, with_discount);
            },
            formatProductPrice(product, with_discount, use_quantity) {
                  var calcprice = this.productPrice(product, with_discount, use_quantity);
                  calcprice = this.fixPriceByPriceType(calcprice, product.vat);
                  return this.$formatter.formatCurrency(calcprice);
            },
            formatPrice(price, with_discount) {
                  var calcprice = this.getPrice(price, with_discount);
                  return this.$formatter.formatCurrency(calcprice);
            },
            fixPriceByPriceType(price, vat) {
                  if (this.priceincvat) {
                        return price + this.$cart.getVatPrice(price, vat);
                  } else {
                        return price;
                  }
            },
            getById(id) {
                  for (var i = 0; i < this.products.length; i++) {
                        var item = this.products[i];
                        if (item.id == id)
                              return item;
                  }
                  return false;
            },
            slugify(string) {
                  return this.$formatter.url(string);
            },
            checkItemCategoryAndProductType(item) {
                  if (
                        !this.selected_producttypes ||
                        this.selected_producttypes.length <= 0
                  ) {
                        return this.checkItemCategoryType(item);
                  }
                  if (this.selected_producttypes.includes(item.type))
                        return this.checkItemCategoryType(item);
                  return false;
            },
            checkItemCategoryType(item) {
                  if (!this.selected_categories || this.selected_categories.length <= 0) {
                        return true;
                  }
                  if (this.selected_categories.includes(item.category)) return true;
                  return false;
            },
            sortChange(sort) {
                  switch (sort) {
                        case "name":
                              this.currentSort = "name";
                              this.currentSortReveresed = false;
                              break;
                        case "price-asc":
                              this.currentSort = "price";
                              this.currentSortReveresed = false;
                              break;
                        case "price-desc":
                              this.currentSort = "price";
                              this.currentSortReveresed = true;
                              break;
                  }
            },
            disclaimerClose() {
                  localStorage.setItem('disclaimer', 'true');
            },
            closebasket() {
                  this.$refs.basketdropdown.hide(true);
            },
            toggleCheckout() {
                  this.isshowcheckout = !this.isshowcheckout;
            },
            addToCart(item, event) {
                  this.$store.dispatch("cart/AddToCart", item);
                  //console.log("add to cart");
                  this.$refs.infomodal.hide();
                  this.saveCart();
                  if (event) {
                        event.target.classList.add("animate__wobble");
                        var self = event.target;
                        setTimeout(() => {
                              self.classList.remove("animate__wobble");
                        }, 1000);
                  }
            },
            removeFromCart(id) {
                  this.$store.dispatch("cart/RemoveFromCart", id);
                  this.saveCart();
            },
            ToggleDisplayType() {
                  this.islistview = !this.islistview;
                  var tmp = this.listvariant;
                  this.listvariant = this.gridvariant;
                  this.gridvariant = tmp;
            },
            clearCart() {
                  this.$store.dispatch("cart/ClearCart");
                  this.closebasket();
                  this.saveCart();
            },
            changeQuantity(id, isadd) {

                  this.$store.dispatch("cart/ChangeQuantity", {
                        id: id,
                        isadd: isadd
                  });

                  this.saveCart();
            },
            saveCart() {
                  this.badge_flip = true;
                  var self = this;
                  setTimeout(() => {
                        self.badge_flip = false;
                  }, 1000);
            },
            loadCart() {},
            showInfo(item, skipurl) {
                  this.setGroupItems(item);
                  this.currentProduct = item;
                  this.$refs.infomodal.show();
                  var newurl = "/produkter/" + item.id + "/" + this.slugify(item.name);
                  if (!skipurl)
                        this.$router.push({
                              path: newurl
                        });
            },
            closeInfo() {
                  this.$router.push({
                        path: "/produkter"
                  });
            },
            svg(path) {
                  return this.$images.getSvgUrl(path);
            },
            getProductImage(id) {
                  return this.$images.getProductImage(id);
            },
            setFailedImageSrc(e) {
                  e.target.src = this.$images.getFailedProductImage();
            },
            setGroupItems(product) {
                  if (!product.groupnr) this.currentGroupItems = [];
                  else {
                        this.currentGroupItems = this.products.filter((item) => {
                              return item.groupnr == product.groupnr;
                        });
                  }
            },
            SetPriceType() {
                  this.$store.commit("cart/SetPriceType", this.pricetype);
                  this.$store.commit("cart/ChangeTotalPrice");
            }
      },
      watch: {
            search(newSearch) {
                  localStorage.search = newSearch;
            },
      },
};
</script>

<template>
<div class="kroppkoll">
      <div class="mt-0 mb-5 text-center">
            <h1 class="text-main">Kroppskollen</h1>
            <h2 v-if="currentUser && latestmeasurement">Senaste mätning: {{latestmeasurement.date}}</h2>
      </div>
      <div v-if="currentUser">
            <b-container fluid>
                  <div v-if="latestmeasurement">
                        <b-row class="mt-2 mb-2 text-center font-bold">
                              <b-col>
                                    <h3>{{currentUser.firstname}} {{currentUser.lastname}}</h3>
                              </b-col>
                              <b-col>
                                    <h3>Längd: {{height}} cm</h3>
                              </b-col>
                              <b-col>
                                    <h3>Ålder: {{age}} år</h3>
                              </b-col>
                        </b-row>
                        <b-row v-if="ready">
                              <b-col md="6" xl="4" class="text-center mb-2 mt-2">
                                    <PhysLevel></PhysLevel>
                              </b-col>
                              <b-col md="6" xl="4" class="text-center mb-2 mt-2">
                                    <Weight></Weight>
                              </b-col>
                              <b-col md="6" xl="4" class="text-center mb-2 mt-2">
                                    <Fat></Fat>
                              </b-col>
                              <b-col md="6" xl="4" class="text-center mb-2 mt-2">
                                    <Muscles></Muscles>
                              </b-col>
                              <b-col md="6" xl="4" class="text-center mb-2 mt-2">
                                    <BellyFat></BellyFat>
                              </b-col>
                              <b-col md="6" xl="4" class="text-center mb-2 mt-2">
                                    <BMI></BMI>
                              </b-col>
                              <b-col md="6" xl="4" class="text-center mb-2 mt-2">
                                    <Water></Water>
                              </b-col>
                              <b-col md="6" xl="4" class="text-center mb-2 mt-2">
                                    <MetAge></MetAge>
                              </b-col>
                              <b-col md="6" xl="4" class="text-center mb-2 mt-2">
                                    <Bust></Bust>
                              </b-col>
                              <b-col md="6" xl="4" class="text-center mb-2 mt-2">
                                    <Waist></Waist>
                              </b-col>
                              <b-col md="6" xl="4" class="text-center mb-2 mt-2">
                                    <Stomach></Stomach>
                              </b-col>
                              <b-col md="6" xl="4" class="text-center mb-2 mt-2">
                                    <Hip></Hip>
                              </b-col>
                        </b-row>                        
                  </div>
                  <div v-else>
                        <KroppsInfo></KroppsInfo>
                  </div>
            </b-container>
      </div>
      <div v-else>
            <NeedLogin redirect="/kroppskollen" text=" Här får du full kontroll på din kropp! Följ din utveckling och resultat av dina kroppsskannnigar.<br />För att ta del av kroppkollen måste du logga in eller registrera dig!"></NeedLogin>
      </div>
</div>
</template>

<script>
import Weight from '@/components/kroppskoll/items/Weight';
import BMI from '@/components/kroppskoll/items/BMI';
import PhysLevel from '@/components/kroppskoll/items/PhysLevel';
import BellyFat from '@/components/kroppskoll/items/BellyFat';
import Fat from '@/components/kroppskoll/items/Fat';
import Muscles from '@/components/kroppskoll/items/Muscles';
import Water from '@/components/kroppskoll/items/Water';
import MetAge from '@/components/kroppskoll/items/Age';

import Waist from '@/components/kroppskoll/items/tapemeasures/Waist';
import Bust from '@/components/kroppskoll/items/tapemeasures/Bust';
import Hip from '@/components/kroppskoll/items/tapemeasures/Hip';
import Stomach from '@/components/kroppskoll/items/tapemeasures/Stomach';
import KroppsInfo from '@/components/kroppskoll/KroppsInfo';

import NeedLogin from '@/components/common/NeedLogin';

export default {
      name: 'KroppkollComponent',
      components: {            
            KroppsInfo,
            Weight,
            Fat,
            BMI,
            BellyFat,
            PhysLevel,
            Muscles,
            Water,
            MetAge,
            Waist,
            Hip,
            Bust,
            Stomach,
            NeedLogin
      },
      data() {
            return {
                  ready: false
            };
      },
      computed: {
            measurements() {
                  return this.$measurements.measurements();
            },
            latestmeasurement() {
                  if (this.measurements.length > 0)
                        return this.measurements[0];
                  return false;
            },
            currentUser() {
                  return this.$store.state.auth.user;
            },
            age() {
                  return this.$measurements.age();
            },
            height() {
                  return this.$measurements.height();
            }
      },
      methods: {
            date(date) {
                  return this.$date.stringToIsoDateOnly(date);
            },
      },
      mounted() {
            // hämta in mätningar och mål
            if (this.currentUser) {
                  this.$store.dispatch('user/getUserMeasurments').then(() => {
                        this.$store.dispatch('user/getUserGoals').then(() => {
                              this.ready = true;
                        });
                  });
            }
      },
};
</script>

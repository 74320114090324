
export default {
      getFirstName(fullname) {
            var fi = fullname.indexOf("'");
            var li = fullname.lastIndexOf("'");
            if (fi >= 0 && li > 0)
                  return fullname.substring(fi + 1, li);
            // fallback, first name possible
            var names = fullname.split(" ");
            return names[0];
      },
      getLastName(fullname) {
            var names = fullname.split(" ");
            return names[names.length - 1];
      }
  };
  
import axios from 'axios';
import authHeader from './auth-header';

const API_URL_GET = process.env.VUE_APP_API_URL_GET;

class UserService { 

  getUserOrders() {
    return axios.get(API_URL_GET + 'myorders', { headers: authHeader() });
  }
  getUserOrder(id) {
    return axios.get(API_URL_GET + 'myorders?id=' + id, { headers: authHeader() });
  }
  getUserOrderPayment(id) {
    return axios.get(API_URL_GET + 'order-payment-info?id=' + id, { headers: authHeader() });
  }
}

export default new UserService();

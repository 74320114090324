<template>
<div id="menu" class="mb-2">
      <div class="d-flex justify-content-lg-center align-items-center">
            <div class="mt-0 mb-0 mt-lg-5 mb-lg-5 p-1 p-lg-0 animate__bounceIn animate__animated">
                  <div v-if="currentUser">
                        <div class="hover-menu-icon">
                              <b-img :class="{ 'animate__flip' : avatar_flip }" class="menu-avatar animate__animated animate__faster" center rounded="circle" :src="avatar()"></b-img>                              
                              <div class="menu-icon-edit" @click="GoTo('/minsida/konto')">
                                    <inline-svg :src="this.$images.getSvgUrl('common/edit-2.svg')" width="16" height="16" />
                              </div>
                        </div>
                  </div>
            </div>
            <div class="d-block d-lg-none ml-auto p-1">
                  <b-button id="menuicon" v-on:click="showMenu()" variant="primary">Meny</b-button>
            </div>
      </div>
      <b-list-group id="menu-nav" class="position-sticky" style="top:0px;" v-bind:class="{ visible: visible }">

            <b-list-group-item v-if="isAdmin" v-b-toggle="'collapse-admin'">
                  <b-container>
                        <b-row class="justify-content-start align-items-center">
                              <b-col cols="3" class="page-svg">
                                    <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/admin', true)}" :src="this.$images.getSvgUrl('menu/tool.svg')" width="24" height="24" />
                              </b-col>
                              <b-col cols="9" class="page-title">
                                    Admin
                                    <inline-svg :src="this.$images.getSvgUrl(collapse.admin? 'menu/chevron-up.svg': 'menu/chevron-down.svg')" width="14" height="18" />
                              </b-col>
                        </b-row>
                  </b-container>
            </b-list-group-item>

            <b-collapse id="collapse-admin" v-model="collapse.admin" v-if="isAdmin">                 
                  <b-list-group-item class="sub-menu" :active="isSelected('/admin/users', true)" :to="'/admin/users'" v-on:click="click()">
                        <b-container>
                              <b-row class="justify-content-start align-items-center pl-3">
                                    Hantera användare
                              </b-row>
                        </b-container>
                  </b-list-group-item>
                   <b-list-group-item class="sub-menu" :active="isSelected('/admin/accounts', true)" :to="'/admin/accounts'" v-on:click="click()">
                        <b-container>
                              <b-row class="justify-content-start align-items-center pl-3">
                                    Hantera inloggningar
                              </b-row>
                        </b-container>
                  </b-list-group-item>
                  <b-list-group-item class="sub-menu" :active="isSelected('/admin/orders', true)" :to="'/admin/orders'" v-on:click="click()">
                        <b-container>
                              <b-row class="justify-content-start align-items-center pl-3">
                                    Hantera ordrar
                              </b-row>
                        </b-container>
                  </b-list-group-item>
                  <b-list-group-item class="sub-menu" :active="isSelected('/admin/recepies', true)" :to="'/admin/recepies'" v-on:click="click()">
                        <b-container>
                              <b-row class="justify-content-start align-items-center pl-3">
                                    Hantera recept
                              </b-row>
                        </b-container>
                  </b-list-group-item>
            </b-collapse>

            <b-list-group-item v-if="currentUser" v-b-toggle="'collapse-my-account'">
                  <b-container>
                        <b-row class="justify-content-start align-items-center">
                              <b-col cols="3" class="page-svg">
                                    <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/minsida', true)}" :src="this.$images.getSvgUrl('menu/user.svg')" width="24" height="24" />
                              </b-col>
                              <b-col cols="9" class="page-title">
                                    Mitt konto
                                    <inline-svg :src="this.$images.getSvgUrl(collapse.account? 'menu/chevron-up.svg': 'menu/chevron-down.svg')" width="14" height="18" />
                              </b-col>
                        </b-row>
                  </b-container>
            </b-list-group-item>

            <b-collapse v-if="currentUser" id="collapse-my-account" v-model="collapse.account">
                  <b-list-group-item class="sub-menu" :active="isSelected('/minsida', )" :to="'/minsida'" v-on:click="click()">
                        <b-container>
                              <b-row class="justify-content-start align-items-center pl-3">
                                    Översikt
                              </b-row>
                        </b-container>
                  </b-list-group-item>
                  <b-list-group-item class="sub-menu" :active="isSelected('/minsida/konto', true)" :to="'/minsida/konto'" v-on:click="click()">
                        <b-container>
                              <b-row class="justify-content-start align-items-center pl-3">
                                    Kontouppgifter
                              </b-row>
                        </b-container>
                  </b-list-group-item>
                   <b-list-group-item class="sub-menu" :active="isSelected('/minsida/bestallningar', true)" :to="'/minsida/bestallningar'" v-on:click="click()">
                        <b-container>
                              <b-row class="justify-content-start align-items-center pl-3">
                                    Beställningar
                              </b-row>
                        </b-container>
                  </b-list-group-item>
                  <!-- <b-list-group-item class="sub-menu" :active="isSelected('/minsida/kostplan', true)" :to="'/minsida/kostplan'" v-on:click="click()">
                        <b-container>
                              <b-row class="justify-content-start align-items-center pl-3">
                                    Min kostplan
                              </b-row>
                        </b-container>
                  </b-list-group-item> -->                 
            </b-collapse>
           
              <b-list-group-item v-b-toggle="'collapse-kroppskollen'">
                  <b-container>
                        <b-row class="justify-content-start align-items-center">
                              <b-col cols="3" class="page-svg">
                                    <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/kroppskollen')}" :src="this.$images.getSvgUrl('menu/user-check.svg')" width="24" height="24" />
                              </b-col>
                              <b-col cols="9" class="page-title">
                                    Kroppskollen
                                    <inline-svg :src="this.$images.getSvgUrl(collapse.kroppskollen? 'menu/chevron-up.svg': 'menu/chevron-down.svg')" width="14" height="18" />
                              </b-col>
                        </b-row>
                  </b-container>
            </b-list-group-item>

            <b-collapse  id="collapse-kroppskollen" v-model="collapse.kroppskollen">
                  <b-list-group-item class="sub-menu" :active="isSelected('/kroppskollen', )" :to="'/kroppskollen'" v-on:click="click()">
                        <b-container>
                              <b-row class="justify-content-start align-items-center pl-3">
                                    Översikt
                              </b-row>
                        </b-container>
                  </b-list-group-item>
                  <b-list-group-item class="sub-menu" :active="isSelected('/kroppskollen/matningar', true)" :to="'/kroppskollen/matningar'" v-on:click="click()">
                        <b-container>
                              <b-row class="justify-content-start align-items-center pl-3">
                                    Mätningar
                              </b-row>
                        </b-container>
                  </b-list-group-item>
                   <b-list-group-item class="sub-menu" :active="isSelected('/kroppskollen/jamfor', true)" :to="'/kroppskollen/jamfor'" v-on:click="click()">
                        <b-container>
                              <b-row class="justify-content-start align-items-center pl-3">
                                    Jämför
                              </b-row>
                        </b-container>
                  </b-list-group-item>
            </b-collapse>

             <b-list-group-item :active="isSelected('/hem') || isSelected('/') " to="/hem" v-on:click="click()">
                  <b-container>
                        <b-row class="justify-content-start align-items-center">
                              <b-col cols="3" class="page-svg">
                                    <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/hem')}" :src="this.$images.getSvgUrl('menu/home.svg')" width="24" height="24" />
                              </b-col>
                              <b-col cols="9" class="page-title">
                                    Hem
                              </b-col>
                        </b-row>
                  </b-container>
            </b-list-group-item>

            <b-list-group-item :active="isSelected('/produkter', true) || isSelected('/kassa', true) || isSelected('/betalning', true)" to="/produkter" v-on:click="click()">
                  <b-container>
                        <b-row class="justify-content-start align-items-center">
                              <b-col cols="3" class="page-svg">
                                    <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/produkter')}" :src="this.$images.getSvgUrl('menu/cart.svg')" width="24" height="24" />
                              </b-col>
                              <b-col cols="9" class="page-title">
                                    Webshop
                                    <b-badge v-if="webshopitems > 0" pill variant="success">{{webshopitems}}</b-badge>
                              </b-col>
                        </b-row>
                  </b-container>
            </b-list-group-item>

          

            <b-list-group-item :active="isSelected('/recept', true)" to="/recept" v-on:click="click()">
                  <b-container>
                        <b-row class="justify-content-start align-items-center">
                              <b-col cols="3" class="page-svg">
                                    <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/recept')}" :src="this.$images.getSvgUrl('menu/book.svg')" width="24" height="24" />
                              </b-col>
                              <b-col cols="9" class="page-title">
                                    Recept
                              </b-col>
                        </b-row>
                  </b-container>
            </b-list-group-item>

            <b-list-group-item :active="isSelected('/ingredienser')" to="/ingredienser" v-on:click="click()">
                  <b-container>
                        <b-row class="justify-content-start align-items-center">
                              <b-col cols="3" class="page-svg">
                                    <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/ingredienser')}" :src="this.$images.getSvgUrl('menu/gift.svg')" width="24" height="24" />
                              </b-col>
                              <b-col cols="9" class="page-title">
                                    Ingredienser
                              </b-col>
                        </b-row>
                  </b-container>
            </b-list-group-item>

            <!-- <b-list-group-item :active="isSelected('/instagram')" to="/instagram" v-on:click="click()">
                  <b-container>
                        <b-row class="justify-content-start align-items-center">
                              <b-col cols="3" class="page-svg">
                                    <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/instagram')}" :src="this.$images.getSvgUrl('menu/instagram.svg')" width="24" height="24" />
                              </b-col>
                              <b-col cols="9" class="page-title">
                                    Instagram
                              </b-col>
                        </b-row>
                  </b-container>
            </b-list-group-item> -->

            <b-list-group-item :active="isSelected('/kontakt')" to="/kontakt" v-on:click="click()">
                  <b-container>
                        <b-row class="justify-content-start align-items-center">
                              <b-col cols="3" class="page-svg">
                                    <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/kontakt')}" :src="this.$images.getSvgUrl('menu/mail.svg')" width="24" height="24" />
                              </b-col>
                              <b-col cols="9" class="page-title">
                                    Kontakta mig
                              </b-col>
                        </b-row>
                  </b-container>
            </b-list-group-item>

            <b-list-group-item style="cursor:pointer" v-if="currentUser" @click.prevent="logOut">
                  <b-container>
                        <b-row class="justify-content-start align-items-center">
                              <b-col cols="3" class="page-svg">
                                    <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/loggaut')}" :src="this.$images.getSvgUrl('menu/log-out.svg')" width="24" height="24" />
                              </b-col>
                              <b-col cols="9" class="page-title">
                                    Logga ut
                              </b-col>
                        </b-row>
                  </b-container>
            </b-list-group-item>
            <b-list-group-item v-else :active="isSelected('/loggain')" to="/loggain" v-on:click="click()">
                  <b-container>
                        <b-row class="justify-content-start align-items-center">
                              <b-col cols="3" class="page-svg">
                                    <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/loggain')}" :src="this.$images.getSvgUrl('menu/log-in.svg')" width="24" height="24" />
                              </b-col>
                              <b-col cols="9" class="page-title">
                                    Logga in
                              </b-col>
                        </b-row>
                  </b-container>
            </b-list-group-item>

            <!-- <li v-if="!currentUser" class="nav-item">
                                    <router-link to="/paket" class="nav-link">Paketlösningar</router-link>
                              </li> -->
            <!-- <li class="nav-item">
                                    <router-link to="/produkter" class="nav-link">Webshop</router-link>
                              </li>
                              <li v-if="currentUser" class="nav-item">
                                    <router-link to="/linn" class="nav-link">Om mig</router-link>
                              </li> -->
            <!-- <li class="nav-item">
                                    <router-link to="/inspiration" class="nav-link">Inspiration</router-link>
                              </li> -->
            <!-- <li v-if="!currentUser" class="nav-item">
                                    <router-link to="/linn" class="nav-link">Om mig</router-link>
                              </li> -->

            <div class="sidebar-footer position-fixed d-none d-md-block" style="bottom:15px">

            </div>

      </b-list-group>
</div>
</template>

<script>
export default {
      name: 'MenuComponent',
      data() {
            return {
                  visible: false,
                  avatar_flip: false,
                  collapse: {
                        admin: false,
                        account: false,
                        kroppskollen: false
                  }
            }
      },
      computed: {
            currentUser() {
                  return this.$store.state.auth.user;
            },
            isAdmin() {
                  if (this.currentUser && this.currentUser.role) {
                        return this.currentUser.role.role == 1;
                  }
                  return false;
            },
            webshopitems()
            {
                 return this.$store.getters["cart/nrofitems"];
            }
      },
      watch:{

      },
      mounted(){
            if(this.currentUser)
                  this.$store.dispatch("cart/CheckCartDraft");            
      },
      created(){
            this.checkCollapsed();
      },
      methods: {
            click() {
                  this.visible = false;
                  this.avatar_flip = true;
                  var self = this;
                  setTimeout(() => {
                        self.avatar_flip = false;
                  }, 1000);
            },
            isSelected(path, startswith) {
                  if (startswith) {
                        return this.$route.path.startsWith(path);
                  } else {
                        return path == this.$route.path;
                  }
            },
            hasavatar() {
                  if (this.currentUser.image)
                        return true;
                  return false;
            },
            avatar() {
                  if (this.hasavatar())
                        return "/images/users/" + this.currentUser.image;
                  else
                        return require("@/assets/images/user.jpg");
            },
            showMenu() {
                  this.visible = !this.visible;
            },
            logOut() {
                  //this.$root.$emit('bv::toggle::collapse', 'collapse-admin');
                  //this.$root.$emit('bv::toggle::collapse', 'collapse-my-account')
                  this.collapse.admin = false;
                  this.collapse.account = false;
                  this.collapse.kroppskollen = false;
                  this.$store.dispatch('auth/logout');
                  this.$router.push('/');
            },
            UserInitials() {
                  var fname = this.currentUser.firstname;
                  var lname = this.currentUser.lastname;
                  var initials = fname.substring(0, 1) + lname.substring(0, 1);
                  return initials.toUpperCase();
            },
            GoTo(path)
            {
                  this.$router.push(path);
            },
            checkCollapsed()
            {
                  var adminp = "/admin";
                  var myp = "/minsida";
                  var kropps = "/kroppskollen";
                  if(this.$router.history._startLocation.startsWith(adminp))
                  {
                        this.collapse.admin = true;
                  }
                  else if(this.$router.history._startLocation.startsWith(myp))
                  {
                        this.collapse.account = true;
                  }
                  else if(this.$router.history._startLocation.startsWith(kropps))
                  {
                        this.collapse.kroppskollen = true;
                  }
            }
      }
}
</script>

<template>
 <span class="trend">
       <inline-svg class="trend-arrow" :class="trendclass" :src="svg()"></inline-svg>
       {{value}}
 </span>
</template>

<script>

export default {
      name: 'TrendComponent',
      props: ["trend"],
      components: {          
      },
      computed: {            
            src()
            {
                  return "";
            },
            trendclass()
            {
                  return this.trendtype() +" " + this.trenddir();
            },
            value()
            {
                  if(this.trend.value)
                  {
                        var suffix = "";
                        if(this.trend.suffix)
                              suffix = " " + this.trend.suffix;
                        var prefix = "";
                        if(this.trend.value > 0)
                              prefix = "+";
                        return prefix + this.$measurements._numb(this.trend.value, 1) + suffix;
                  }
                  return "";
            }
      },
      methods: {
            svg()
            {
                  return this.$images.getSvgUrl("arrow.svg");
            },
            trendtype()
            {
                  switch(this.trend.type)
                  {
                        case "positive":
                        case "1":
                        case 1:
                              return "trend-positive";
                        case "negative":
                        case "-1":
                        case -1:
                              return "trend-negative";
                        
                  }
                  return "trend-unchange";
            },
            trenddir()
            {
                  switch(this.trend.dir)
                  {
                        case "up":
                        case "1":
                        case 1:
                              return "trend-up";
                        case "down":
                        case "-1":
                        case -1:
                              return "trend-down";
                        
                  }
                  return "";
            }
      }
};
</script>
